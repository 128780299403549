import { useEffect } from "react";

const FormInput = (props) => {
  useEffect(() => {
    if (props.id === undefined) {
      throw new Error("FormInput component must be supplied an ID");
    }
  }, []);

  return (
    <div className={`modal-field form-input ${props.className ?? ""}`}>
      <label>{props.label}</label>
      <input
        id={props.id}
        type={props.type ?? "text"}
        defaultValue={props.defaultValue}
        defaultChecked={props.checked ?? false}
        placeholder={props.placeholder ?? ""}
        readOnly={props.readOnly ?? false}
        disabled={props.disabled ?? false}
        autoFocus={props.autoFocus ?? false}
      />
    </div>
  );
};

export default FormInput;
