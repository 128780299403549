/*
    Required props:
        show
        setShow
        title
    Optional Props
        action
        renderItem
        onClose
*/

import Modal from "./Modal";

const ModalCustom = (props) => {
  return (
    <Modal
      show={props.show}
      setShowModal={props.setShow}
      onClose={props.onClose}
      key={props.renderItem}
    >
      <div className="modal-content modal-custom">
        <h3 id="modal-title">{props.title}</h3>
        <div className="modal-fields-container">{props.children}</div>
      </div>
      {!props.action ? null : (
        <div className="modal-buttons">
          <button id="modal-custom-action" onClick={props.action}>
            {props?.buttonText || "Create"}
          </button>
          {!props.action2 ? null : (
            <button id="modal-custom-action2" onClick={props.action2}>
              {props?.buttonText2 || "Create"}
            </button>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ModalCustom;
