import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";

import Helpers from "../../api/Helpers";
import Loading from "../Loading";
import IntervalItemTable from "../Interval/IntervalItemTable";
import ModalCustom from "../Modal/ModalCustom";
import ModalDialog from "../Modal/ModalDialog";
// import TooltipIcon from "../Misc/TooltipIcon";
import TabContextMenu from "../Input/TabContextMenu";
import TableIcon from "../Misc/TableIcon";
import IntervalHelpers from "../../api/IntervalHelpers";
import ProjectHelpers from "../../api/ProjectHelpers";
import ModalForm from "../Modal/ModalForm";
import FormPage from "../Modal/Forms/FormPage";
import FormInput from "../Modal/Forms/FormInput";

const TabParticipants = (props) => {
  /* STATES */
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [intervalItems, setIntervalItems] = useState([]);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [createNew, setCreateNew] = useState(false);

  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const [showModalDialog, setShowModalDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogAction, setDialogAction] = useState(null);
  const [dialogOnClose, setDialogOnClose] = useState(null);

  /* EFFECTS */
  useEffect(() => {
    if (!isEmpty(props.currInterval) && !isEmpty(props.participants)) {
      if (
        props.currInterval.doesNotExist ||
        (props.currInterval.details && props.currInterval.users)
      ) {
        if (
          !props.currInterval.doesNotExist &&
          props.currInterval.users.length > 0
        ) {
          setIntervalItems(
            IntervalHelpers.getParticipantItems(
              props.currInterval,
              props.participants
            )
          );
        } else {
          setDataLoaded(true);
        }
      }
    }
  }, [props.participants, props.currInterval]);

  useEffect(() => {
    setDataLoaded(true);
  }, [intervalItems]);

  useEffect(() => {
    setLoading(!dataLoaded);
  }, [dataLoaded]);

  /* API */
  const addProjectUser = () => {
    setLoading(true);
    ProjectHelpers.addUser(
      parseInt(document.getElementById("add-user").value),
      props.project.projectId
    ).finally(() => {
      reload();
      setShowCreateModal(false);
    });
  };

  const removeProjectUser = (part) => {
    setLoading(true);
    const data = {
      UserId: part.userId,
      ProjectId: props.project.projectId,
    };
    Helpers.removeProjectUser(
      data,
      part.probabilityScoreSubmitted || part.consequenceScoreSubmitted
    )
      .then(reload)
      .finally(() => {
        setShowModalDialog(false);
      });
  };

  function handleEdit() {
    ProjectHelpers.editUser(
      selectedParticipant,
      props.project.projectId,
      document.getElementById("part-interval")?.checked
        ? props.currInterval.intervalId
        : undefined
    )
      .then(reload)
      .finally(() => setShowEditModal(false));
  }

  function addAllUsersToInterval() {
    setLoading(true);
    Helpers.addAllActiveUsersToInterval(props.currInterval.intervalId).finally(
      reload
    );
  }

  function removeUserFromInterval(part) {
    setLoading(true);
    IntervalHelpers.removeUser(part, props.currInterval).finally(reload);
  }

  /* HELPERS */
  function reload() {
    setDataLoaded(false);
    props.getCurrInterval();
    props.getParticipants();
  }

  function setDeleteParticipantDialog(part) {
    setDialogTitle("Remove Participant");
    const hasScoresMessage =
      " All associated scores will be deleted and the project will need to be recalculated. ";
    setDialogMessage(
      `${part.firstName} ${part.lastName} will be removed from ${
        props.project.projectName
      }. ${
        part.probabilityScoreSubmitted || part.consequenceScoreSubmitted
          ? hasScoresMessage
          : ""
      }Continue?`
    );
    setDialogAction(() => () => removeProjectUser(part)); // I hate that I have to do this
    setShowModalDialog(true);
  }

  function setRemoveIntervalPartDialog(partId, setShowAncestor) {
    const part = props.participants.find((p) => p.userId === partId);
    if (part === undefined) {
      alert(
        `Could not remove participant with ID ${partId}. Reason: Participant does not exist in project.`
      );
    } else {
      setDialogTitle("Remove Participant From Interval");
      setDialogMessage(
        `${part.firstName} ${part.lastName} will be removed from ${
          props.project.projectName
        }. ${
          part.probabilityScoreSubmitted || part.consequenceScoreSubmitted
            ? "All associated scores will be deleted and the project will need to be recalculated. "
            : ""
        }Continue?`
      );
      setDialogAction(() => () => removeUserFromInterval(part));
      setDialogOnClose(() => () => setShowAncestor(true));
      setShowModalDialog(true);
    }
  }

  function getUserIcon(user) {
    if (user.isAdmin) {
      return <i className="fa-solid fa-user-gear fa-lg" />;
      // return (
      //   <TooltipIcon
      //     id={`user-${user.userId}`}
      //     content="Admin"
      //     place="top"
      //     iconClass="fa-solid fa-user-gear fa-lg"
      //   />
      // );
    }
    if (user.isCorporateAdmin) {
      return <i className="fa-solid fa-users-gear fa-lg" />;
    }
    if (user.isModerator) {
      return <i className="fa-solid fa-user-shield fa-lg" />;
    }
    return null;
  }

  function handleUserSelect(e) {
    if (parseInt(e.target.value) === 0) {
      setCreateNew(true);
    } else {
      setCreateNew(false);
    }
  }

  function allPartsAdded() {
    return props.currInterval.users.length === props.participants.length;
  }

  return (
    <div className="tab-content">
      {loading ? <Loading /> : null}
      {/* Add Participant to Project */}
      <ModalCustom
        show={showCreateModal}
        setShow={setShowCreateModal}
        title={`Add Participant to ${props.project.projectName}`}
        action={addProjectUser}
        buttonText="Add"
        onClose={() => setCreateNew(false)}
      >
        <select id="add-user" onChange={(e) => handleUserSelect(e)}>
          <option value={null}></option>
          <option value={0}>+ Create New Participant</option>
          {props.allUsers
            .filter((u) => !props.participants.some((p) => p.userId === u.id))
            .map((u, index) => (
              <option value={u.id} key={index}>
                {`${u.firstName} ${u.lastName}`}
              </option>
            ))}
        </select>
        {!createNew ? null : (
          <div className="modal-fields-container-compact">
            {/* First Name */}
            <div className="modal-field-compact">
              <label htmlFor="create-first-name">First Name</label>
              <input
                type="text"
                id="create-first-name"
                placeholder="First Name"
                autoFocus
              />
            </div>

            {/* Last Name */}
            <div className="modal-field-compact">
              <label htmlFor="create-last-name">Last Name</label>
              <input
                type="text"
                id="create-last-name"
                placeholder="Last Name"
              />
            </div>

            {/* Email */}
            <div className="modal-field-compact">
              <label htmlFor="create-email">Email</label>
              <input
                type="email"
                id="create-email"
                placeholder="user@email.com"
              />
            </div>

            {/* Phone # */}
            <div className="modal-field-compact">
              <label htmlFor="create-email">Phone #</label>
              <input
                type="tel"
                id="create-tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                placeholder="123-456-7890"
              />
            </div>

            {/* Department */}
            <div className="modal-field-compact">
              <label htmlFor="create-last-name">Department</label>
              <input
                type="text"
                id="create-department"
                placeholder="Department"
              />
            </div>

            {/* User creds */}
            <div className="modal-field-compact">
              <label htmlFor="create-email">User Privileges</label>
              <select>
                <option></option>
                <option value={0}>Admin</option>
                <option value={1}>Corporate Admin</option>
                <option value={2}>Moderator</option>
              </select>
            </div>
          </div>
        )}
      </ModalCustom>

      {/* Dialog Box */}
      <ModalDialog
        show={showModalDialog}
        setShow={setShowModalDialog}
        title={dialogTitle}
        message={dialogMessage}
        action={dialogAction}
        onClose={dialogOnClose}
      />

      {/* Context Menu */}
      <TabContextMenu>
        <button onClick={() => setShowCreateModal(true)}>
          Add Participant
        </button>
        {!dataLoaded || props.currInterval.doesNotExist ? null : (
          <IntervalItemTable
            title="Interval Participants"
            itemName="Participants"
            items={intervalItems}
            addAllAction={allPartsAdded() ? null : addAllUsersToInterval}
            buttonText="Add All"
            removeAction={setRemoveIntervalPartDialog}
          />
        )}
      </TabContextMenu>

      {!dataLoaded ? null : (
        <div className="content-container">
          {!selectedParticipant ? null : (
            // Edit Participant
            <ModalForm
              show={showEditModal}
              setShow={setShowEditModal}
              title="Edit Participant"
              action={handleEdit}
              buttonText="Save"
            >
              <FormPage>
                <FormInput
                  id="part-firstname"
                  label="First Name"
                  placeholder={selectedParticipant.firstName}
                  disabled
                />
                <FormInput
                  id="part-lastname"
                  label="Last Name"
                  placeholder={selectedParticipant.lastName}
                  disabled
                />
                <FormInput
                  type="email"
                  id="part-email"
                  label="Email"
                  placeholder={selectedParticipant.email}
                  disabled
                />
                <FormInput
                  id="part-department"
                  label="Department"
                  placeholder={selectedParticipant.department}
                  disabled
                />
                <FormInput
                  type="telephone"
                  id="part-phone"
                  label="Phone Number"
                  placeholder={selectedParticipant.phone}
                  disabled
                />
                {props.currInterval.users.some(
                  (u) => u.userId === selectedParticipant.userId
                ) ? null : (
                  <FormInput
                    type="checkbox"
                    id="part-interval"
                    label="Add to Interval"
                    disabled
                  />
                )}
                {/* dropdown to select user privileges */}
              </FormPage>
            </ModalForm>
          )}

          {/* Participant Table */}
          <table id="participant-table">
            <thead>
              <tr>
                <th>Name</th>
                <th colSpan="2" className="table-icon nowrap">
                  P.F. / B.F.
                </th>
                {/* <th>Position</th> */}
                <th>Department</th>
                <th>Email</th>
                {/* Delete */}
                <th className="table-icon nowrap"></th>
              </tr>
            </thead>
            <tbody>
              {props.participants.map((p) => (
                <tr key={p.userId}>
                  <td className="part-name">
                    {`${p.firstName} ${p.lastName}`}
                    {getUserIcon(p)}
                  </td>
                  <TableIcon
                    cellClass={
                      p.probabilityScoreSubmitted ? "green-icon" : "red-icon"
                    }
                    iconClass={`fa-solid fa-lg ${
                      p.probabilityScoreSubmitted ? "fa-check" : "fa-xmark"
                    }`}
                  />
                  <TableIcon
                    cellClass={
                      p.consequenceScoreSubmitted ? "green-icon" : "red-icon"
                    }
                    iconClass={`fa-solid fa-lg ${
                      p.consequenceScoreSubmitted ? "fa-check" : "fa-xmark"
                    }`}
                  />
                  {/* <td>{p.title}</td> */}
                  <td>{p.department}</td>
                  <td>{p.email}</td>
                  <td className="nowrap">
                    <Menu
                      className="data-class-menu"
                      menuButton={
                        <i className="fa-solid fa-ellipsis-vertical fa-lg" />
                      }
                      align="center"
                      position="anchor"
                      arrow={true}
                      direction="right"
                    >
                      <MenuItem
                        className="menu-item"
                        onClick={() => {
                          setSelectedParticipant(p);
                          setShowEditModal(true);
                        }}
                      >
                        <i className="fa-solid fa-pen-to-square " /> Edit
                      </MenuItem>
                      <MenuItem
                        className="menu-item menu-delete"
                        onClick={() => {
                          setDeleteParticipantDialog(p);
                        }}
                      >
                        <i className="fa-solid fa-trash" /> Remove
                      </MenuItem>
                    </Menu>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TabParticipants;
