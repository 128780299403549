import { useEffect, useState } from "react";

import Helpers from "./api/Helpers";
import AdminHome from "./components/AdminHome";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Login from "./components/Login";
import ParticipantHome from "./components/Participant/ParticipantHome";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [user, setUser] = useState({});

  const getUser = () => {
    Helpers.getUser().then((response) => setUser(response.data));
  };

  // after successful login, save user token and headers in state
  useEffect(() => {
    if (loggedIn) {
      // calls constructor to enter auth token for the current import of Headers
      new Helpers();
      getUser(admin);
    }
  }, [loggedIn]);

  function logout() {
    Helpers.logout();
    setLoggedIn(false);
    setUser({});
  }

  const loginPart = () => {
    setLoggedIn(true);
    setAdmin(false);
  };

  const loginAdmin = () => {
    setLoggedIn(true);
    setAdmin(true);
  };

  return (
    <div className="App">
      <Header
        logout={logout}
        setAdmin={setAdmin}
        loginStatus={loggedIn}
        user={user}
      />
      <main>
        {!loggedIn ? (
          <Login admin={admin} loginAdmin={loginAdmin} loginPart={loginPart} />
        ) : admin ? (
          <AdminHome />
        ) : (
          <ParticipantHome />
        )}
      </main>
      <Footer setAdmin={setAdmin} />
    </div>
  );
}

export default App;
