import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

import Helpers from "../api/Helpers";
import Loading from "./Loading";
import ModalConfirm from "./Modal/ModalConfirm";
import ModalCreate from "./Modal/ModalCreate";

const AdminProjects = (props) => {
  const [loading, setLoading] = useState(false);

  const [selectedProject, setSelectedProject] = useState({});

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const createProj = () => {
    setLoading(true);
    const data = {
      ProjectName: document.getElementById("createName").value,
      ProjectTypeId: parseInt(document.getElementById("type").value),
      CompanyId: props.currCompany.companyId,
    };
    Helpers.createProject(data)
      .then(() => {
        props.fetchProjects();
      })
      .finally(() => {
        setShowCreateModal(false);
        setLoading(false);
      });
  };

  const deleteProj = () => {
    setLoading(true);
    Helpers.deleteProject(selectedProject.projectId)
      .then(() => props.fetchProjects())
      .finally(() => {
        setShowConfirmModal(false);
        setLoading(false);
      });
  };

  return (
    <div className="admin-projects">
      {loading ? <Loading /> : null}
      {/* Create new project */}
      <ModalCreate
        show={showCreateModal}
        setShow={setShowCreateModal}
        title={`Create New Portfolio/Project`}
        action={createProj}
      >
        {/* COMPANY */}
        {/* Not to edit, only to display current company */}
        <div className="modal-field">
          <label htmlFor="company">Company</label>
          <input type="text" value={props.currCompany.companyName} readOnly />
        </div>

        {/* NAME */}
        <div className="modal-field">
          <label htmlFor="createName">Name</label>
          <input
            type="text"
            id="createName"
            placeholder="New Portfolio/Project"
          />
        </div>

        {/* PROJECT TYPE */}
        {/* Programatically creates select options for when more project types are added */}
        <div className="modal-field">
          <label htmlFor="type">Project Type</label>
          <select id="type">
            {props.projectTypes.map((pt) => (
              <option value={pt.projectTypeId} key={pt.projectTypeId}>
                {pt.projectTypeName}
              </option>
            ))}
          </select>
        </div>
      </ModalCreate>

      {/* Delete project */}
      <ModalConfirm
        show={showConfirmModal}
        setShow={setShowConfirmModal}
        title={`Delete ${
          props.projectTypes.find(
            (pt) => pt.projectTypeId === selectedProject.projectTypeId
          )?.projectTypeName
        }`}
        message={`Are you sure you want to delete ${selectedProject.projectName}?`}
        action={deleteProj}
      />

      <h3>{"Portfolio/Project List"}</h3>
      <div className="proj-select-buttons">
        <button onClick={props.deselectCompany}>Back</button>
        <button onClick={() => setShowCreateModal(true)}>
          <i className="fa-solid fa-plus" />
          &nbsp; Create New Project
        </button>
      </div>
      <ul>
        {!props.projects || props.projects.length === 0 ? (
          <p>No Projects to display</p>
        ) : (
          props.projects.map((p) => (
            <li key={p.projectId}>
              <div>
                <p onClick={() => props.selectProject(p.projectId)}>
                  {p.projectName}
                </p>
                <p>{p.projectType}</p>
                <i
                  className="fa-solid fa-trash fa-lg"
                  onClick={() => {
                    setSelectedProject(p);
                    setShowConfirmModal(true);
                  }}
                />
              </div>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default AdminProjects;
