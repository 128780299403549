const Loading = (props) => {
  const id = props?.id || "";
  const loadingText = props?.loadingText || "";

  return (
    <div className="loading-container" id={id}>
      <i className="fa-solid fa-arrows-spin fa-8x" />
      <p>{loadingText}</p>
    </div>
  );
};

export default Loading;
