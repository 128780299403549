import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

import Helpers from "../api/Helpers";
import AdminProjects from "./AdminProjects";
import AdminProjectHome from "./AdminProjectHome";
import AdminCompanies from "./AdminCompanies";
import Loading from "./Loading";

const AdminHome = () => {
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [allCompanies, setAllCompanies] = useState([]);
  const [currCompany, setCurrCompany] = useState({});
  const [companyProjects, setCompanyProjects] = useState([]);

  const [allProjects, setAllProjects] = useState([]);
  const [currProject, setCurrProject] = useState({});
  const [projectTypes, setProjectTypes] = useState([]);

  const [allUsers, setAllUsers] = useState([]);

  const fetchCompanies = () => {
    Helpers.getCompanies()
      .then((response) => {
        if (isEmpty(response.data)) {
          setAllCompanies({ doesNotExist: true });
        } else {
          setAllCompanies(response.data);
        }
        return;
      })
      .finally(() => fetchProjects());
  };

  const fetchProjects = () => {
    Helpers.getProjectsAdmin().then((response) => {
      if (response) {
        if (isEmpty(response.data)) {
          setAllProjects({ doesNotExist: true });
        } else {
          setAllProjects(response.data);
        }
      }
    });
  };

  const fetchProjectTypes = () => {
    Helpers.getProjectTypes().then((response) => {
      if (isEmpty(response.data)) {
        setProjectTypes({ doesNotExist: true });
      } else {
        setProjectTypes(response.data);
      }
    });
  };

  const getUsers = () => {
    Helpers.getAllInstanceUsers().then((response) => {
      setAllUsers(response.data);
    });
  };

  useEffect(() => {
    fetchCompanies();
    // fetchProjects();
    fetchProjectTypes();
    getUsers();
  }, []);

  useEffect(() => {
    if (
      !isEmpty(allCompanies) &&
      !isEmpty(allProjects) &&
      !isEmpty(projectTypes)
    ) {
      setDataLoaded(true);
      setLoading(false);
    }
  }, [allCompanies, allProjects, projectTypes]);

  useEffect(() => {
    if (!isEmpty(currCompany)) {
      setCompanyProjects(
        allProjects.filter((p) => p.companyId === currCompany.companyId)
      );
    }
    setLoading(false);
  }, [currCompany]);

  const selectCompany = (id) => {
    setLoading(true);
    setCurrCompany(allCompanies.find((c) => c.companyId === id));
  };

  const deselectCompany = () => {
    setCurrCompany(null);
    setCompanyProjects(null);
  };

  const selectProject = (projectId) => {
    setCurrProject({
      ...allProjects.find((p) => p.projectId === projectId),
    });
  };

  const deselectProject = () => {
    setCurrProject(null);
  };

  return (
    <div className="admin-home">
      {loading ? <Loading /> : null}
      {!dataLoaded ? null : isEmpty(currCompany) ? (
        <AdminCompanies
          allCompanies={allCompanies}
          selectCompany={selectCompany}
          setLoading={setLoading}
        />
      ) : isEmpty(currProject) ? (
        <AdminProjects
          currCompany={currCompany}
          allCompanies={allCompanies}
          deselectCompany={deselectCompany}
          projects={companyProjects}
          selectProject={selectProject}
          fetchProjects={fetchProjects}
          projectTypes={projectTypes}
          setLoading={setLoading}
        />
      ) : (
        <AdminProjectHome
          allCompanies={allCompanies}
          allProjects={allProjects}
          allUsers={allUsers}
          projectTypes={projectTypes}
          company={currCompany}
          project={currProject}
          users={allUsers}
          getUsers={getUsers}
          // fetchProjects={fetchProjects}
          selectProject={selectProject}
          deselectProject={deselectProject}
        />
      )}
    </div>
  );
};

export default AdminHome;
