import axios from "axios";
import axiosRetry from "axios-retry";
import configData from "./config.json";

// retries 5xx errors as per config below
axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
});

// base url for every call
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// content-type header
axios.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

// acao header
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

// handle all calls AFTER request is fulfilled
axios.interceptors.response.use(
  (response) => {
    // throw non-200 status as error and alert
    if (response.status === 200) return response;
    alertUser(`${response.config.url} success didn't respond with status 200`);
    throw response;
  },
  (error) => {
    // error 400 --> call-specific
    if (error.response.status === 400) {
      alert(error.response.data);
      // throw error to allow caller to perform error-cleanup
      throw error;
    }
    // error 401 --> retry unauthorized error
    if (error.response.status === 401) {
      const controller = new AbortController();
      if (
        tokenExpired(
          JSON.parse(localStorage.getItem("seescapeLogin")).expirationTime
        ) ??
        true
      ) {
        controller.abort("User token expired.");
        // alert("User token expired. Please re-enter your logon credentials.");
        console.error(
          "User token expired. Please re-enter your logon credentials."
        );
        Helpers.logout();
      } else {
        const amendRetryRequest = new Promise((resolve) => {
          try {
            // const token = JSON.parse(localStorage.getItem("seescapeLogin")).token;
            const token = JSON.parse(
              localStorage.getItem("seescapeLogin")
            ).token;
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            error.config.headers.Authorization = `Bearer ${token}`;
            resolve();
          } catch (err) {
            console.error(err);
          }
        });
        return amendRetryRequest.then(() => {
          return axios(error.config);
        });
      }
    }
    // error 404 -->
    if (error.response.status === 404) {
      console.error(error);
    }
    // all other errors
    console.error(error);
    throw error;
  }
);

/*------------------------
---- Helper Functions ----
------------------------*/

function tokenExpired(expiryTime) {
  const currentTime = Math.floor(Date.now() / 1000);
  console.log(
    "Time left for token: " +
      Math.floor((expiryTime - currentTime) / 60) +
      " minutes"
  );
  return expiryTime < currentTime;
}

// extract this into dedicated component for communicating with users?
function alertUser(message, response) {
  console.log(message);
  if (response !== undefined) console.log(response);
}

export default class Helpers {
  constructor() {
    // set automatic logout reminder
    setTimeout(() => {
      alert("user token is expired");
      this.logout();
    }, JSON.parse(localStorage.getItem("seescapeLogin")).expirationTime * 1000 - Date.now());

    // handle all calls BEFORE request is fulfilled
    // let reqInterceptor = axios.interceptors.request.use((config) => {
    //   const controller = new AbortController();
    //   if (
    //     tokenExpired(
    //       JSON.parse(localStorage.getItem("seescapeLogin"))?.expirationTime
    //     )
    //   ) {
    //     controller.abort("User token expired.");
    //     alert("User token expired. Please re-enter your logon credentials.");
    //     logout();
    //   }
    //   axios.interceptors.request.eject(reqInterceptor);
    //   return {
    //     ...config,
    //     signal: controller.signal,
    //   };
    // }, undefined);
  }

  static logout = () => {
    localStorage.removeItem("seescapeLogin");
    document.location.reload();
  };

  /*--------------
  ---- App.js ----
  --------------*/

  // Fetch user information on Login
  static getUser = () => {
    return axios.get(configData.GET.USER).then((response) => {
      return response;
    });
  };

  /*----------------
  ---- Login.js ----
  ----------------*/

  // Login admin or participant based on admin param
  static login = (admin, data) => {
    return axios
      .post({
        baseURL: process.env.REACT_APP_LOGIN_URL,
        url: admin ? configData.LOGIN.ADMIN_LOGIN : configData.LOGIN.LOGIN,
        data: data,
      })
      // .post(admin ? configData.LOGIN.ADMIN_LOGIN : configData.LOGIN.LOGIN, data)
      .then((response) => {
        // save user token to localStorage
        localStorage.setItem(
          "seescapeLogin",
          JSON.stringify({
            isLoggedIn: true,
            token: response.data.token,
            email: response.data.email,
            expirationTime: response.data.expirationTime,
          })
        );
        // store token and expirationTime
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.token}`;

        // debugging
        console.log(
          "Time left for token: " +
            Math.floor(
              (response.data.expirationTime - Math.floor(Date.now() / 1000)) /
                60
            ) +
            " minutes"
        );

        return response;
      })
      .catch((err) => {
        console.error(err);
        if (err.response?.data?.errors !== undefined) {
          if (err.response.data.errors.IncorrectInfo !== null) {
            throw new Error("Incorrect username or password");
          } else {
            throw new Error("User does not have administrator privileges.");
          }
        }
      });
  };

  /*--------------------------
  ---- ParticipantHome.js ----
  --------------------------*/

  // Fetch all projects visible by participant user
  static getProjectsParticipant = () => {
    return axios.get(configData.GET.PROJECTS_PARTICIPANT);
  };

  static getProjectIntervalsParticipant = (projectId) => {
    return axios
      .get(configData.GET.PROJECT_INTERVALS_PARTICIPANT + projectId)
      .then((response) => {
        if (response.data.length === 0) {
          return { projectId: projectId, doesNotExist: true };
        } else {
          return axios
            .all([
              Helpers.getIntervalDetailsParticipant(
                response.data[0].intervalId
              ),
              Helpers.getIntervalSubmittedScores(response.data[0].intervalId),
              Helpers.getIntervalSavedScores(response.data[0].intervalId),
            ])
            .then(
              axios.spread((...responses) => {
                response.data[0].details = responses[0].data;
                response.data[0].submitted = responses[1].data;
                response.data[0].saved = responses[2].data;
                return response.data[0];
              })
            );
        }
      });
  };

  static getIntervalDetailsParticipant = (intervalId) => {
    return axios.get(configData.GET.INTERVAL_DETAILS_PARTICIPANT + intervalId);
  };

  static getIntervalSubmittedScores = (intervalId) => {
    return axios.get(configData.GET.INTERVAL_SUBMITTED_SCORES + intervalId);
  };

  static getIntervalSavedScores = (intervalId) => {
    return axios.get(configData.GET.INTERVAL_SAVED_SCORES + intervalId);
  };

  /*-----------------------
  ---- ScoringTable.js ----
  -----------------------*/

  static saveScores = (data, isProb) => {
    return axios
      .post(
        isProb
          ? configData.POST.SAVE_PROBABILITY_SCORES
          : configData.POST.SAVE_CONSEQUENCE_SCORES,
        data
      )
      .then((response) => {
        if (!response.data.savedAllScores) {
          throw response;
        }
      });
  };

  static submitScores = (data, isProb) => {
    return axios
      .post(
        isProb
          ? configData.POST.SUBMIT_PROBABILITY_SCORES
          : configData.POST.SUBMIT_CONSEQUENCE_SCORES,
        data
      )
      .then((response) => {
        if (!response.data.submittedAllScores) {
          throw response;
        }
      });
  };
  /*--------------------
  ---- AdminHome.js ----
  --------------------*/

  // Fetch all companies visible by user
  static getCompanies = () => {
    return axios.get(configData.GET.COMPANIES);
  };

  // Fetch all projects visible by admin user
  static getProjectsAdmin = () => {
    return axios.get(configData.GET.PROJECTS_ADMIN).then((response) => {
      return response;
    });
  };

  // Fetch all projects visible by moderator user
  static getProjectsModerator = () => {
    return axios.get(configData.GET.PROJECTS_MODERATOR);
  };

  // Fetch all project types
  static getProjectTypes = () => {
    return axios.get(configData.GET.PROJECT_TYPES);
  };

  static getAllInstanceUsers = () => {
    return axios.get(configData.GET.ALL_USERS);
  };

  /*------------------------
  ---- AdminProjects.js ----
  ------------------------*/

  static createProject = (data) => {
    return axios.post(configData.POST.NEW_PROJECT, data).then((response) => {
      if (response.data.newProjectId === -1) {
        alertUser(`${response.url} success responded with -1`);
      }
    });
  };

  static deleteProject = (projectId) => {
    return axios
      .delete(configData.DELETE.PROJECT + projectId)
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success didn't respond with 1`);
        }
      });
  };

  /*---------------------------
  ---- AdminProjectHome.js ----
  ---------------------------*/

  static getScorers = (projectId) => {
    return axios.get(configData.GET.PROJECT_USERS + projectId);
  };

  // Fetch all probability factors for specified project
  static getProbabilityFactors = (projectId) => {
    const pf = axios.get(configData.GET.PROJECT_PROBABILITIES + projectId);
    const pfSubcats = axios.get(
      configData.GET.PROBABILITY_SUBCATEGORIES + projectId
    );
    return axios.all([pf, pfSubcats]);
  };

  // Fetch all business factors for specified project
  static getBusinessFactors = (projectId) => {
    const bf = axios.get(configData.GET.PROJECT_CONSEQUENCES + projectId);
    const bfSubcats = axios.get(
      configData.GET.CONSEQUENCE_SUBCATEGORIES + projectId
    );
    return axios.all([bf, bfSubcats]);
  };

  // Fetch all processes for specified project
  static getProcesses = (projectId) => {
    return axios.get(configData.GET.PROJECT_MAJOR_PROCESSES + projectId);
  };

  // Fetch intervals for specified project
  static getAllIntervals = (projectId) => {
    return axios.get(configData.GET.PROJECT_INTERVALS_ADMIN + projectId);
  };

  // Fetch all data for specified interval
  static getIntervalData = (intervalId) => {
    const currIntervalCalculations = axios.get(
      configData.GET.INTERVAL_CALCULATIONS + intervalId
    );
    const currIntervalDetails = axios.get(
      configData.GET.INTERVAL_DETAILS + intervalId
    );
    const currIntervalScores = axios.get(
      configData.GET.INTERVAL_SCORES + intervalId
    );
    const currIntervalUsers = axios.get(
      configData.GET.INTERVAL_USERS + intervalId
    );
    return axios
      .all([
        currIntervalCalculations,
        currIntervalDetails,
        currIntervalScores,
        currIntervalUsers,
      ])
      .then(
        axios.spread((...responses) => {
          const intervalData = {};
          intervalData.calculations = responses[0].data;
          intervalData.details = responses[1].data;
          intervalData.scores = responses[2].data;
          intervalData.users = responses[3].data;
          return intervalData;
        })
      );
  };

  /*-------------------
  ---- TabSetup.js ----
  -------------------*/

  static setIntervalVisibility = (data) => {
    return axios.patch(configData.PATCH.SET_INTERVAL_VISIBILITY, data);
  };

  /*--------------------------
  ---- TabParticipants.js ----
  --------------------------*/

  static addProjectUser = (data) => {
    return axios.post(configData.POST.ADD_PROJECT_USER, data);
  };

  static removeProjectUser = (data, hasScored) => {
    return axios
      .delete(
        hasScored
          ? configData.DELETE.PROJECT_USER_WITH_SCORES
          : configData.DELETE.PROJECT_USER,
        { data: data }
      )
      .then((response) => {
        if (response.data === -1) {
          alertUser(`${response.url} success responded with ${response.data}`);
        }
      });
  };

  /* --------------------
  ---- TabFactors.js ----
  ---------------------*/

  // Create new factor
  static createFactor = (data, isProb) => {
    return axios
      .post(
        isProb
          ? configData.POST.NEW_PROBABILITY
          : configData.POST.NEW_CONSEQUENCE,
        data
      )
      .then((response) => {
        const newFactorId = isProb
          ? response.data.newProbabilityId
          : response.data.newConsequenceId;
        if (newFactorId === -1) {
          alertUser(
            `${response.url} success responded with ${response.data}`,
            response
          );
        } else {
          return newFactorId;
        }
      });
  };

  // Delete given factor
  static deleteFactor = (factorId, isProb, withCalcs) => {
    let url = "";
    if (isProb) {
      if (withCalcs) {
        url = configData.DELETE.PROBABILITY_WITH_CALCULATIONS;
      } else {
        url = configData.DELETE.PROBABILITY;
      }
    } else {
      if (withCalcs) {
        url = configData.DELETE.CONSEQUENCE_WITH_CALCULATIONS;
      } else {
        url = configData.DELETE.CONSEQUENCE;
      }
    }
    return axios.delete(url + factorId).then((response) => {
      if (response.data !== 1) {
        alertUser(
          `${response.url} success responded with ${response.data}`,
          response
        );
      }
    });
  };

  // Change name of given factor
  static editFactor = (data, isProb) => {
    return axios
      .patch(
        isProb
          ? configData.PATCH.EDIT_PROBABILITY
          : configData.PATCH.EDIT_CONSEQUENCE,
        data
      )
      .then((response) => {
        if (response.data !== 1) {
          alertUser(
            `${response.url} success responded with ${response.data}`,
            response
          );
        }
      });
  };

  // Edit weight and isMultiplied of given factor in given interval
  static editIntervalFactor = (data, isProb) => {
    return axios
      .patch(
        isProb
          ? configData.PATCH.EDIT_INTERVAL_PROBABILITY
          : configData.PATCH.EDIT_INTERVAL_CONSEQUENCE,
        data
      )
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success responded with ${response.data}`);
        }
      });
  };

  // Create new subcategory for probability or business factors
  static createSubcategory = (data, isProb) => {
    return axios
      .post(
        isProb
          ? configData.POST.NEW_PROBABILITY_SUBCATEGORY
          : configData.POST.NEW_CONSEQUENCE_SUBCATEGORY,
        data
      )
      .then((response) => {
        if (response.newProbabilityId === -1) {
          alertUser(
            `${response.url} success responded with ${response.data}`,
            response
          );
        }
      });
  };

  // Delete and unassign subcategory
  static deleteSubcategory = (subcategoryId, isProb, withCalcs) => {
    let url = "";
    if (isProb) {
      if (withCalcs) {
        url = configData.DELETE.PROBABILITY_SUBCATEGORY_WITH_CALCULATIONS;
      } else {
        url = configData.DELETE.PROBABILITY_SUBCATEGORY;
      }
    } else {
      if (withCalcs) {
        url = configData.DELETE.CONSEQUENCE_SUBCATEGORY_WITH_CALCULATIONS;
      } else {
        url = configData.DELETE.CONSEQUENCE_SUBCATEGORY;
      }
    }

    return axios.delete(url + subcategoryId).then((response) => {
      if (response.data !== 1) {
        alertUser(`${response.url} success didn't respond with 1`, response);
      }
    });
  };

  // Change name of given subcategory
  static editSubcategory = (data, isProb) => {
    return axios
      .patch(
        isProb
          ? configData.PATCH.EDIT_PROBABILITY_SUBCATEGORY
          : configData.PATCH.EDIT_CONSEQUENCE_SUBCATEGORY,
        data
      )
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success didn't respond with 1`, response);
        }
      });
  };

  // Assign given subcategory to given list of factors
  static assignSubcategoryToFactors = (data, isProb) => {
    return axios
      .patch(
        isProb
          ? configData.PATCH.ADD_PROBABILITIES_TO_SUBCATEGORY
          : configData.PATCH.ADD_CONSEQUENCES_TO_SUBCATEGORY,
        data
      )
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success didn't respond with 1`, response);
        }
      });
  };

  static removeFactorFromSubcategory = (data, isProb, withCalcs) => {
    let url = "";
    if (isProb) {
      if (withCalcs) {
        url = configData.DELETE.PROBABILITY_FROM_SUBCATEGORY_WITH_CALCULATIONS;
      } else {
        url = configData.DELETE.PROBABILITY_FROM_SUBCATEGORY;
      }
    } else {
      if (withCalcs) {
        url = configData.DELETE.CONSEQUENCE_FROM_SUBCATEGORY_WITH_CALCULATIONS;
      } else {
        url = configData.DELETE.CONSEQUENCE_FROM_SUBCATEGORY;
      }
    }

    return axios.delete(url, { data: data }).then((response) => {
      if (response.data !== 1) {
        alertUser(`${response.url} success responded with ${response.data}`);
      }
    });
  };

  /*----------------------
  ---- TabProjects.js ----
  ----------------------*/

  // Create new major process
  static createMajorProcess = (data) => {
    return axios
      .post(configData.POST.NEW_MAJOR_PROCESS, data)
      .then((response) => {
        if (response.data.newMajorProcessId === -1) {
          alertUser(`${response.url} success responded with -1`);
        }
      });
  };

  // Delete major process (with or without calculations) and all child minor processes
  static deleteMajorProcess = (majorProcessId, withCalcs) => {
    return axios
      .delete(
        (withCalcs
          ? configData.DELETE.MAJOR_PROCESS_WITH_SCORES
          : configData.DELETE.MAJOR_PROCESS) + majorProcessId
      )
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success didn't respond with 1`);
        }
      });
  };

  // Edit name and description of a major process
  static editMajorProcess = (data) => {
    return axios
      .patch(configData.PATCH.EDIT_MAJOR_PROCESS, data)
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success didn't respond with 1`);
        }
      });
  };

  // Create new minor process for a given major process
  static createMinorProcess = (data) => {
    return axios
      .post(configData.POST.NEW_MINOR_PROCESS, data)
      .then((response) => {
        if (response.data.newMinorProcessId === -1) {
          alertUser(`${response.url} success responded with -1`);
        }
      });
  };

  // Delete minor process (with or without calculations)
  static deleteMinorProcess = (minorProcessId, withCalcs) => {
    return axios
      .delete(
        (withCalcs
          ? configData.DELETE.MINOR_PROCESS_WITH_SCORES
          : configData.DELETE.MINOR_PROCESS) + minorProcessId
      )
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success didn't respond with 1`);
        }
      });
  };

  // Edit name and description of a minor process
  static editMinorProcess = (data) => {
    return axios
      .patch(configData.PATCH.EDIT_MINOR_PROCESS, data)
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success didn't respond with 1`);
        }
      });
  };

  static setVOI = (data) => {
    return axios.patch(configData.PATCH.SET_INTERVAL_VOI, data);
  };

  /*----------------------
  ---- TabSettings.js ----
  ----------------------*/

  // Locks or Unlocks project based on check param
  static lockProject = (checked, projectId) => {
    return axios.patch(
      (checked
        ? configData.PATCH.LOCK_PROJECT
        : configData.PATCH.UNLOCK_PROJECT) + projectId,
      // passing null for data as a consequence of using PATCH
      null
    );
  };

  // Enabled or Disabled project scoring based on check param
  static toggleProjectScoring = (checked, projectId) => {
    return axios
      .patch(
        (checked
          ? configData.PATCH.OPEN_PROJECT_SCORING
          : configData.PATCH.OPEN_PROJECT_SCORING) + projectId
      )
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success didn't respond with 1`);
        }
      });
  };

  static setProjectNumScores = (data) => {
    return axios
      .patch(configData.PATCH.SET_PROJECT_NUM_SCORES, data)
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success didn't respond with 1`);
        }
      });
  };

  static createInterval = (data) => {
    return axios.post(configData.POST.NEW_INTERVAL, data).then((response) => {
      if (response.data.newIntervalId === -1) {
        alertUser(`${response.url} success responded with -1`);
      }
    });
  };

  static editInterval = (data) => {
    return axios
      .patch(configData.PATCH.EDIT_INTERVAL, data)
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success didn't respond with 1`);
        }
      });
  };

  // Locks or Unlocks interval based on check param
  static lockInterval = (checked, intervalId) => {
    return axios
      .patch(
        (checked
          ? configData.PATCH.LOCK_INTERVAL
          : configData.PATCH.UNLOCK_INTERVAL) + intervalId,
        // passing null for data as a consequence of using PATCH
        null
      )
      .then((response) => {
        if (response.data !== 1) {
          throw new Error("lockInterval success didn't respond with 1");
        }
      });
  };

  // // Enables or Disabled interval scoring based on check param
  // static toggleIntervalScoring = (checked, intervalId) => {
  //   return axios
  //     .patch(
  //       (checked
  //         ? configData.PATCH.OPEN_INTERVAL_SCORING
  //         : configData.PATCH.CLOSE_INTERVAL_SCORING) + intervalId
  //     )
  //     .then((response) => {
  //       if (response.data !== 1) {
  //         alertUser(`${response.url} success didn't respond with 1`);
  //       }
  //     });
  // };

  // // Calculates interval with available scores
  // static calculateInterval = (intervalId) => {
  //   return axios.patch(configData.PATCH.CALCULATE_INTERVAL + intervalId);
  // };

  // Add all given factors to given interval
  static addFactorsToInterval = (data, isProb) => {
    return axios
      .post(
        isProb
          ? configData.POST.ADD_PROBABILITIES_TO_INTERVAL
          : configData.POST.ADD_CONSEQUENCES_TO_INTERVAL,
        data
      )
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success responded with ${response.data}`);
        }
      });
  };

  static removeFactorsFromInterval = (data, isProb, withCalcs) => {
    let url = "";
    if (isProb) {
      if (withCalcs) {
        url = configData.DELETE.PROBABILITIES_FROM_INTERVAL_WITH_CALCULATIONS;
      } else {
        url = configData.DELETE.PROBABILITIES_FROM_INTERVAL;
      }
    } else {
      if (withCalcs) {
        url = configData.DELETE.CONSEQUENCES_FROM_INTERVAL_WITH_CALCULATIONS;
      } else {
        url = configData.DELETE.CONSEQUENCES_FROM_INTERVAL;
      }
    }
    return axios.delete(url, { data: data }).then((response) => {
      if (response.data !== 1) {
        alertUser(`${response.url} success didn't respond with 1`);
      }
    });
  };

  // Create a new scale description for the given factor and interval
  static createFactorScaleDescription = (data, isProb) => {
    return axios
      .post(
        isProb
          ? configData.POST.NEW_PROBABILITY_SCALE_DESCRIPTION
          : configData.POST.NEW_CONSEQUENCE_SCALE_DESCRIPTION,
        data
      )
      .then((response) => {
        if (response.data.newProbabilityScaleDescriptionId === -1) {
          alertUser(`${response.url} success responded with -1`);
        }
      });
  };

  static editFactorScaleDescription = (data, isProb) => {
    return axios
      .patch(
        isProb
          ? configData.PATCH.EDIT_PROBABILITY_SCALE_DESCRIPTION
          : configData.PATCH.EDIT_CONSEQUENCE_SCALE_DESCRIPTION,
        data
      )
      .then((response) => {
        if (response.data === -1) {
          alertUser(`${response.url} success responded with -1`);
        }
      });
  };

  static deleteFactorScaleDescription = (data, isProb) => {
    return axios
      .delete(
        isProb
          ? configData.DELETE.PROBABILITY_SCALE_DESCRIPTION
          : configData.DELETE.CONSEQUENCE_SCALE_DESCRIPTION,
        { data: data }
      )
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success didn't respond with 1`);
        }
      });
  };

  // Adds all active participants to given interval
  static addAllActiveUsersToInterval = (intervalId) => {
    return axios
      .post(configData.POST.ADD_ALL_ACTIVE_USERS_TO_INTERVAL + intervalId)
      .then((response) => {
        if (response.data !== 1) {
          alertUser(
            "addAllActiveUsersToInterval success didn't respond with 1",
            response
          );
        }
      });
  };

  static addUsersToInterval = (data) => {
    return axios
      .post(configData.POST.ADD_USERS_TO_INTERVAL, data)
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success responded with ${response.data}`);
        }
      });
  };

  static editIntervalUserCanScore = (data) => {
    return axios
      .patch(configData.PATCH.EDIT_INTERVAL_USER_CAN_SCORE, data)
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success responded with ${response.data}`);
        }
      });
  };

  static removeUserFromInterval = (data, withScores) => {
    return axios
      .delete(
        withScores
          ? configData.DELETE.INTERVAL_USER_WITH_SCORES
          : configData.DELETE.INTERVAL_USER,
        { data: data }
      )
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success responded with ${response.data}`);
        }
      });
  };

  static addMajorProcessesToInterval = (data) => {
    return axios
      .post(configData.POST.ADD_MAJOR_PROCESSES_TO_INTERVAL, data)
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success responded with ${response.data}`);
        }
      });
  };

  static addMinorProcessesToInterval = (data) => {
    return axios
      .post(configData.POST.ADD_MINOR_PROCESSES_TO_INTERVAL, data)
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success responded with ${response.data}`);
        }
      });
  };

  static removeMajorProcessesFromInterval = (data, withScores) => {
    return axios
      .delete(
        withScores
          ? configData.DELETE.MAJOR_PROCESSES_FROM_INTERVAL_WITH_SCORES
          : configData.DELETE.MAJOR_PROCESSES_FROM_INTERVAL,
        { data: data }
      )
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success responded with ${response.data}`);
        }
      });
  };

  static removeMinorProcessesFromInterval = (data, withScores) => {
    return axios
      .delete(
        withScores
          ? configData.DELETE.MINOR_PROCESSES_FROM_INTERVAL_WITH_SCORES
          : configData.DELETE.MINOR_PROCESSES_FROM_INTERVAL,
        { data: data }
      )
      .then((response) => {
        if (response.data !== 1) {
          alertUser(`${response.url} success responded with ${response.data}`);
        }
      });
  };
}
