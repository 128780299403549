const Footer = (props) => {
  return (
    <footer id="bottom-bar">
      <ul>
        <li onClick={() => props.setAdmin(false)}>Participant</li>
        <li onClick={() => props.setAdmin(true)}>Administrator</li>
        <li>Legal</li>
      </ul>
      <p>© {new Date().getFullYear()} - Seescape Analytics™</p>
    </footer>
  );
};

export default Footer;
