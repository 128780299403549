import axios from "axios";
import { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Helpers from "../api/Helpers";
import Sidebar from "./Sidebar/Sidebar";
import TabAdmin from "./Tabs/TabAdmin";
import TabFactors from "./Tabs/TabFactors";
import TabImport from "./Tabs/TabImport";
import TabParticipants from "./Tabs/TabParticipants";
import TabProjects from "./Tabs/TabProjects";
import TabReports from "./Tabs/TabReports";
import TabSettings from "./Tabs/TabSettings";
import TabSetup from "./Tabs/TabSetup";

const AdminProjectHome = (props) => {
  const [participants, setParticipants] = useState([]);
  const [pf, setPf] = useState([]);
  const [pfSubcats, setPfSubcats] = useState([]);
  const [bf, setBf] = useState([]);
  const [bfSubcats, setBfSubcats] = useState([]);
  const [currInterval, setCurrInterval] = useState({});
  const [intervals, setIntervals] = useState([]);
  const [majorProcesses, setMajorProcesses] = useState([]);
  const [minorProcesses, setMinorProcesses] = useState([]);

  const [tabIndex, setTabIndex] = useState(0);

  // reset all project info when switching through sidebar
  const resetProjectInfo = () => {
    setParticipants([]);
    setPf([]);
    setBf([]);
    setCurrInterval({});
    setIntervals([]);
    setMajorProcesses([]);
    setMinorProcesses([]);
  };

  /* creates array of minor processes for given major process */
  const extractMinp = (majp) => {
    let minp = [];
    majp.forEach((p) => {
      minp = minp.concat(p.minorProcesses);
    });
    return minp;
  };

  // creates array of subcat IDs for each given factor
  const extractSubcats = (factors, subcats, isProb) => {
    for (let i = 0; i < factors.length; i++) {
      factors[i].subcategories = [];
      subcats
        .filter((subcat) =>
          isProb
            ? subcat.probabilityIds.includes(factors[i].probabilityId)
            : subcat.consequenceIds.includes(factors[i].consequenceId)
        )
        .forEach((as) =>
          factors[i].subcategories.push(
            isProb ? as.probSubcategoryId : as.consSubcategoryId
          )
        );
    }
    return factors;
  };

  const getBf = () => {
    Helpers.getBusinessFactors(props.project.projectId).then(
      axios.spread((...responses) => {
        if (responses[0].data.length === 0) {
          setBf({ doesNotExist: true });
        } else {
          setBf(extractSubcats(responses[0].data, responses[1].data, false));
        }
        if (responses[1].data.length === 0) {
          setBfSubcats({ doesNotExist: true });
        } else {
          setBfSubcats(responses[1].data);
        }
      })
    );
  };

  // update param ensure only current interval data is fetched
  const getCurrInterval = async (update) => {
    // first get all intervals, then using the ID of the latest get remaining info
    Helpers.getAllIntervals(props.project.projectId)
      .then((allIntervalsResponse) => {
        if (allIntervalsResponse.data.length === 0) {
          // if no intervals, set flag in otherwise empty object
          setCurrInterval({ doesNotExist: true });
          setIntervals({ doesNotExist: true });
        } else {
          let currInterval =
            allIntervalsResponse.data[allIntervalsResponse.data.length - 1];
          return Helpers.getIntervalData(currInterval.intervalId).then(
            (response) => {
              currInterval = {
                ...currInterval,
                ...response,
              };
              setCurrInterval(currInterval);
              allIntervalsResponse.data[allIntervalsResponse.data.length - 1] =
                currInterval;
              return allIntervalsResponse;
            }
          );
        }
      })
      // decide if there are more intervals to get data for
      .then((response) => {
        if (response) {
          if (response.data.length === 1) {
            setIntervals(response.data);
          } else {
            getIntervals(response.data);
          }
        }
      });
  };

  const getIntervals = (allIntervals) => {
    let calls = [];
    allIntervals.forEach((interval) =>
      calls.push(Helpers.getIntervalData(interval.intervalId))
    );
    axios.all(calls).then(
      axios.spread((...responses) => {
        for (let i = 0; i < allIntervals.length; i++) {
          allIntervals[i] = {
            ...allIntervals[i],
            ...responses.find(
              (r) => allIntervals[i].intervalId === r.calculations.intervalId
            ),
          };
        }
        setIntervals(allIntervals);
      })
    );
  };

  const getParticipants = () => {
    Helpers.getScorers(props.project.projectId).then((response) => {
      if (response.data.length === 0) {
        setParticipants({ doesNotExist: true });
      }
      setParticipants(response.data);
    });
  };

  const getPf = () => {
    Helpers.getProbabilityFactors(props.project.projectId).then(
      axios.spread((...responses) => {
        if (responses[0].data.length === 0) {
          setPf({ doesNotExist: true });
        } else {
          setPf(extractSubcats(responses[0].data, responses[1].data, true));
        }
        if (responses[1].data.length === 0) {
          setPfSubcats({ doesNotExist: true });
        } else {
          setPfSubcats(responses[1].data);
        }
      })
    );
  };

  const getProcesses = () => {
    Helpers.getProcesses(props.project.projectId).then((response) => {
      if (response.data.length === 0) {
        setMajorProcesses({ doesNotExist: true });
        setMinorProcesses({ doesNotExist: true });
      } else {
        setMajorProcesses(response.data);
        setMinorProcesses(extractMinp(response.data));
      }
    });
  };

  useEffect(() => {
    getParticipants();
    getCurrInterval();
    getProcesses();
    getPf();
    getBf();
    console.log(props.project);
  }, [props.project]);

  const sidebarSelectProject = (projectId) => {
    resetProjectInfo();
    setTabIndex(0);
    props.selectProject(projectId);
  };

  let scoringItem = {
    typeId: props.project.projectTypeId,
  };

  switch (props.project.projectTypeId) {
    // Project Breakdown
    case 1:
      scoringItem.type = "Project Breakdown";
      scoringItem.singular = "Component";
      scoringItem.plural = "Components";
      scoringItem.minorSingular = "Sub-Component";
      scoringItem.minorPlural = "Sub-Components";
      break;
    // Project Portfolio
    case 2:
      scoringItem.type = "Project Portfolio";
      scoringItem.singular = "Team";
      scoringItem.plural = "Teams";
      scoringItem.minorSingular = "Project";
      scoringItem.minorPlural = "Projects";
      break;
    // not yet implemented
    // case "People Portfolio":
    // scoringItem.type = "People Portfolio";
    //   scoringItem.minorSingular = "Person";
    //   scoringItem.minorPlural = "People";
    //   break;
    // case "Project Team":
    //   scoringItem.minorSingular = "Component";
    //   scoringItem.minorPlural = "Components";
    //   break;
    default:
      console.error(`Invalid project type: ${props.project.projectTypeId}`);
      break;
  }

  return (
    <div className="admin-proj-home">
      <Sidebar
        companies={props.allCompanies}
        projects={props.allProjects}
        selectedProject={props.project}
        selectProject={sidebarSelectProject}
        canChangeProject={true}
      />
      <div className="admin-proj-tabs">
        <h3>{props.project.projectName}</h3>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>Setup</Tab>
            <Tab>Probability Factors</Tab>
            <Tab>Business Factors</Tab>
            <Tab>
              {/* Use "People" and "Projects" wording when displaying Portfolios*/}
              {props.project.projectTypeId === 2
                ? scoringItem.minorPlural
                : scoringItem.plural}
            </Tab>
            <Tab>Participants</Tab>
            <Tab disabled>Admin Scoring</Tab>
            <Tab>Reports</Tab>
            {/* <Tab>Interval Setup</Tab> */}
            <Tab>Import</Tab>
          </TabList>

          {/* SETTINGS */}
          <TabPanel>
            <TabSetup
              project={props.project}
              currInterval={currInterval}
              pf={pf}
              bf={bf}
              participants={participants}
              majorProcesses={majorProcesses}
              scoringItem={scoringItem}
              setTabIndex={setTabIndex}
              getCurrInterval={getCurrInterval}
            />
            {/* <TabSettings
              project={props.project}
              currInterval={currInterval}
              intervals={intervals}
              pf={pf}
              bf={bf}
              participants={participants}
              majorProcesses={majorProcesses}
              getCurrInterval={getCurrInterval}
              scoringItem={scoringItem}
              projectTypes={props.projectTypes}
            /> */}
          </TabPanel>

          {/* PROBABILITIES */}
          <TabPanel>
            <TabFactors
              isProb={true}
              factors={pf}
              subcats={pfSubcats}
              project={props.project}
              currInterval={currInterval}
              getCurrInterval={getCurrInterval}
              getFactors={getPf}
            />
          </TabPanel>

          {/* BUSINESS VALUE */}
          <TabPanel>
            <TabFactors
              isProb={false}
              factors={bf}
              subcats={bfSubcats}
              project={props.project}
              currInterval={currInterval}
              getCurrInterval={getCurrInterval}
              getFactors={getBf}
            />
          </TabPanel>

          {/* PROJECTS */}
          <TabPanel>
            <TabProjects
              project={props.project}
              majorProcesses={majorProcesses}
              minorProcesses={minorProcesses}
              getProcesses={getProcesses}
              scoringItem={scoringItem}
              currInterval={currInterval}
              getCurrInterval={getCurrInterval}
            />
          </TabPanel>

          {/* PARTICIPANTS */}
          <TabPanel>
            <TabParticipants
              participants={participants}
              project={props.project}
              allUsers={props.allUsers}
              getParticipants={getParticipants}
              currInterval={currInterval}
              getCurrInterval={getCurrInterval}
            />
          </TabPanel>

          {/* ADMIN */}
          <TabPanel>
            <TabAdmin
              currInterval={currInterval}
              // processes={minorProcesses}
              processes={majorProcesses}
              pf={pf}
              bf={bf}
              scoringItem={scoringItem}
              getCurrInterval={getCurrInterval}
              getProcesses={getProcesses}
              getPf={getPf}
              getBf={getBf}
            />
          </TabPanel>

          {/* REPORTS */}
          <TabPanel>
            <TabReports
              project={props.project}
              currInterval={currInterval}
              intervals={intervals}
              participants={participants}
              majorProcesses={majorProcesses}
              minorProcesses={minorProcesses}
              bf={bf}
              bfSubcats={bfSubcats}
              pf={pf}
              pfSubcats={pfSubcats}
              scoringItem={scoringItem}
            />
          </TabPanel>

          {/* IMPORT */}
          <TabPanel>
            <TabImport />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default AdminProjectHome;
