import axios from "axios";

import Helpers from "./Helpers";
import ProjectHelpers from "./ProjectHelpers";
import configData from "./config.json";

export default class IntervalHelpers {
  // API
  static startNewInterval(
    projectId,
    currInterval,
    label,
    addProbs,
    addCons,
    addProcs,
    addParts
  ) {
    // if full copy
    if (addProbs && addCons && addProcs && addParts) {
      return Helpers.createInterval({
        ProjectId: projectId,
        IntervalLabel: label,
        CopyDetailsFromIntervalId: currInterval.intervalId,
      });
    } else {
      return Helpers.createInterval({
        ProjectId: projectId,
        IntervalLabel: label,
      }).then((response) => {
        // returns an object with key newIntervalId
        if (!addProbs && !addCons && !addProcs && !addParts) {
          // nothing to copy, simply return response
          return response;
        } else {
          const calls = [];
          if (addProbs) {
            calls.push(
              this.addProbFactorsToInterval(
                response.data.newIntervalId,
                getIntervalProbIds(currInterval)
              )
            );
            // TODO: also import scale descriptions
          }
          if (addCons) {
            calls.push(
              this.addBusFactorsToInterval(
                response.data.newIntervalId,
                getIntervalConsIds(currInterval)
              )
            );
            // TODO: also import scale descriptions
          }
          if (addProcs) {
            calls.push(
              this.addProcessesToInterval(
                response.data.newIntervalId,
                getIntervalProcIds(currInterval)
              )
            );
          }
          if (addParts) {
            calls.push(
              this.addUsersToInterval(
                response.data.newIntervalId,
                getIntervalUserIds(currInterval)
              )
            );
          }
          return Promise.all(calls);
        }
      });
    }
  }

  static openScoring(intervalId, projectId) {
    const calls = [];
    calls.push(
      axios.patch(configData.PATCH.OPEN_INTERVAL_SCORING + intervalId)
    );
    calls.push(
      Helpers.setIntervalVisibility({
        IntervalId: intervalId,
        IsVisibleToParticipants: true,
        IsVisibleToModerators: true,
      })
    );
    calls.push(ProjectHelpers.openScoring(projectId));
    return Promise.all(calls);
  }

  static closeScoring(intervalId, projectId) {
    const calls = [];
    calls.push(
      axios.patch(configData.PATCH.CLOSE_INTERVAL_SCORING + intervalId)
    );
    calls.push(ProjectHelpers.closeScoring(projectId));
    return Promise.all(calls);
  }

  static calculateScores(intervalId) {
    return axios.patch(configData.PATCH.CALCULATE_INTERVAL + intervalId);
  }

  static setScoringScales(projectId, probScale, consScale) {
    return Helpers.setProjectNumScores({
      ProjectId: projectId,
      NewNumProbScores: probScale,
      NewNumConsScores: consScale,
    });
  }

  static addAllProbFactors(intervalId) {
    return axios.post(
      configData.POST.ADD_ALL_PROBABILITIES_TO_INTERVAL + intervalId
    );
  }

  static addProbFactorsToInterval(intervalId, factorIds) {
    return Helpers.addFactorsToInterval(
      {
        IntervalId: intervalId,
        ProbabilityIds: factorIds,
      },
      true
    );
  }

  static addAllBusFactors(intervalId) {
    return axios.post(
      configData.POST.ADD_ALL_CONSEQUENCES_TO_INTERVAL + intervalId
    );
  }

  static addBusFactorsToInterval(intervalId, factorIds) {
    return Helpers.addFactorsToInterval(
      {
        IntervalId: intervalId,
        ConsequenceIds: factorIds,
      },
      false
    );
  }

  static removeProbFactor(interval, factor) {
    const hasCalcs = this.pfHasCalculations(interval);
    return Helpers.removeFactorsFromInterval(
      {
        IntervalId: interval.intervalId,
        ProbabilityIds: [factor.probabilityId],
      },
      true,
      hasCalcs
    );
  }

  static removeBusFactor(interval, factor) {
    const hasCalcs = this.bfHasCalculations(interval);
    return Helpers.removeFactorsFromInterval(
      {
        IntervalId: interval.intervalId,
        ConsequenceIds: [factor.consequenceId],
      },
      false,
      hasCalcs
    );
  }

  static addProcessesToInterval(intervalId, processIds) {
    let ids = [];
    if (Array.isArray(processIds)) {
      ids = processIds;
    } else {
      ids.push(processIds);
    }
    return Helpers.addMinorProcessesToInterval({
      IntervalId: intervalId,
      MinorProcessIds: ids,
    });
  }

  static removeMajorProcesses(interval, processes) {
    const ids = [];
    if (Array.isArray(processes)) {
      processes.forEach((majp) => {
        ids.push(majp.majorProcessId);
      });
    } else {
      ids.push(processes.majorProcessId ?? processes.id);
    }
    const hasCalcs = ids.some((majpId) =>
      this.minpHasCalculations(interval, majpId)
    );
    return Helpers.removeMajorProcessesFromInterval(
      {
        IntervalId: interval.intervalId,
        MajorProcessIds: ids,
      },
      hasCalcs
    );
  }

  static removeMinorProcesses(interval, processes) {
    const ids = [];
    if (Array.isArray(processes)) {
      processes.forEach((minp) => {
        ids.push(minp.minorProcessId);
      });
    } else {
      ids.push(processes.minorProcessId ?? processes.id);
    }
    const hasCalcs = ids.some((minpId) =>
      this.minpHasCalculations(interval, minpId)
    );
    return Helpers.removeMinorProcessesFromInterval(
      {
        IntervalId: interval.intervalId,
        MinorProcessIds: ids,
      },
      hasCalcs
    );
  }

  static addUsers(intervalId, userIds) {
    return Helpers.addUsersToInterval({
      IntervalId: intervalId,
      UserIds: userIds,
    });
  }

  static removeUser(interval, user) {
    return Helpers.removeUserFromInterval(
      {
        IntervalId: interval.intervalId,
        UserId: user.userId,
      },
      user.submittedAllProbabilityScores || user.submittedAllConsequenceScores
    );
  }

  // HELPERS
  static getProbabilityItems(interval, factors) {
    const items = [];
    interval.details.includedProbabilities.forEach((f) => {
      const factor = factors.find((e) => e.probabilityId === f.probabilityId);
      items.push({
        name: factor.description,
        id: f.probabilityId,
        ready: factorReady(f),
      });
    });
    return items;
  }

  static getBusinessItems(interval, factors) {
    const items = [];
    interval.details.includedConsequences.forEach((f) => {
      const factor = factors.find((e) => e.consequenceId === f.consequenceId);
      items.push({
        name: factor.description,
        id: f.consequenceId,
        ready: factorReady(f),
      });
    });
    return items;
  }

  static getProcessItems(interval, processes) {
    const items = [];
    interval.details.includedProcesses.forEach((intMajp) => {
      const majp = processes.find(
        (p) => p.majorProcessId === intMajp.majorProcessId
      );
      const minps = majp.minorProcesses.filter((p) =>
        intMajp.minorProcesses.includes(p.minorProcessId)
      );
      const subItems = [];
      minps.forEach((minp) =>
        subItems.push({
          name: minp.minorProcessName,
          id: minp.minorProcessId,
          ready: true,
        })
      );
      items.push({
        name: majp.majorProcessName,
        id: majp.majorProcessId,
        ready: null,
        subItems: subItems,
      });
    });
    return items;
  }

  static getParticipantItems(interval, users) {
    const items = [];
    interval.users.forEach((u) => {
      const user = users.find((e) => e.userId === u.userId);
      if (user !== undefined) {
        items.push({
          name: `${user.firstName} ${user.lastName}`,
          id: u.userId,
          ready: participantReady(u),
        });
      } else {
        console.error(`Cannot find interval user with ID ${u.userId}`);
        items.push({
          name: "<Unknown user>",
          id: u.userId,
          ready: false,
        });
      }
    });
    return items;
  }

  static pfHasCalculations(interval) {
    return interval.calculations.medianProbabilityValue > 0;
  }

  static bfHasCalculations(interval) {
    return interval.calculations.medianConsequenceValue > 0;
  }

  static majpHasCalculations(interval, majpId) {
    return interval.details.includedProcesses.some(
      (majp) =>
        majp.majorProcessId === majpId &&
        majp.minorProcesses.some((minp) =>
          this.minpHasCalculations(interval, minp)
        )
    );
  }

  static minpHasCalculations(interval, minpId) {
    return interval.calculations.processCalculations.some(
      (pc) => pc.processId === minpId
    );
  }
}

// LOCAL HELPERS
function factorReady(factor) {
  return factor.weight && factor.scaleDescriptions.length > 0;
}

function participantReady(part) {
  return part.canScoreProbability || part.canScoreConsequence;
}

function getIntervalProbIds(interval) {
  const ids = [];
  interval.details.includedProbabilities.forEach((f) =>
    ids.push(f.probabilityId)
  );
  return ids;
}

function getIntervalConsIds(interval) {
  const ids = [];
  interval.details.includedConsequences.forEach((f) =>
    ids.push(f.consequenceId)
  );
  return ids;
}

function getIntervalProcIds(interval) {
  const ids = [];
  interval.details.includedProcesses.forEach((majp) =>
    ids.push(...majp.minorProcesses)
  );
  return ids;
}

function getIntervalUserIds(interval) {
  const ids = [];
  interval.users.forEach((u) => ids.push(u.userId));
  return ids;
}
