const ParticipantScorecard = (props) => {
  function handleButtonClick() {
    console.log("viewing scoring for " + props.title);
    props.action();
  }

  return (
    <div className="part-scorecard">
      <h2>{props.title}</h2>
      <button onClick={handleButtonClick}>
        <h3>Score</h3>
      </button>
    </div>
  );
};

export default ParticipantScorecard;
