const ReportCard = (props) => {
  const openReport = () => {
    if (props.enabled) {
      props.displayReport(props.name);
    }
  };

  return (
    <div
      className={`report-card${!props.enabled ? " disabled" : ""}`}
      onClick={openReport}
    >
      <i className={props.icon ?? "fa-solid fa-chart-column fa-6x"} />
      <p>{props.name}</p>
    </div>
  );
};

export default ReportCard;
