import ChecklistIcon from "../../Misc/ChecklistIcon";

const SetupSection = (props) => {
  function handleSectionToggle(e) {
    e.target.parentNode.parentNode.classList.toggle("closed");
  }

  return (
    <div
      className={`setup-section${props.setupFlag ? " closed" : ""}`}
      id={props.id ?? ""}
    >
      <div className="section-header">
        <div>
          {props.setupFlag !== undefined ? (
            <ChecklistIcon flag={props.setupFlag} />
          ) : null}
          <h2>{props.title}</h2>
        </div>
        {props.noClose ? null : (
          <i
            className="fa-solid fa-chevron-up fa-2x"
            onClick={(e) => handleSectionToggle(e)}
          />
        )}
      </div>
      <div className="section-content">
        {props.sectionText !== undefined ||
        props.sectionInstructions !== undefined ? (
          <div className="section-instructions">
            <p>{props.sectionText}</p>
            {props.sectionInstructions ? props.sectionInstructions : null}
            {props.sectionAction ? (
              <button onClick={props.sectionAction}>Go</button>
            ) : null}
          </div>
        ) : null}
        <div className={props.className}>{props.children}</div>
      </div>
    </div>
  );
};

export default SetupSection;
