import Helpers from "../../api/Helpers";
import EditableInput from "../Input/EditableInput";
import SetupSection from "./Layout/SetupSection";

const IntervalSettings = (props) => {
  function handleRenameInterval(input) {
    props.setLoading(true);
    const data = {
      IntervalId: props.currInterval.intervalId,
      NewLabel: input,
    };
    Helpers.editInterval(data).finally(props.reload);
  }

  function toggleIntervalLock(e) {
    props.setLoading(true);
    Helpers.lockInterval(e.target.checked, props.currInterval.intervalId)
      .catch(() => {
        // return switch to original position
        e.target.checked = !e.target.checked;
      })
      .finally(props.reload);
  }

  function toggleProjectLock(e) {
    props.setLoading(true);
    Helpers.lockProject(e.target.checked, props.project.projectId)
      .catch(() => {
        // return switch to original position
        e.target.checked = !e.target.checked;
      })
      .finally(props.reload);
  }

  function setProbScale(input) {
    props.setLoading(true);
    const data = {
      ProjectId: props.project.projectId,
      NewNumProbScores: parseInt(input),
    };
    Helpers.setProjectNumScores(data)
      .then(() => {
        props.project.numProbScoreValues = parseInt(input);
      })
      .finally(props.reload);
  }

  function setConsScale(input) {
    props.setLoading(true);
    const data = {
      ProjectId: props.project.projectId,
      NewNumConsScores: parseInt(input),
    };
    Helpers.setProjectNumScores(data)
      .then(() => {
        props.project.numConsScoreValues = parseInt(input);
      })
      .finally(props.reload);
  }

  function setIntervalVisibility(e, forPart) {
    props.setLoading(true);
    const data = {
      IntervalId: props.currInterval.intervalId,
    };
    if (forPart) {
      data.IsVisibleToParticipants = e.target.checked;
    } else {
      data.IsVisibleToModerators = e.target.checked;
    }
    Helpers.setIntervalVisibility(data)
      .catch(() => {
        // return switch to original position
        e.target.checked = !e.target.checked;
      })
      .finally(props.reload);
  }

  return (
    <SetupSection
      title="Interval Setup"
      className="settings"
      id="interval-settings"
      noClose={true}
    >
      <h3>Interval Label</h3>
      <h3>Status</h3>

      <div className="label">
        <label htmlFor="intervalLabel">Name</label>
        <EditableInput
          key={props.currInterval.intervalLabel}
          type="text"
          id="intervalLabel"
          text={props.currInterval.intervalLabel || ""}
          placeholder={props.currInterval.intervalLabel || ""}
          onButtonClick={handleRenameInterval}
        />
      </div>

      <div className="status">
        <label htmlFor="intervalScoreSwitch">Project Locked</label>
        <label htmlFor="intervalScoreSwitch">Interval Locked</label>
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={props.project.isLocked}
            onClick={(e) => toggleProjectLock(e)}
          />
          <span className="slider round"></span>
        </label>
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={props.currInterval.isLocked}
            onClick={(e) => toggleIntervalLock(e)}
          />
          <span className="slider round"></span>
        </label>
      </div>

      <h3>Scoring Scales</h3>
      <h3>Scoring Visibility</h3>

      <div className="score-scale">
        <label htmlFor="prob-scale">Probability Scores</label>
        <label htmlFor="cons-scale">Business Scores</label>
        <EditableInput
          type="number"
          id="prob-scale"
          text={props.project.numProbScoreValues ?? ""}
          placeholder={props.project.numProbScoreValues ?? ""}
          onButtonClick={setProbScale}
        />
        <EditableInput
          type="number"
          id="cons-scale"
          text={props.project.numConsScoreValues ?? ""}
          placeholder={props.project.numConsScoreValues ?? ""}
          onButtonClick={setConsScale}
        />
      </div>

      <div className="visibility">
        <label htmlFor="projectScoreSwitch">Moderator</label>
        <label htmlFor="projectScoreSwitch">Participant</label>
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={props.currInterval.isVisibleToModerators}
            onClick={(e) => setIntervalVisibility(e, false)}
          />
          <span className="slider round"></span>
        </label>
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={props.currInterval.isVisibleToParticipants}
            onClick={(e) => setIntervalVisibility(e, true)}
          />
          <span className="slider round"></span>
        </label>
      </div>
    </SetupSection>
  );
};

export default IntervalSettings;
