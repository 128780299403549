import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import Helpers from "../../api/Helpers";
import Loading from "../Loading";
import TabContextMenu from "../Input/TabContextMenu";
import ProjectChecklist from "../Setup/ProjectChecklist";
import SetupMenu from "../Setup/SetupMenu";
import IntervalChecklist from "../Setup/IntervalChecklist";
import IntervalSettings from "../Setup/IntervalSettings";

const TabSetup = (props) => {
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [projectSetup, setProjectSetup] = useState(false);
  const [intervalSetup, setIntervalSetup] = useState(false);

  useEffect(() => {
    setDataLoaded(false);
  }, [props.project]);

  useEffect(() => {
    if (
      !isEmpty(props.currInterval) &&
      !isEmpty(props.pf) &&
      !isEmpty(props.bf) &&
      !isEmpty(props.participants) &&
      !isEmpty(props.majorProcesses)
    ) {
      if (
        props.currInterval.doesNotExist ||
        (props.currInterval.details && props.currInterval.users)
      ) {
        if (dataLoaded) {
          setLoading(false);
        } else {
          setDataLoaded(true);
        }
      }
    }
  }, [
    props.currInterval,
    props.pf,
    props.bf,
    props.participants,
    props.majorProcesses,
  ]);

  useEffect(() => {
    setLoading(!dataLoaded);
    if (dataLoaded) {
      if (!projectSetup) {
        setProjectSetup(projectSetupComplete());
      } else if (!intervalSetup) {
        setIntervalSetup(intervalSetupComplete());
      } else {
        // project and interval are complete,
        // ready to score
      }
    }
  }, [dataLoaded, projectSetup]);

  function reloadData() {
    props.getCurrInterval();
  }

  function projectSetupComplete() {
    return (
      projectChecks.parts() &&
      projectChecks.probs() &&
      projectChecks.cons() &&
      projectChecks.procs()
    );
  }

  const projectChecks = {
    probs: () => {
      return !props.pf.doesNotExist;
    },
    cons: () => {
      return !props.bf.doesNotExist;
    },
    procs: () => {
      return (
        !props.majorProcesses.doesNotExist &&
        props.majorProcesses.some((majp) => majp.minorProcesses.length > 0)
      );
    },
    parts: () => {
      return props.participants.length > 0;
    },
  };

  function intervalSetupComplete() {
    return (
      intervalChecks.parts() &&
      intervalChecks.probs() &&
      intervalChecks.cons() &&
      intervalChecks.procs()
    );
  }

  const intervalChecks = {
    // at least 1 factor included
    // all factors have a weight and at least 1 scale description
    probs: () => {
      if (props.currInterval.details) {
        return (
          props.currInterval.details.includedProbabilities.length > 0 &&
          !props.currInterval.details.includedProbabilities.some(
            (p) => !p.weight || p.scaleDescriptions.length === 0
          )
        );
      }
    },
    cons: () => {
      if (props.currInterval.details) {
        return (
          props.currInterval.details.includedConsequences.length > 0 &&
          !props.currInterval.details.includedConsequences.some(
            (c) => !c.weight || c.scaleDescriptions.length === 0
          )
        );
      }
    },
    // at least 1 major process included
    // all major processes have at least 1 minor process
    procs: () => {
      if (props.currInterval.details) {
        return (
          props.currInterval.details.includedProcesses.length > 0 &&
          !props.currInterval.details.includedProcesses.some(
            (p) => p.minorProcesses.length === 0
          )
        );
      }
    },
    // at least 1 participant included
    // all participants can score probabilities or consequences
    parts: () => {
      if (props.currInterval.users) {
        return (
          props.currInterval.users.length > 0 &&
          !props.currInterval.users.some(
            (u) => !u.canScoreProbability && !u.canScoreConsequence
          )
        );
      }
    },
  };

  return (
    <div className="tab-content" id="tab-setup">
      {loading ? <Loading /> : null}

      {!dataLoaded ? null : (
        <>
          <TabContextMenu>
            <SetupMenu
              project={props.project}
              currInterval={props.currInterval}
              scoringItem={props.scoringItem}
              projectSetup={projectSetup}
              intervalSetup={intervalSetup}
              setLoading={setLoading}
              reload={reloadData}
            />
          </TabContextMenu>
          <div className="content-container">
            <ProjectChecklist
              participants={props.participants}
              pf={props.pf}
              bf={props.bf}
              processes={props.majorProcesses}
              scoringItem={props.scoringItem}
              setTabIndex={props.setTabIndex}
              projectChecks={projectChecks}
              setupFlag={projectSetup}
            />
            {!props.currInterval.doesNotExist ? (
              <>
                <IntervalChecklist
                  currInterval={props.currInterval}
                  participants={props.participants}
                  pf={props.pf}
                  bf={props.bf}
                  processes={props.majorProcesses}
                  scoringItem={props.scoringItem}
                  setTabIndex={props.setTabIndex}
                  intervalChecks={intervalChecks}
                  setupFlag={intervalSetup}
                />
                <IntervalSettings
                  project={props.project}
                  currInterval={props.currInterval}
                  setLoading={setLoading}
                  reload={reloadData}
                />
              </>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default TabSetup;
