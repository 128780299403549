import { useState } from "react";

import IntervalHelpers from "../../api/IntervalHelpers";
import ModalForm from "../Modal/ModalForm";
import FormInput from "../Modal/Forms/FormInput";
import ModalDialog from "../Modal/ModalDialog";
import FormPage from "../Modal/Forms/FormPage";

const SetupMenu = (props) => {
  const [showNewIntervalModal, setShowNewIntervalModal] = useState(false);

  const [showModalDialog, setShowModalDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogAction, setDialogAction] = useState(null);

  function createInterval() {
    props.setLoading(true);
    const calls = [];
    calls.push(
      IntervalHelpers.startNewInterval(
        props.project.projectId,
        props.currInterval,
        document.getElementById("interval-label").value,
        document.getElementById("import-probs")?.checked,
        document.getElementById("import-cons")?.checked,
        document.getElementById("import-procs")?.checked,
        document.getElementById("import-parts")?.checked
      ).then(() => {
        setShowNewIntervalModal(false);
        props.reload();
      })
    );
    calls.push(IntervalHelpers.setScoringScales(props.project.projectId, 3, 3));
    Promise.all(calls);
  }

  function handleNewInterval() {
    setShowNewIntervalModal(true);
  }

  function handleStartScoring() {
    props.setLoading(true);
    IntervalHelpers.openScoring(
      props.currInterval.intervalId,
      props.project.projectId
    ).finally(props.reload);
  }

  function handleStopScoring() {
    props.setLoading(true);
    IntervalHelpers.closeScoring(
      props.currInterval.intervalId,
      props.project.projectId
    ).finally(props.reload);
  }

  function handleCalc() {
    props.setLoading(true);
    IntervalHelpers.calculateScores(props.currInterval.intervalId).finally(
      props.reload
    );
  }

  function setInfoDialog() {
    setDialogTitle("How do I begin?");
    setDialogMessage(
      "Follow the steps below to populate your project, prepare an interval, and initiate scoring!"
    );
    setDialogAction(null);
    setShowModalDialog(true);
  }

  return (
    <>
      <ModalDialog
        show={showModalDialog}
        setShow={setShowModalDialog}
        title={dialogTitle}
        message={dialogMessage}
        action={dialogAction}
      />

      <button className="keep-icon-square" onClick={setInfoDialog}>
        <i className="fa-solid fa-info fa-lg" />
      </button>
      {props.projectSetup ? (
        <>
          <button onClick={handleNewInterval}>Start New Interval</button>
          <ModalForm
            show={showNewIntervalModal}
            setShow={setShowNewIntervalModal}
            title="Create New Interval"
            action={createInterval}
          >
            <FormPage>
              <FormInput
                id="interval-label"
                label="Label"
                placeholder="New Interval"
                autoFocus={true}
              />
              {/* <FormInput
                type="number"
                id="prob-scale"
                label="Probability Scoring Scale"
                defaultValue={props.project.numProbScoreValues ?? 3}
              />
              <FormInput
                type="number"
                id="cons-scale"
                label="Business Scoring Scale"
                defaultValue={props.project.numConsScoreValues ?? 3}
              /> */}
            </FormPage>
            {props.currInterval.doesNotExist ? null : (
              <FormPage title="Import From Previous Interval">
                <FormInput
                  type="checkbox"
                  id="import-probs"
                  label="Probability Factors"
                />
                <FormInput
                  type="checkbox"
                  id="import-cons"
                  label="Business Factors"
                />
                <FormInput
                  type="checkbox"
                  id="import-procs"
                  label={props.scoringItem.minorPlural}
                />
                <FormInput
                  type="checkbox"
                  id="import-parts"
                  label="Participants"
                />
              </FormPage>
            )}
          </ModalForm>
        </>
      ) : null}
      {/* Stop/Start Scoring */}
      {props.intervalSetup ? (
        !props.currInterval.scoringOpen ? (
          <button className="green-background" onClick={handleStartScoring}>
            Start Scoring
          </button>
        ) : (
          <button className="red-background" onClick={handleStopScoring}>
            Stop Scoring
          </button>
        )
      ) : null}
      {/* Calculate latest scores */}
      {props.currInterval.shouldRecalculate ? (
        <button onClick={handleCalc}>Calculate</button>
      ) : null}
    </>
  );
};

export default SetupMenu;
