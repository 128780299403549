import axios from "axios";
import { isEmpty } from "lodash";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import { useEffect, useState } from "react";

import Helpers from "../../api/Helpers";
import Loading from "../Loading";
import ModalCreate from "../Modal/ModalCreate";
import ModalCustom from "../Modal/ModalCustom";
import ModalDialog from "../Modal/ModalDialog";
import ModalForm from "../Modal/ModalForm";
import FormPage from "../Modal/Forms/FormPage";
import FormInput from "../Modal/Forms/FormInput";
import SubcatBox from "../SubcatBox";
import TabContextMenu from "../Input/TabContextMenu";
import IntervalItemTable from "../Interval/IntervalItemTable";
import IntervalHelpers from "../../api/IntervalHelpers";
// import FactorTable from "../Tables/Factors/FactorTable";

const TabFactors = (props) => {
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [writeInp, setWriteInp] = useState(false);
  const [subcatChange, setSubcatChange] = useState(false);

  const [intervalItems, setIntervalItems] = useState([]);

  const [selectedFactor, setSelectedFactor] = useState({});
  const [selectedSubcat, setSelectedSubcat] = useState({});

  const [showCreateFactorModal, setShowCreateFactorModal] = useState(false);
  const [addingToInterval, setAddingToInterval] = useState(false);

  const [showEditFactorModal, setShowEditFactorModal] = useState(false);

  const [showSubcatMenuModal, setShowSubcatMenuModal] = useState(false);
  const [showAssignSubcatModal, setshowAssignSubcatModal] = useState(false);

  const [showScaleModal, setShowScaleModal] = useState(false);

  const [showModalDialog, setShowModalDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogAction, setDialogAction] = useState(null);
  const [dialogOnClose, setDialogOnClose] = useState(null);

  // testing
  useEffect(() => {
    setAddingToInterval(false);
  }, []);

  useEffect(() => {
    if (
      !isEmpty(props.factors) &&
      !isEmpty(props.subcats) &&
      !isEmpty(props.currInterval)
    ) {
      if (
        props.currInterval.doesNotExist ||
        (props.currInterval.calculations &&
          props.currInterval.details &&
          props.currInterval.scores &&
          props.currInterval.users)
      ) {
        if (
          !props.currInterval.doesNotExist &&
          (props.isProb
            ? props.currInterval.details.includedProbabilities.length
            : props.currInterval.details.includedConsequences.length) > 0
        ) {
          setIntervalItems(
            props.isProb
              ? IntervalHelpers.getProbabilityItems(
                  props.currInterval,
                  props.factors
                )
              : IntervalHelpers.getBusinessItems(
                  props.currInterval,
                  props.factors
                )
          );
        } else {
          setDataLoaded(true);
        }
      }
    }
  }, [props.factors, props.subcats, props.currInterval]);

  useEffect(() => {
    setDataLoaded(true);
  }, [intervalItems]);

  useEffect(() => {
    setLoading(!dataLoaded);
  }, [dataLoaded]);

  function refreshInformation() {
    setDataLoaded(false);
    props.getFactors();
    props.getCurrInterval(true);
  }

  const createFactor = () => {
    setLoading(true);
    const data = {
      ProjectId: props.project.projectId,
      Description: document.getElementById("createName").value,
    };
    // if there's a current interval, check if weight and multiplier values have been entered
    let editData = null;
    if (addingToInterval) {
      const weight = parseInt(document.getElementById("setWeight").value);
      const multiplied =
        document.getElementById("setMultiplied")?.checked ?? null;
      if (weight && multiplied !== undefined) {
        editData = {
          IntervalId: props.currInterval.intervalId,
          NewWeight: weight,
          NewIsMultiplied: multiplied,
        };
      }
    }
    Helpers.createFactor(data, props.isProb)
      .then((newFactorId) => {
        if (addingToInterval) {
          const addData = {
            IntervalId: props.currInterval.intervalId,
          };
          addData[props.isProb ? "ProbabilityIds" : "ConsequenceIds"] = [
            newFactorId,
          ];
          // first add new factor to current interval
          Helpers.addFactorsToInterval(addData, props.isProb).then(() => {
            if (editData !== null) {
              // then edit weight/multiplier of interval factor (if data not null)
              editData[props.isProb ? "ProbabilityId" : "ConsequenceId"] =
                newFactorId;
              Helpers.editIntervalFactor(editData, props.isProb);
            }
          });
        }
      })
      .then(refreshInformation)
      .finally(() => {
        setShowCreateFactorModal(false);
        setAddingToInterval(false);
      });
  };

  const deleteFactor = (factor) => {
    // console.log(factor);
    setLoading(true);

    function localDelete() {
      Helpers.deleteFactor(
        factor.probabilityId ?? factor.consequenceId,
        props.isProb,
        factorsHaveCalcs()
      )
        .then(refreshInformation)
        .finally(() => {
          setShowModalDialog(false);
        });
    }

    if (factorInInterval(factor)) {
      const data = {
        IntervalId: props.currInterval.intervalId,
      };
      if (props.isProb) {
        data.ProbabilityIds = [factor.probabilityId];
      } else {
        data.ConsequenceIds = [factor.consequenceId];
      }
      Helpers.removeFactorsFromInterval(
        data,
        props.isProb,
        factorsHaveCalcs()
      ).then(() => {
        localDelete();
      });
    } else {
      localDelete();
    }
  };

  const editFactor = () => {
    setLoading(true);
    const calls = [];

    // Factor Description
    const data = {
      NewDescription: document.getElementById("editName").value,
    };
    if (data.NewDescription) {
      if (props.isProb) {
        data.ProbabilityId = selectedFactor.probabilityId;
      } else {
        data.ConsequenceId = selectedFactor.consequenceId;
      }
      calls.push(Helpers.editFactor(data, props.isProb));
    }

    // Check if adding to interval
    if (addingToInterval) {
      const addData = {
        IntervalId: props.currInterval.intervalId,
      };
      addData[props.isProb ? "ProbabilityIds" : "ConsequenceIds"] = [
        selectedFactor.probabilityId ?? selectedFactor.consequenceId,
      ];
      calls.push(Helpers.addFactorsToInterval(addData, props.isProb));
    }

    // Check for weight/multipler changes
    let editData = null;
    if (addingToInterval || factorInInterval(selectedFactor)) {
      const weight =
        parseInt(document.getElementById("setWeight").value) || null;
      const multiplied =
        document.getElementById("setMultiplied")?.checked ?? null;
      if (weight !== NaN && multiplied !== undefined) {
        editData = {
          IntervalId: props.currInterval.intervalId,
          NewWeight: weight,
          NewIsMultiplied: multiplied,
        };
        editData[props.isProb ? "ProbabilityId" : "ConsequenceId"] =
          selectedFactor.probabilityId ?? selectedFactor.consequenceId;
      }
    }

    axios
      .all(calls)
      .then(() => {
        if (editData !== null) {
          Helpers.editIntervalFactor(editData, props.isProb);
        }
      })
      .then(() => {
        refreshInformation();
      })
      .finally(() => {
        setShowEditFactorModal(false);
        setAddingToInterval(false);
      });
  };

  const createSubcat = () => {
    setLoading(true);
    const data = {
      ProjectId: props.project.projectId,
      Name: document.getElementById("createSubcatName").value,
    };
    Helpers.createSubcategory(data, props.isProb)
      .then(() => {
        refreshInformation();
      })
      .finally(() => {
        setSubcatChange(!subcatChange);
        setWriteInp(false);
      });
  };

  const deleteSubcat = (subcat) => {
    // console.log(subcat);
    setLoading(true);
    Helpers.deleteSubcategory(
      subcat.probSubcategoryId ?? subcat.consSubcategoryId,
      props.isProb,
      factorsHaveCalcs()
    )
      .then(refreshInformation)
      .finally(() => {
        setShowModalDialog(false);
        setSubcatChange(!subcatChange);
      });
  };

  const editSubcat = () => {
    setLoading(true);
    const data = {
      NewName: document.getElementById("editSubcatName").value,
    };
    if (props.isProb) {
      data.ProbSubcategoryId = selectedSubcat.probSubcategoryId;
    } else {
      data.ConsSubcategoryId = selectedSubcat.consSubcategoryId;
    }
    Helpers.editSubcategory(data, props.isProb)
      .then(() => {
        refreshInformation();
      })
      .finally(() => {
        setSubcatChange(!subcatChange); // flips between t/f to trigger subcat modal re-render
        setWriteInp(false);
      });
  };

  const assignSubcats = () => {
    setLoading(true);
    const addRemoveIds = getAddRemoveIds();
    if (addRemoveIds.length === 0) {
      setshowAssignSubcatModal(false);
      setLoading(false);
    } else {
      axios
        .all(getSubcatCalls(addRemoveIds))
        .then(() => {
          refreshInformation();
        })
        .finally(() => {
          setshowAssignSubcatModal(false);
        });
    }
  };

  function addAllFactorsToInterval() {
    setLoading(true);
    let promise = null;
    if (props.isProb) {
      promise = IntervalHelpers.addAllProbFactors(
        props.currInterval.intervalId
      );
    } else {
      promise = IntervalHelpers.addAllBusFactors(props.currInterval.intervalId);
    }
    promise.then(refreshInformation);
  }

  function removeFactorFromInterval(factor) {
    setLoading(true);
    let promise = null;
    if (props.isProb) {
      promise = IntervalHelpers.removeProbFactor(props.currInterval, factor);
    } else {
      promise = IntervalHelpers.removeBusFactor(props.currInterval, factor);
    }
    promise.then(refreshInformation);
  }

  function setScale() {
    const input = document.querySelectorAll(".scoring-scale > input");
    const scales = getFactorIntervalInfo(selectedFactor).scaleDescriptions;
    const calls = [];
    input.forEach((i) => {
      const scoreValue = parseInt(i.id.split("-")[1]);
      const valueKey = props.isProb ? "probScoreValue" : "consScoreValue";
      const data = {
        IntervalId: props.currInterval.intervalId,
        ScoreValue: scoreValue,
      };
      props.isProb
        ? (data.ProbabilityId = selectedFactor.probabilityId)
        : (data.ConsequenceId = selectedFactor.consequenceId);
      const existing = scales.find((sd) => sd[valueKey] === scoreValue);
      if (existing && existing.description !== i.value) {
        data.NewDescription = i.value;
        calls.push(Helpers.editFactorScaleDescription(data, props.isProb));
      } else if (existing === undefined) {
        data.Description = i.value;
        calls.push(Helpers.createFactorScaleDescription(data, props.isProb));
      }
    });
    if (calls.length > 0) {
      setLoading(true);
      Promise.all(calls).finally(refreshInformation);
    } else {
      setShowScaleModal(false);
    }
  }

  // HELPERS
  function handleSetScales(factor) {
    setSelectedFactor(factor);
    setShowScaleModal(true);
  }

  const getAddRemoveIds = () => {
    const checkboxes = document.querySelectorAll(`input[name="subcats"]`);
    const ids = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        if (!selectedFactor.subcategories.includes(parseInt(checkbox.value))) {
          // checked and not already assigned, ADD
          ids.push({
            add: true,
            id: parseInt(checkbox.value),
          });
        }
      } else {
        if (selectedFactor.subcategories.includes(parseInt(checkbox.value))) {
          // not checked but already assigned, REMOVE
          ids.push({
            add: false,
            id: parseInt(checkbox.value),
          });
        }
      }
    });
    return ids;
  };

  const getSubcatCalls = (addRemoveIds) => {
    const calls = [];
    addRemoveIds.forEach((subcat) => {
      const data = {};
      let hasCalcs = false;
      if (props.isProb) {
        data.ProbSubcategoryId = subcat.id;
        data.ProbabilityIds = subcat.add
          ? [selectedFactor.probabilityId]
          : selectedFactor.probabilityId;
        if (subcat.add) {
          calls.push(Helpers.assignSubcategoryToFactors(data, true));
        } else {
          if (props.currInterval?.calculations?.processCalculations?.length) {
            hasCalcs =
              props.currInterval.calculations.processCalculations.probabilityVals.some(
                (factor) =>
                  factor.probabilityId === selectedFactor.probabilityId
              );
          }
          calls.push(Helpers.removeFactorFromSubcategory(data, true, hasCalcs));
        }
      } else {
        data.ConsSubcategoryId = subcat.id;
        data.ConsequenceIds = subcat.add
          ? [selectedFactor.consequenceId]
          : selectedFactor.consequenceId;
        if (subcat.add) {
          calls.push(Helpers.assignSubcategoryToFactors(data, false));
        } else {
          if (props.currInterval?.calculations?.processCalculations?.length) {
            hasCalcs =
              props.currInterval.calculations.processCalculations.consequenceVals.some(
                (factor) =>
                  factor.consequenceId === selectedFactor.consequenceId
              );
          }
          calls.push(
            Helpers.removeFactorFromSubcategory(data, false, hasCalcs)
          );
        }
      }
    });
    return calls;
  };

  const inputSubcatEdit = (subcat) => {
    let tr = null;
    const tds = [];

    for (let i = 0; i < 3; i++) {
      tds.push(document.createElement("td"));
    }

    // new/edit input td
    tds[0].className = "subcatName noborder";
    const inputName = document.createElement("input");
    inputName.type = "text";
    inputName.placeholder = "Subcategory";
    inputName.focus();
    tds[0].appendChild(inputName);

    // accept change td
    tds[1].className = "nowrap";
    const check = document.createElement("i");
    check.className = "fa-solid fa-check fa-lg";
    tds[1].appendChild(check);

    // cancel change td
    tds[2].className = "nowrap";
    const x = document.createElement("i");
    x.className = "fa-solid fa-xmark fa-lg";
    tds[2].appendChild(x);

    // executes if called when editing an existing subcat
    if (subcat) {
      tr = document.getElementById(
        subcat.probSubcategoryId ?? subcat.consSubcategoryId
      );
      // empty row and store nodes for later
      const originalNodes = [];
      while (tr.firstChild) originalNodes.push(tr.removeChild(tr.firstChild));

      // set input id and show previous subcat name
      inputName.id = "editSubcatName";
      inputName.value =
        subcat.probSubcategoryName ?? subcat.consSubcategoryName;

      // listen for instance-appropriate keystrokes
      inputName.addEventListener("keyup", ({ key }) => {
        if (key === "Enter") {
          if (
            inputName.value !==
            (subcat.probSubcategoryName ?? subcat.consSubcategoryName)
          ) {
            editSubcat();
          } else {
            while (tr.firstChild) tr.removeChild(tr.firstChild);
            tr.append(...originalNodes);
            setWriteInp(false);
          }
        }
        if (key === "Escape") {
          while (tr.firstChild) tr.removeChild(tr.firstChild);
          tr.append(...originalNodes);
          setWriteInp(false);
        }
      });

      // accepting changes calls editSubcat API
      check.addEventListener("click", () => {
        if (
          inputName.value !==
          (subcat.probSubcategoryName ?? subcat.consSubcategoryName)
        ) {
          editSubcat();
        } else {
          while (tr.firstChild) tr.removeChild(tr.firstChild);
          tr.append(...originalNodes);
          setWriteInp(false);
        }
      });

      // cancelling reverts to previous state of list
      x.addEventListener("click", () => {
        // empty row and append originally removed nodes
        while (tr.firstChild) tr.removeChild(tr.firstChild);
        tr.append(...originalNodes);
        setWriteInp(false);
      });

      tr.append(...tds);
      return;
    }

    const tbody = document.getElementById("subcat-table-body");

    // set input id
    inputName.id = "createSubcatName";

    // listen for instance-appropriate keystrokes
    inputName.addEventListener("keyup", ({ key }) => {
      if (key === "Enter") createSubcat();
      if (key === "Escape") {
        tbody.removeChild(tbody.lastChild);
        setWriteInp(false);
      }
    });

    // accepting calls createSubcat API
    check.addEventListener("click", createSubcat);

    // cancelling reverts to previous state of list
    x.addEventListener("click", () => {
      tbody.removeChild(tbody.lastChild);
      setWriteInp(false);
    });

    tr = document.createElement("tr");
    tr.append(...tds);

    tbody.appendChild(tr);
  };

  function factorInInterval(factor) {
    if (props.currInterval.doesNotExist) {
      return false;
    }
    const key = props.isProb ? "includedProbabilities" : "includedConsequences";
    const id = props.isProb ? "probabilityId" : "consequenceId";
    return props.currInterval.details[key].some((f) => f[id] === factor[id]);
  }

  function factorsHaveCalcs() {
    if (props.currInterval.doesNotExist) {
      return false;
    }
    const key = props.isProb
      ? "medianProbabilityValue"
      : "medianConsequenceValue";
    return props.currInterval.calculations[key] > 0;
  }

  function getFactorWeight(factor) {
    const intervalInfo = getFactorIntervalInfo(factor);
    return intervalInfo?.weight ?? null;
  }

  function getFactorMultiplied(factor) {
    const intervalInfo = getFactorIntervalInfo(factor);
    return intervalInfo?.isMultiplied ?? null;
  }

  function getFactorIntervalInfo(factor) {
    let included = "included";
    let id = "";
    if (props.isProb) {
      included += "Probabilities";
      id = "probabilityId";
    } else {
      included += "Consequences";
      id = "consequenceId";
    }
    return props.currInterval.details[included]?.find(
      (f) => f[id] === factor[id]
    );
  }

  function generateScaleInputs() {
    const scaleSize = props.isProb
      ? props.project.numProbScoreValues
      : props.project.numConsScoreValues;
    const arr = [];
    for (let i = 0; i < scaleSize; i++) {
      const scaleItem = {
        value: i + 1,
        description: getFactorIntervalInfo(
          selectedFactor
        )?.scaleDescriptions?.find(
          (sd) => (sd.probScoreValue ?? sd.consScoreValue) === i + 1
        )?.description,
      };
      arr.push(scaleItem);
    }
    return (
      <>
        {arr.map((i) => (
          <FormInput
            key={i.value}
            className="scoring-scale"
            id={`scale-${i.value}`}
            label={i.value}
            defaultValue={i.description ?? ""}
          />
        ))}
      </>
    );
  }

  function allFactorsAdded() {
    if (!props.factors.length) {
      return true;
    } else {
      if (props.isProb) {
        return (
          props.currInterval.details.includedProbabilities.length ===
          props.factors.length
        );
      } else {
        return (
          props.currInterval.details.includedConsequences.length ===
          props.factors.length
        );
      }
    }
  }

  function setInfoDialog() {
    setDialogAction(null);
    setDialogTitle(
      `What are ${props.isProb ? "Probability" : "Business"} Factors?`
    );
    setDialogMessage(
      props.isProb
        ? "Probability Factors are the factors which influence success."
        : "Business Factors are the factors which measure success."
    );
    setShowModalDialog(true);
  }

  const inFactorText = ` The factor will be removed from Interval ${
    props.currInterval.intervalLabel
      ? `\"${props.currInterval.intervalLabel}\"`
      : props.currInterval.intervalNum
  }.`;

  const hasCalcsText =
    " All associated scores will be deleted and the interval will need to be recalculated.";

  function setDeleteFactorDialog(factor) {
    setDialogAction(() => () => deleteFactor(factor));
    setDialogTitle(
      `Delete ${props.isProb ? "Probability" : "Business"} Factor`
    );
    setDialogMessage(
      `Are you sure you want to delete ${factor.description}?${
        !factorInInterval(factor) ? "" : inFactorText
      }${!factorsHaveCalcs() ? "" : hasCalcsText}`
    );
    setShowModalDialog(true);
  }

  function setDeleteSubcatDialog(subcat) {
    setDialogAction(() => () => deleteSubcat(subcat));
    setDialogTitle("Delete Subcategory");
    setDialogMessage(
      `Are you sure you want to delete ${
        subcat.probSubcategoryName ?? subcat.consSubcategoryName
      }?`
    );
    setShowModalDialog(true);
  }

  function setRemoveFactorDialog(factorId, setShowAncestor) {
    const factor = props.factors.find((f) => {
      if (props.isProb) {
        return f.probabilityId === factorId;
      } else {
        return f.consequenceId === factorId;
      }
    });
    const hasCalcs = props.isProb
      ? IntervalHelpers.pfHasCalculations(props.currInterval)
      : IntervalHelpers.bfHasCalculations(props.currInterval);
    setDialogTitle(
      `Remove ${props.isProb ? "Probability" : "Business"} Factor from Interval`
    );
    setDialogMessage(
      `Are you sure you want to remove ${
        factor.description
      } from  the current Interval?${hasCalcs ? hasCalcsText : ""}`
    );
    setDialogAction(() => () => removeFactorFromInterval(factor));
    setDialogOnClose(() => () => setShowAncestor(true));
    setShowModalDialog(true);
  }

  return (
    <div className="tab-content">
      {loading ? <Loading /> : null}
      {/* Context Menu */}
      <TabContextMenu>
        <button onClick={() => setShowCreateFactorModal(true)}>
          Create New Factor
        </button>
        <button onClick={() => setShowSubcatMenuModal(true)}>
          Subcategories
        </button>
        {!dataLoaded || props.currInterval.doesNotExist ? null : (
          <IntervalItemTable
            title={`Interval ${
              props.isProb ? "Probability" : "Business"
            } Factors`}
            itemName={`${props.isProb ? "Probability" : "Business"} Factors`}
            items={intervalItems}
            addAllAction={allFactorsAdded() ? null : addAllFactorsToInterval}
            buttonText="Add All"
            removeAction={setRemoveFactorDialog}
          />
        )}
        <button className="keep-icon-square" onClick={setInfoDialog}>
          <i className="fa-solid fa-info fa-lg" />
        </button>
      </TabContextMenu>

      {/* Create new factor */}
      <ModalCreate
        show={showCreateFactorModal}
        setShow={setShowCreateFactorModal}
        title={`Create New ${props.isProb ? "Probability" : "Business"} Factor`}
        action={createFactor}
      >
        {/* NAME */}
        <div className="modal-field">
          <label htmlFor="createName">Name</label>
          <input
            type="text"
            id="createName"
            placeholder={`${props.isProb ? "Probability" : "Business"} Factor`}
            autoFocus
          />
        </div>
        {/* Only display below if there is a current interval */}
        {props.currInterval.doesNotExist ? null : (
          <>
            {/* Add to current interval? */}
            <div className="modal-field">
              <label htmlFor="addToInterval">Add to current interval?</label>
              <input
                type="checkbox"
                id="addToInterval"
                value={addingToInterval}
                onChange={(e) => setAddingToInterval(e.target.checked)}
              />
            </div>
            {!addingToInterval ? null : (
              <>
                <div className="modal-field">
                  <label htmlFor="setWeight">Weight</label>
                  <input
                    type="number"
                    id="setWeight"
                    placeholder="50"
                    min="1"
                  />
                </div>
                {props.isProb ? null : (
                  <div className="modal-field">
                    <label htmlFor="setMultiplied">Multiplier</label>
                    <input type="checkbox" id="setMultiplied" />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </ModalCreate>

      {/* View subcategories */}
      <ModalCustom
        show={showSubcatMenuModal}
        setShow={setShowSubcatMenuModal}
        title={`${
          props.isProb ? "Probability" : "Business"
        } Factor Subcategories`}
        onClose={() => setWriteInp(false)}
        renderItem={subcatChange}
        action={() => {
          setWriteInp(true);
          inputSubcatEdit();
          document.getElementById("createSubcatName").focus();
        }}
      >
        <div className="modal-fields-container">
          <table id="subcat-table">
            <tbody id="subcat-table-body">
              {props.subcats.doesNotExist || isEmpty(props.subcats) ? (
                writeInp ? null : (
                  <tr>
                    <td>No Subcategories Exist!</td>
                  </tr>
                )
              ) : (
                props.subcats.map((subcat) => (
                  <tr
                    key={subcat.probSubcategoryId ?? subcat.consSubcategoryId}
                    id={subcat.probSubcategoryId ?? subcat.consSubcategoryId}
                  >
                    <td className="subcatName">
                      {subcat.probSubcategoryName ?? subcat.consSubcategoryName}
                    </td>
                    <td className="nowrap">
                      {writeInp ? (
                        <i className="fa-solid fa-pen-to-square fa-lg hover-not-allowed" />
                      ) : (
                        <i
                          className="fa-solid fa-pen-to-square fa-lg"
                          onClick={() => {
                            setWriteInp(true);
                            setSelectedSubcat(subcat);
                            inputSubcatEdit(subcat);
                            document.getElementById("editSubcatName").focus();
                          }}
                        />
                      )}
                    </td>
                    <td className="nowrap">
                      {writeInp ? (
                        <i className="fa-solid fa-trash fa-lg hover-not-allowed" />
                      ) : (
                        <i
                          className="fa-solid fa-trash fa-lg"
                          onClick={() => {
                            setSelectedSubcat(subcat);
                            // setShowDeleteSubcatModal(true);
                            setDeleteSubcatDialog(subcat);
                          }}
                        />
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </ModalCustom>

      {/* General purpose dialog box, set contents through state */}
      <ModalDialog
        show={showModalDialog}
        setShow={setShowModalDialog}
        title={dialogTitle}
        message={dialogMessage}
        action={dialogAction}
        onClose={dialogOnClose}
      />

      {!dataLoaded || props.factors.doesNotExist ? null : (
        <>
          {/* Edit business factor */}
          <ModalCreate
            show={showEditFactorModal}
            setShow={setShowEditFactorModal}
            title={`Edit ${props.isProb ? "Probability" : "Business"} Factor`}
            action={editFactor}
            buttonText="Confirm"
          >
            {/* NAME */}
            <div className="modal-field">
              <label htmlFor="editName">Name</label>
              <input
                type="text"
                id="editName"
                placeholder={selectedFactor.description}
              />
            </div>
            {/* Only display below if there is a current interval */}
            {props.currInterval.doesNotExist ? null : !factorInInterval(
                selectedFactor
              ) ? (
              <>
                <div className="modal-field">
                  <label htmlFor="addToInterval">
                    Add to current interval?
                  </label>
                  <input
                    type="checkbox"
                    id="addToInterval"
                    value={addingToInterval}
                    onChange={(e) => setAddingToInterval(e.target.checked)}
                  />
                </div>
                {!addingToInterval ? null : (
                  <>
                    <div className="modal-field">
                      <label htmlFor="setWeight">Weight</label>
                      <input
                        type="number"
                        id="setWeight"
                        placeholder="50"
                        min="1"
                      />
                    </div>
                    {props.isProb ? null : (
                      <div className="modal-field">
                        <label htmlFor="setMultiplied">Multiplier</label>
                        <input type="checkbox" id="setMultiplied" />
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <div className="modal-field">
                  <label htmlFor="setWeight">Weight</label>
                  <input
                    type="number"
                    id="setWeight"
                    placeholder={getFactorWeight(selectedFactor)}
                    min="1"
                  />
                </div>
                {props.isProb ? null : (
                  <div className="modal-field">
                    <label htmlFor="setMultiplied">Multiplier</label>
                    <input
                      type="checkbox"
                      id="setMultiplied"
                      defaultChecked={getFactorMultiplied(selectedFactor)}
                    />
                  </div>
                )}
              </>
            )}
          </ModalCreate>

          {/* Assign subcategories to factor */}
          <ModalCreate
            show={showAssignSubcatModal}
            setShow={setshowAssignSubcatModal}
            title={`Assign Subcategories to ${selectedFactor.description}`}
            action={assignSubcats}
            buttonText="Save"
          >
            {props.subcats.doesNotExist || isEmpty(props.subcats)
              ? null
              : props.subcats.map((subcat, index) => (
                  <div className="modal-field" key={index}>
                    <input
                      type="checkbox"
                      name="subcats"
                      id={subcat.probSubcategoryId ?? subcat.consSubcategoryId}
                      value={
                        subcat.probSubcategoryId ?? subcat.consSubcategoryId
                      }
                      defaultChecked={
                        isEmpty(selectedFactor)
                          ? false
                          : selectedFactor.subcategories.includes(
                              subcat.probSubcategoryId ??
                                subcat.consSubcategoryId
                            )
                      }
                    />
                    <label
                      htmlFor={
                        subcat.probSubcategoryId ?? subcat.consSubcategoryId
                      }
                    >
                      {subcat.probSubcategoryName ?? subcat.consSubcategoryName}
                    </label>
                  </div>
                ))}
          </ModalCreate>

          {/* View and edit scoring scale descriptions */}
          {props.currInterval.doesNotExist ? null : (
            <ModalForm
              show={showScaleModal}
              setShow={setShowScaleModal}
              title={`${
                props.isProb ? "Probability" : "Business"
              } Factor Scoring Scale`}
              action={setScale}
              buttonText="Save"
            >
              <FormPage title={selectedFactor.description}>
                {generateScaleInputs()}
              </FormPage>
            </ModalForm>
          )}

          {/* Factors Table */}
          <div className="content-container">
            <table id="factor-table">
              <thead>
                <tr>
                  <th>{props.isProb ? "Probability" : "Business"} Factor</th>
                  <th>Weighted Value</th>
                  {props.isProb ? null : <th>Multiplier</th>}
                  <th>Subcategory</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {props.factors.map((factor) => (
                  <tr key={factor.probabilityId ?? factor.consequenceId}>
                    <td>{factor.description}</td>
                    <td>
                      {props.currInterval.doesNotExist
                        ? null
                        : getFactorWeight(factor)}
                    </td>
                    {props.isProb ? null : (
                      <td className="nowrap center-icon">
                        {props.currInterval
                          .doesNotExist ? null : getFactorMultiplied(factor) ? (
                          <i className="fa-solid fa-lg fa-star-of-life" />
                        ) : null}
                      </td>
                    )}
                    <td>
                      <div className="subcat-box-container">
                        {factor.subcategories.map((as) => (
                          <SubcatBox
                            key={as}
                            name={() => {
                              const subcat = props.subcats.find(
                                (s) =>
                                  (s.probSubcategoryId ??
                                    s.consSubcategoryId) === as
                              );
                              return (
                                subcat.probSubcategoryName ??
                                subcat.consSubcategoryName
                              );
                            }}
                          />
                        ))}
                      </div>
                    </td>
                    <td className="nowrap">
                      {/* Factor context menu */}
                      <Menu
                        className="data-class-menu"
                        menuButton={
                          <i className="fa-solid fa-ellipsis-vertical fa-lg" />
                        }
                        align="center"
                        position="anchor"
                        arrow={true}
                        direction="right"
                      >
                        {/* Edit Factor */}
                        <MenuItem
                          className="menu-item"
                          onClick={() => {
                            setSelectedFactor(factor);
                            setShowEditFactorModal(true);
                          }}
                        >
                          <i className="fa-solid fa-pen-to-square " /> Edit
                        </MenuItem>
                        {/* Assign Subcategories */}
                        {props.subcats.doesNotExist ||
                        isEmpty(props.subcats) ? null : (
                          <MenuItem
                            className="menu-item"
                            onClick={() => {
                              setSelectedFactor(factor);
                              setshowAssignSubcatModal(true);
                            }}
                          >
                            <i className="fa-solid fa-tags " />
                            Subcategories
                          </MenuItem>
                        )}
                        {/* Set Scale Descriptions */}
                        {props.currInterval
                          .doesNotExist ? null : !factorInInterval(
                            factor
                          ) ? null : (
                          <MenuItem
                            className="menu-item"
                            onClick={() => handleSetScales(factor)}
                          >
                            <i className="fa-solid fa-list-ul " />
                            Scoring Scale
                          </MenuItem>
                        )}
                        {/* Delete Factor */}
                        <MenuItem
                          className="menu-item menu-delete"
                          onClick={() => {
                            setSelectedFactor(factor);
                            // setShowDeleteFactorModal(true);
                            setDeleteFactorDialog(factor);
                          }}
                        >
                          <i className="fa-solid fa-trash" /> Delete
                        </MenuItem>
                      </Menu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default TabFactors;
