import { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

import ScoringReport from "./ScoringReport";
import { cloneDeep } from "lodash";

const DecisionMap = (props) => {
  const [selectedProcesses, setSelectedProcesses] = useState([]);
  const [point, setPoint] = useState(null);
  const [options, setOptions] = useState({
    chart: {
      height: computeChartHeight() ?? (9 / 16) * 100 + "%",
      // height: (9 / 16) * 100 + "%",
      credits: { enabled: false },
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      scatter: {
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              if (point) {
                point.update({
                  visible: true,
                });
                // prevPoint.options.color = undefined;
                // prevPoint.graphic.radius = 4;
              }
              this.visible = false;
              setPoint(cloneDeep(this));
            },
            // update: function () {
            //   if (point) setPoint(cloneDeep(point));
            // },
          },
        },
        stickyTracking: false,
        zIndex: 2,
      },
      line: {
        marker: {
          enabled: false,
        },
        enableMouseTracking: false,
        zIndex: 1,
      },
    },
    title: {
      text: `Decision Map for ${props.project.projectName}`,
    },
    subtitle: {
      text:
        props.currInterval.intervalLabel ??
        `Interval ${props.currInterval.intervalNum}`,
    },
    tooltip: {
      formatter: function () {
        return (
          "<b>Process Name: </b>" +
          this.point.name +
          "</br><b>Process Number: </b>" +
          this.point.custom.number +
          "</br><b>Probability Value: </b>" +
          this.y +
          "</br><b>Business Value: </b>" +
          this.x
        );
      },
    },
    yAxis: {
      title: {
        text: "Probability Value",
      },
      plotLines: [
        {
          value:
            props.currInterval.medianIntervalProbabilityValue ||
            props.project.medianProbabilityValue,
          width: 1,
          color: "#000",
          zIndex: 3,
        },
      ],
      min: 0,
      max:
        (props.currInterval.medianIntervalProbabilityValue ||
          props.project.medianProbabilityValue) * 2,
    },
    xAxis: {
      title: {
        text: "Business Value",
      },
      plotLines: [
        {
          value:
            props.currInterval.medianIntervalConsequenceValue ||
            props.project.medianConsequenceValue,
          width: 1,
          color: "#000",
          zIndex: 3,
        },
      ],
      min: 0,
      max:
        (props.currInterval.medianIntervalConsequenceValue ||
          props.project.medianConsequenceValue) * 2,
    },
    exporting: {
      menuItemDefinitions: {
        // Custom definition
        label: {
          onclick: function () {
            this.renderer
              .label("You just clicked a custom menu item", 100, 100)
              .attr({
                fill: "#a4edba",
                r: 5,
                padding: 10,
                zIndex: 10,
              })
              .css({
                fontSize: "1.5em",
              })
              .add();
          },
          text: "Show label",
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
            "label",
          ],
        },
      },
    },
  });

  useEffect(() => {
    updateSeries();
    if (point) {
      displayPointInfo(point);
    }
  }, [props.processes]);

  useEffect(() => {
    if (point) {
      displayPointInfo(point);
    } else {
      setSelectedProcesses([]);
    }
  }, [point]);

  function updateSeries() {
    const copy = cloneDeep(options);
    copy.subtitle.text =
      props.currInterval.intervalLabel ??
      `Interval ${props.currInterval.intervalNum}`;
    copy.series = generateSeries(getMajp(props.processes));
    setOptions(copy);
  }

  function getMajp(allProcesses) {
    const majp = [];
    allProcesses.forEach((p) => {
      if (!majp.some((m) => m.majorProcessId === p.majorProcessId)) {
        majp.push({
          majorProcessId: p.majorProcessId,
          majorProcessName: p.majorProcessName,
        });
      }
    });
    return majp;
  }

  function generateSeries(majorProcesses) {
    const allSeries = [];
    majorProcesses.forEach((majp) => {
      let minps = cloneDeep(props.processes).filter(
        (p) => p.majorProcessId === majp.majorProcessId
      );
      allSeries.push({
        type: "scatter",
        id: majp.majorProcessId,
        name: majp.majorProcessName,
        data: generatePoints(minps),
      });
    });
    return allSeries;
  }

  function generatePoints(processes) {
    const data = [];
    processes.forEach((minp) => {
      // BELOW IS ONLY FOR TESTING/DEMO PURPOSES
      // RANDOMIZES VALUES FOR INTERVAL 0 OF ECM PROJECT PRIORITIZATION
      if (
        props.project.projectId === 36 &&
        props.currInterval.intervalId === 3
      ) {
        data.push({
          name: minp.minorProcessName,
          x:
            minp.values.consequenceTotal +
            (Math.floor(Math.random() * 400) - 200),
          y:
            minp.values.probabilityTotal +
            (Math.floor(Math.random() * 400) - 200),
          id: minp.minorProcessId,
          custom: {
            number: minp.minorProcessNumber,
            valueShare: minp.values.valueShare,
            valueShareRank: minp.values.valueShareRank,
          },
        });
        return;
      }
      if (minp.values) {
        data.push({
          name: minp.minorProcessName,
          x: minp.values.consequenceTotal,
          y: minp.values.probabilityTotal,
          id: minp.minorProcessId,
          custom: {
            number: minp.minorProcessNumber,
            valueShare: minp.values.valueShare,
            valueShareRank: minp.values.valueShareRank,
          },
        });
      }
    });
    return data;
  }

  function displayPointInfo(point) {
    const process = props.processes.find((p) => p.minorProcessId === point.id);
    setSelectedProcesses([process]);
  }

  function computeChartHeight() {
    const maxContentHeight = window.innerHeight - 140; // header & footer
    const maxTabContentHeight = maxContentHeight - 90 - remToPixels(2) - 0.72; // project title & tabs
    const maxChartHeight = maxTabContentHeight - 67 - remToPixels(1) - 115 + 3; // tab context menu & ScoringReport
    return maxChartHeight;
  }

  function remToPixels(rem) {
    return (
      rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
    );
  }

  return (
    <>
      <HighchartsReact
        id="highcharts-container"
        highcharts={Highcharts}
        options={options}
        allowChartUpdate={true}
      />
      <ScoringReport
        processes={selectedProcesses}
        bfSubcats={props.bfSubcats}
        pfSubcats={props.pfSubcats}
        scoringItem={props.scoringItem}
      />
    </>
  );
};

export default DecisionMap;
