const AdminCompanies = (props) => {
  return (
    <div className="admin-companies">
      <h3>Programs List</h3>
      {/* <button onClick={props.createProgram}>Add new Program</button> */}
      {!props.allCompanies ? (
        <p>No Programs To Display</p>
      ) : (
        <ul>
          {props.allCompanies.map((p) => (
            <li
              key={p.companyId}
              onClick={() => props.selectCompany(p.companyId)}
            >
              <div>
                <p data-id={p.companyId}>{p.companyName}</p>
                <i className="fa-solid fa-pen-to-square fa-lg"></i>
                <i className="fa-solid fa-trash fa-lg"></i>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AdminCompanies;
