/*
    Optional props:
        id
        icon - (Custom icon to display)
        flag - (Boolean to display checkmark or xmark)
*/

const ChecklistIcon = (props) => {
  return (
    <button className="round" id={props.id ?? ""} disabled={!props.flag}>
      {props.icon ? (
        props.icon
      ) : props.flag ? (
        <i className="fa-solid fa-check fa-lg" />
      ) : (
        <i className="fa-solid fa-xmark fa-lg" />
      )}
    </button>
  );
};

export default ChecklistIcon;
