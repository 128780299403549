import { useState, useEffect } from "react";
import { cloneDeep } from "lodash";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import Loading from "./Loading";
import Modal from "./Modal/Modal";

const ScoreDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [showChartModal, setShowChartModal] = useState(false);
  const [participant, setParticipant] = useState("");
  const [scores, setScores] = useState([]);
  const [colourAsg, setColourAsg] = useState([
    "#fff",
    "#fff",
    "#fff",
    "#fff",
    "#fff",
    "#fff",
  ]);

  const colours = [
    { name: "Red", code: "#f0777f" },
    { name: "Green", code: "#a4d6a6" },
    { name: "Blue", code: "#a8cfde" },
    { name: "Orange", code: "#ffc266" },
    { name: "Yellow", code: "#f0f571" },
    { name: "Purple", code: "#d08cf3" },
    { name: "Grey", code: "#c1c1c1" },
  ];

  const colourScore = (option, index) => {
    const newColourAsg = cloneDeep(colourAsg);
    newColourAsg[index] = option.target.value;
    setColourAsg(newColourAsg);
  };

  // get scores for user (or avg of all), if no score present,
  // display 0
  useEffect(() => {
    if (participant) {
      const values = [];
      props.minorProcesses.forEach((minp) => {
        let bvScores = minp.consequenceScores.filter(
          (cs) => cs.userId.toString() === participant
        );
        if (bvScores.length < props.bValues.length) {
          bvScores = [];
          for (let i = bvScores.length; i < props.bValues.length; i++) {
            bvScores.push({ scoreValue: 0 });
          }
        }
        values.push({
          processId: minp.id,
          bvScores: bvScores,
        });
      });
      setScores(values);
    } else {
      // talk to Delica about getting avg scores for each bv/prob for each
      // process
    }
  }, [participant]);

  const options = {
    chart: {
      type: "spline",
      // set height for 16:9 ratio
      height: (9 / 16) * 100 + "%",
    },
    title: {
      text: "My chart",
    },
    series: [
      {
        data: [1, 2, 1, 4, 3, 6],
      },
    ],
  };

  return (
    <div className="report-score-details">
      {loading ? <Loading /> : null}
      <Modal show={showChartModal} setShowModal={setShowChartModal}>
        <div className="modal-content" style={{ width: "60vw" }}>
          <h3 id="modal-title">Scoring Chart</h3>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </Modal>
      <div className="score-details-filter">
        <div className="filter-participants">
          <p className="filter-title">Participant</p>
          <select id="part" onChange={(e) => setParticipant(e.target.value)}>
            <option value="">All</option>
            {props.participants.map((p) => {
              if (p.consequenceScoreSubmitted && p.probabilityScoreSubmitted)
                return (
                  <option value={p.userId}>
                    {p.firstName} {p.lastName}
                  </option>
                );
            })}
          </select>
        </div>
        <div className="highlight-scores">
          <p className="filter-title">Highlight Scores</p>
          <table>
            <thead>
              <tr>
                <th style={{ backgroundColor: colourAsg[0] }}>Not Scored</th>
                <th style={{ backgroundColor: colourAsg[1] }}>1</th>
                <th style={{ backgroundColor: colourAsg[2] }}>2</th>
                <th style={{ backgroundColor: colourAsg[3] }}>3</th>
                <th style={{ backgroundColor: colourAsg[4] }}>4</th>
                <th style={{ backgroundColor: colourAsg[5] }}>5</th>
              </tr>
            </thead>
            <tbody>
              <tr id="colour-select-row">
                {colourAsg.map((c, index) => (
                  <td>
                    <select onChange={(e) => colourScore(e, index)}>
                      <option value="#fff">None</option>
                      {colours.map((cl) => (
                        <option
                          key={cl.code}
                          value={cl.code}
                          style={{ backgroundColor: cl.code }}
                        >
                          {cl.name}
                        </option>
                      ))}
                    </select>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="score-details-bv">
        <p className="table-title">Business Value</p>
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              {props.bValues.map((bv) => (
                <th>{bv.description}</th>
              ))}
            </tr>
            <tr>
              <th></th>
              <th></th>
              {props.bValues.map((bv) => (
                <th>{bv.weight}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.minorProcesses.map((minp) => (
              <tr>
                <td
                  className="hover-cursor"
                  onClick={() => setShowChartModal(true)}
                >
                  {minp.number}
                </td>
                <td
                  className="hover-cursor"
                  onClick={() => setShowChartModal(true)}
                >
                  {minp.name}
                </td>
                {scores
                  .find((s) => s.processId === minp.id)
                  ?.bvScores?.map((score) => (
                    <td
                      style={{ backgroundColor: colourAsg[score.scoreValue] }}
                    >
                      {score.scoreValue}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="score-details-prob"></div>
    </div>
  );
};

export default ScoreDetails;
