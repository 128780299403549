import React, { useEffect, useState } from "react";

import TableIcon from "../Misc/TableIcon";
import ModalCustom from "../Modal/ModalCustom";

const IntervalItemTable = (props) => {
  const [show, setShow] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  function handleRemove(item) {
    setShow(false);
    props.removeAction(item.id, setShow);
  }

  function handleSubItemRemove(item) {
    setShow(false);
    props.removeSubItemAction(item.id, setShow);
  }

  return (
    <>
      <ModalCustom
        show={show}
        setShow={setShow}
        title={props.title}
        action={props.addAllAction ?? null}
        buttonText="Add All"
      >
        <table id="interval-item-table">
          <thead>
            <tr>
              <th></th>
              <th>{props.itemName}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {/* if no items, display empty row */}
            {items.length === 0 ? (
              <tr>
                <td colSpan={3}>No {props.itemName} added to Interval</td>
              </tr>
            ) : null}
            {items.map((item, index) => (
              <React.Fragment key={index}>
                <tr key={index} className={item.subItems ? "major-row" : ""}>
                  {item.ready === null ? (
                    <td></td>
                  ) : (
                    <TableIcon
                      cellClass={item.ready ? "green-icon" : "red-icon"}
                      iconClass={`fa-solid fa-lg ${
                        item.ready ? "fa-check" : "fa-xmark"
                      }`}
                    />
                  )}
                  <td>{item.name}</td>
                  <TableIcon
                    iconClass="fa-solid fa-lg fa-trash"
                    onClick={
                      !props.removeAction ? null : () => handleRemove(item)
                    }
                  />
                </tr>
                {item?.subItems?.map((sub, index) => (
                  <tr key={index}>
                    <TableIcon
                      cellClass={sub.ready ? "green-icon" : "red-icon"}
                      iconClass={`fa-solid fa-lg ${
                        sub.ready ? "fa-check" : "fa-xmark"
                      }`}
                    />
                    <td>{sub.name}</td>
                    <TableIcon
                      iconClass="fa-solid fa-lg fa-trash"
                      onClick={() => handleSubItemRemove(sub)}
                    />
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </ModalCustom>
      <button onClick={() => setShow(true)}>Interval</button>
    </>
  );
};

export default IntervalItemTable;
