import { cloneDeep, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import ModalCustom from "../../Modal/ModalCustom";
import FilterModal from "./FilterModal";

const Filters = (props) => {
  const [showFilterModal, setShowFilterModal] = useState(false);

  const [currFilter, setCurrFilter] = useState(props.processes);

  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState([
    {
      type: "checkbox",
      name: props.scoringItem.plural,
      id: "majorProcessId",
      data: [],
      checked: [],
    },
    {
      type: "minmax",
      name: "Value Share",
      id: "valueShare",
      min: false,
      max: false,
      minVal: 0,
      maxVal: 0,
    },
    {
      type: "minmax",
      name: "VOI",
      id: "voi",
      min: false,
      max: false,
      minVal: 0,
      maxVal: 0,
    },
    {
      type: "minmax",
      name: "Probability Score",
      id: "probabilityTotal",
      min: false,
      max: false,
      minVal: 0,
      maxVal: 0,
    },
    {
      type: "minmax",
      name: "Business Score",
      id: "consequenceTotal",
      min: false,
      max: false,
      minVal: 0,
      maxVal: 0,
    },
  ]);

  useEffect(() => {
    updateFilterData();
  }, []);

  useEffect(() => {
    if (search === "") {
      props.setFiltered(currFilter);
    } else {
      props.setFiltered(currFilter.filter((process) => matchSearch(process)));
    }
  }, [search]);

  useEffect(() => {
    updateFilterData();
  }, [props.processes]);

  function saveFilters() {
    if (filtersExist()) {
      let processesCopy = cloneDeep(props.processes);
      processesCopy = applyCheckboxFilters(processesCopy);
      processesCopy = applyMinMaxFilters(processesCopy);
      setCurrFilter(processesCopy);
      props.setFiltered(processesCopy);
      setShowFilterModal(false);
    }
  }

  function resetFilters() {
    const filtersCopy = cloneDeep(filters);
    filtersCopy[0].checked = [];
    for (let i = 0; i < filtersCopy.length; i++) {
      if (filtersCopy[i].type !== "minmax") continue;
      filtersCopy[i].min = false;
      filtersCopy[i].max = false;
    }
    setFilters(filtersCopy);
    setCurrFilter(props.processes);
    props.setFiltered(props.processes);
    setShowFilterModal(false);
  }

  function filtersExist() {
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].type === "checkbox" && filters[i].checked.length > 0) {
        return true;
      }
      if (filters[i].type === "minmax" && (filters[i].min || filters[i].max)) {
        return true;
      }
    }
    return false;
  }

  function applyCheckboxFilters(processes) {
    filters.forEach((filter) => {
      if (filter.type === "checkbox" && filter.checked.length > 0) {
        processes = processes.filter((p) =>
          filter.checked.includes(p[filter.id])
        );
      }
    });
    return processes;
  }

  function applyMinMaxFilters(processes) {
    filters.forEach((filter) => {
      if (filter.type === "minmax") {
        if (filter.min) {
          processes = processes.filter((p) => {
            let value = p.values[filter.id];
            if (filter.id === "valueShare") {
              value = Math.floor(value * 100);
            }
            return value >= filter.min;
          });
        }
        if (filter.max) {
          processes = processes.filter((p) => {
            let value = p.values[filter.id];
            if (filter.id === "valueShare") {
              value = Math.ceil(value * 100);
            }
            return value <= filter.max;
          });
        }
      }
    });
    return processes;
  }

  function matchSearch(process) {
    return (
      process.minorProcessName
        .toLowerCase()
        .includes(search.trim().toLowerCase()) ||
      process.majorProcessName
        .toLowerCase()
        .includes(search.trim().toLowerCase()) ||
      process.minorProcessNumber.includes(search.trim())
    );
  }

  function updateFilterData() {
    let filtersCopy = cloneDeep(filters);

    filtersCopy = setMajpData(filtersCopy);
    filtersCopy = setMinMaxVals(filtersCopy);

    setFilters(filtersCopy);
  }

  function setMajpData(filters) {
    props.processes.forEach((p) => {
      // don't push if majp already added
      if (!filters[0].data.some((majp) => majp.id === p.majorProcessId)) {
        filters[0].data.push({
          name: p.majorProcessName,
          id: p.majorProcessId,
        });
      }
    });
    return filters;
  }

  function setMinMaxVals(filters) {
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].type === "minmax") {
        filters[i].minVal = getValueMinMax(filters[i].id, true);
        filters[i].maxVal = getValueMinMax(filters[i].id, false);
        // handle special case
        if (filters[i].id === "valueShare") {
          filters[i].minVal = Math.floor(filters[i].minVal * 100);
          filters[i].maxVal = Math.ceil(filters[i].maxVal * 100);
        }
      }
    }
    return filters;
  }

  function getValueMinMax(attr, isMin) {
    let minmax = 0;
    props.processes.forEach((p) => {
      if (isMin) {
        if (p.values?.[attr] < minmax) {
          minmax = p.values[attr];
        }
      } else {
        if (p.values?.[attr] > minmax) {
          minmax = p.values[attr];
        }
      }
    });
    return minmax;
  }

  return (
    <div className="filter-container">
      <button onClick={() => setShowFilterModal(true)}>
        Filters{" "}
        {props.filteredNum < props.processes.length
          ? `(${props.filteredNum})`
          : null}
      </button>
      <input
        type="text"
        value={search}
        placeholder="Search"
        onChange={(e) => setSearch(e.target.value)}
      />

      <ModalCustom
        show={showFilterModal}
        setShow={setShowFilterModal}
        title="Filters"
        action={saveFilters}
        action2={resetFilters}
        buttonText="Save"
        buttonText2="Reset"
      >
        <FilterModal
          scoringItem={props.scoringItem}
          filters={filters}
          setFilters={setFilters}
        />
      </ModalCustom>
    </div>
  );
};

export default Filters;
