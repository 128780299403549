/*
    Required props:
        show
        setShow
        title
        message
    Optional props:
        action
*/

import Modal from "./Modal";

const ModalDialog = (props) => {
  return (
    <Modal
      show={props.show}
      setShowModal={props.setShow}
      onClose={props.onClose}
    >
      <div className="modal-content modal-dialog">
        <h3 id="modal-title">{props.title}</h3>
        {props.children ? props.children : <p>{props.message}</p>}
        {!props.action ? null : (
          <div className="modal-buttons">
            <button
              onClick={() => {
                props.action();
                props.setShow(false);
                if (props.onClose) props.onClose();
              }}
            >
              Confirm
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalDialog;
