import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";

/* -- Data-Structure Overview --
  Sort Order:
    {
      index: int, // INDEX OF COLUMN IN ALLCOLUMNS
      sort: +1 || -1 // ASCENDING OR DESCENDING SORT ORDER
    }

  Column:
    {
      name: String, // HEADER NAME
      type: String, // DATA TYPE
      attr: String || [String], // PROPERTY KEY, IN ARRAY IF NESTED
      nowrap: boolean // FOR TABLE COLUMN CSS
    }
*/

const ScoringReport = (props) => {
  // const SortDirection = { Asc: 1, Desc: -1 };
  const [sortOrder, setSortOrder] = useState({
    index: 0,
    sort: 1,
  });
  const [ordered, setOrdered] = useState(props.processes);

  const [allColumns, setAllColumns] = useState([]);
  const [processColumns] = useState(createCols());
  const [subcatColumns, setSubcatColumns] = useState(createSubcatCols());

  // Maintain sort order with new processes (filtering or different interval)
  // or when user changes sort order. Wait until allColumns is populated
  useEffect(() => {
    if (allColumns.length) applyOrdering();
  }, [props.processes, sortOrder, allColumns]);

  useEffect(() => {
    if (
      (props.pfSubcats.doesNotExist || props.pfSubcats.length > 0) &&
      (props.bfSubcats.doesNotExist || props.bfSubcats.length > 0)
    ) {
      setSubcatColumns(createSubcatCols());
    }
  }, [props.pfSubcats, props.bfSubcats]);

  useEffect(() => {
    if (processColumns.length && subcatColumns.length) {
      setAllColumns(processColumns.concat(subcatColumns));
    }
  }, [processColumns, subcatColumns]);

  function createCols() {
    return [
      {
        name: "VS Rank",
        type: "number",
        attr: ["values", "valueShareRanking"],
        nowrap: true,
      },
      {
        name: props.scoringItem.minorSingular,
        type: "string",
        attr: "minorProcessName",
      },
      {
        name: "ID #",
        type: "number",
        attr: "minorProcessId",
        nowrap: true,
      },
      {
        name: props.scoringItem.singular,
        type: "string",
        attr: "majorProcessName",
      },
      {
        name: "VS",
        type: "float",
        subtype: "percent",
        attr: ["values", "valueShare"],
        ref: "VS Rank",
        nowrap: true,
      },
      {
        name: "VOI",
        type: "number",
        attr: ["values", "voi"],
        nowrap: true,
        desc: true,
      },
    ];
  }

  function createSubcatCols() {
    const cols = [];
    // prob subcat columns
    cols.push({
      name: "Score",
      type: "number",
      attr: ["values", "probabilityTotal"],
      nowrap: true,
      desc: true,
    });
    if (props.pfSubcats.length > 0) {
      const pfSubcatCols = [];
      props.pfSubcats.forEach((subcat) => {
        pfSubcatCols.push({
          name: subcat.probSubcategoryName,
          type: "number",
          attr: "",
          nowrap: true,
          desc: true,
        });
      });
      cols.push(...pfSubcatCols);
    }

    // cons subcat columns
    cols.push({
      name: "Score",
      type: "number",
      attr: ["values", "consequenceTotal"],
      nowrap: true,
      desc: true,
    });
    if (props.bfSubcats.length > 0) {
      const bfSubcatCols = [];
      props.bfSubcats.forEach((subcat) => {
        bfSubcatCols.push({
          name: subcat.consSubcategoryName,
          type: "number",
          attr: "",
          nowrap: true,
          desc: true,
        });
      });

      cols.push(...bfSubcatCols);
    }

    return cols;
  }

  function handleSort(index) {
    // if column sorting has ref to another column
    if (allColumns[index].ref) {
      index = allColumns.findIndex((c) => c.name === allColumns[index].ref);
    }
    if (sortOrder.index === index) {
      setSortOrder({ index: index, sort: sortOrder.sort * -1 });
    } else {
      setSortOrder({ index: index, sort: allColumns[index].desc ? -1 : 1 });
    }
  }

  function applyOrdering() {
    let copy = cloneDeep(props.processes);
    const col = allColumns[sortOrder.index];
    if (col.name === "ID #") {
      // special case for process number
      copy = processNumCompare(copy, col);
    } else if (col.type === "string") {
      copy = stringCompare(copy, col);
    } else if (col.type === "number" || col.type === "float") {
      copy = numberCompare(copy, col);
    } else {
      console.error("Column datatype undefined, cannot sort.");
    }
    setOrdered(copy);
  }

  function stringCompare(processes, col) {
    return processes.sort((a, b) => {
      const aVal = getValue(a, col);
      const bVal = getValue(b, col);
      // match string if first char is NOT alphanumeric
      let compare = sortOrder.sort * aVal.localeCompare(bVal);
      const regex = new RegExp(/^[^a-zA-Z0-9]/);
      return regex.test(aVal) || regex.test(bVal) ? compare * -1 : compare;
    });
  }

  function numberCompare(processes, col) {
    return processes.sort((a, b) => {
      a = getValue(a, col);
      b = getValue(b, col);
      // null value will be last if descending, vice versa
      if (a === null) return sortOrder.sort * 1;
      if (b === null) return sortOrder.sort * -1;
      return sortOrder.sort * (a - b);
    });
  }

  // sort by minorProcessNumber
  function processNumCompare(p, col) {
    p.sort(
      (a, b) =>
        sortOrder.sort *
        (a.majorProcessId - b.majorProcessId ||
          a.minorProcessId - b.minorProcessId)
    );
    return p;
  }

  function getValueCell(process, column, index) {
    let val =
      column.attr === "minorProcessId"
        ? process.minorProcessNumber
        : getValue(process, column) ?? <span>&mdash;</span>;

    if (column.type === "string") {
      return (
        <td key={index} className="ellipsis align-left">
          <abbr title={val}>
            <span>{val}</span>
          </abbr>
        </td>
      );
    } else {
      return <td key={index}>{val}</td>;
    }
  }

  function getValue(process, column) {
    let value = !Array.isArray(column.attr)
      ? process[column.attr]
      : getNestedVal(process, cloneDeep(column.attr));

    if (column.type === "float") {
      value *= column.subtype === "percent" ? 100 : 1;
      return parseFloat(value.toFixed(2));
    } else {
      return value;
    }
  }

  function getNestedVal(obj, props) {
    const val = obj[props[0]];
    // typeof null --> "object"
    if (val === null || typeof val !== "object") {
      return val;
    } else {
      props.shift();
      return getNestedVal(val, props);
    }
  }

  return (
    <table id="score-table">
      <thead>
        {/* Top level table headers for prob and bus values */}
        <tr>
          {processColumns.map((col, index) => (
            <th key={index} className="nohover noborder"></th>
          ))}
          <th
            className="nowrap subcat-headers"
            colSpan={(props.pfSubcats.length ?? 0) + 1}
          >
            Probability Values
          </th>
          <th
            className="nowrap subcat-headers"
            colSpan={(props.bfSubcats.length ?? 0) + 1}
          >
            Business Values
          </th>
        </tr>
        <tr className="process-headers">
          {allColumns.map((col, index) => (
            <th
              key={index}
              className={col.nowrap ? "nowrap" : ""}
              onClick={() => handleSort(index)}
            >
              {col.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* display single empty row if no processes to display */}
        {ordered.length === 0 ? (
          <tr>
            {allColumns.map((col, index) => (
              <td key={index}>
                <span>&mdash;</span>
              </td>
            ))}
          </tr>
        ) : (
          ordered.map((pr, index) => (
            <tr key={index}>
              {allColumns.map((col, index) => getValueCell(pr, col, index))}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export default ScoringReport;
