const Modal = (props) => {
  // check if click coords are outside of modal's DOMRect bounds
  const clickOutOfBounds = (mouseX, mouseY, rect) => {
    const top = mouseY < rect.top;
    const bottom = mouseY > rect.bottom;
    const right = mouseX > rect.right;
    const left = mouseX < rect.left;
    return top || bottom || right || left;
  };

  // only return elements to display if props.show = true
  if (!props.show) return null;

  // modal is made up of an outside div the length/width of the viewport
  // which is blurred to obscure out-of-focus elements, and an inner
  // div which will display whatever is placed inside of the <Modal> tags
  return (
    <div
      className="modal-background"
      onClick={(e) => {
        e.stopPropagation();
        const rect = document
          .getElementsByClassName("modal")[0]
          .getBoundingClientRect();
        if (clickOutOfBounds(e.clientX, e.clientY, rect)) {
          if (props.onClose) props.onClose();
          props.setShowModal(false);
        }
      }}
    >
      <div className="modal">
        <button
          id="modal-close-button"
          onClick={() => {
            if (props.onClose) props.onClose();
            props.setShowModal(false);
          }}
        >
          Close
        </button>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
