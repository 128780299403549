import React, { useEffect, useState } from "react";

import { cloneDeep } from "lodash";
import { Tooltip } from "react-tooltip";

const ScoringTable = (props) => {
  /* STATES */
  const [canSubmit, setCanSubmit] = useState(false);
  const [canSave, setCanSave] = useState(false);

  /* EFFECTS */
  useEffect(() => {
    if (props.savedScores && props.savedScores.length > 0) {
      const scores = [];
      props.savedScores.forEach((s) => {
        scores.push(generateScoreData(s.processId, s.factorId, s.scoreValue));
      });
      props.setScores(scores);
    }
  }, []);

  useEffect(() => {
    if (props.scores) {
      setCanSubmit(checkCanSubmit());
      setCanSave(checkCanSave());
    }
  }, [props.scores]);

  /* HANDLERS */
  function handleInput(e) {
    const processId = parseInt(e.target.id.split("-")[0]);
    const factorId = parseInt(e.target.id.split("-")[1]);
    const scoresCopy = cloneDeep(props.scores);
    const existingIndex = scoresCopy.findIndex(
      (s) =>
        s.ProcessId === processId &&
        (s.ProbabilityId === factorId || s.ConsequenceId === factorId)
    );
    const saveExists = getSavedScore(processId, factorId) !== "";

    if (!e.target.value && !saveExists) {
      e.target.classList.remove("changed");
    } else {
      e.target.classList.add("changed");
    }
    // if score exists
    if (existingIndex !== -1) {
      if (!e.target.value) {
        if (saveExists) {
          scoresCopy[existingIndex].ScoreValue = -1;
        } else {
          scoresCopy.splice(existingIndex, 1);
        }
      } else {
        scoresCopy[existingIndex].ScoreValue = parseInt(e.target.value) || null;
      }
    } else {
      scoresCopy.push(generateScoreData(processId, factorId, e.target.value));
    }
    setCanSubmit(scoresCopy.length > 0);
    props.setScores(scoresCopy);
  }

  function handleBack() {
    if (canSubmit && canSave) {
      props.promptBack();
    } else {
      props.back();
    }
  }

  function handleSave() {
    props.save();
  }

  function handleSubmit() {
    props.submit();
  }

  /* HELPERS */
  function getSavedScore(processId, factorId) {
    return (
      props.savedScores.find(
        (s) => s.processId === processId && s.factorId === factorId
      )?.scoreValue ?? ""
    );
  }

  function generateScoreData(processId, factorId, scoreValue) {
    const score = {
      IntervalId: props.interval.intervalId,
      ProcessId: parseInt(processId),
      ScoreValue: parseInt(scoreValue),
    };

    if (props.scoringProb) {
      score.ProbabilityId = parseInt(factorId);
    } else {
      score.ConsequenceId = parseInt(factorId);
    }

    return score;
  }

  function checkCanSave() {
    // below conditions assume:
    //    - savedScores will be turned to scores when loading scoringTable
    //    - changing a saved score to blank will not remove it (value = -1)
    if (props.scores.length === 0) {
      return false;
    } else if (props.scores.length > props.savedScores.length) {
      return true;
    } else {
      for (let i = 0; i < props.scores.length; i++) {
        let s = props.scores[i];
        if (
          !props.savedScores.some(
            (sa) =>
              s.ProcessId === sa.processId &&
              s.ScoreValue === sa.scoreValue &&
              (s.ProbabilityId ?? s.ConsequenceId === sa.factorId)
          )
        ) {
          return true;
        }
      }
      // scores and savedScores are identical
      return false;
    }
  }

  function checkCanSubmit() {
    return props.scores.length > 0;
  }

  return (
    <div className="part-scoring">
      {/* Context Menu */}
      <div className="tab-context-menu">
        <button onClick={handleBack}>Back</button>
        <button onClick={handleSave} disabled={!canSave}>
          Save
        </button>
        <button onClick={handleSubmit} disabled={!canSubmit}>
          Submit
        </button>
      </div>

      {/* Scale Description Tooltips */}
      {props.factors.map((f, index) => (
        <Tooltip
          key={index}
          anchorSelect={`#factor-${f.id}`}
          place="top"
          events={["click"]}
        >
          <ol>
            {f.scale.map((sd, index) => (
              <li key={index} value={sd.value}>
                {sd.description}
              </li>
            ))}
          </ol>
        </Tooltip>
      ))}

      {/* Table */}
      <div className="scoring-table">
        <table id={props.interval.intervalId}>
          <thead>
            <tr>
              <th></th>
              {props.factors.map((f, index) => (
                <th key={index}>
                  <a id={`factor-${f.id}`}>{f.name}</a>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.processes.map((p, index) => (
              <React.Fragment key={index}>
                <tr>
                  <th
                    className="major-process-row"
                    colSpan={props.factors.length + 1}
                  >{`${p.number} - ${p.name}`}</th>
                </tr>
                {p.minorProcesses.map((mp, index) => (
                  <tr key={index}>
                    <td>{mp.name}</td>
                    {props.factors.map((f, index) => (
                      <td key={index} className="input-cells">
                        {props.submittedScores.find(
                          (s) => s.factorId === f.id && s.processId === mp.id
                        )?.scoreValue ?? (
                          <select
                            name="scale"
                            id={`${mp.id}-${f.id}`}
                            className={
                              getSavedScore(mp.id, f.id) !== "" ? "changed" : ""
                            }
                            defaultValue={getSavedScore(mp.id, f.id)}
                            onChange={(e) => handleInput(e)}
                          >
                            <option value={null}></option>
                            {f.scale.map((s, index) => (
                              <option key={index} value={s.value}>
                                {s.value}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScoringTable;
