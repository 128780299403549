import { useEffect, useState } from "react";

import Modal from "./Modal";

const ModalCalc = (props) => {
  // null = select type
  // 0 = manual
  // 1 = automatic
  const [entryType, setEntryType] = useState(null);

  const [voi, setVoi] = useState({});
  const [roi, setRoi] = useState({});

  const [fieldsFilled, setFieldsFilled] = useState(false);
  const [calcMade, setCalcMade] = useState(false);

  useEffect(() => {
    resetCalcs();
  }, [props.show]);

  // testing
  // useEffect(() => {
  //   console.log(voi);
  //   console.log(roi);
  // }, [voi, roi]);

  function calcVOI() {
    setCalcMade(true);
    const db = parseFloat(document.getElementById("db").value);
    const vl = parseFloat(document.getElementById("vl").value);
    const va = parseFloat(document.getElementById("va").value);
    const vr = parseFloat(document.getElementById("vr").value);
    const vi = parseFloat(document.getElementById("vi").value);
    const cc = parseFloat(document.getElementById("cc").value);
    const oc = parseFloat(document.getElementById("oc").value);
    const tc = oc + cc;

    setVoi({
      val: db + vl + va + vr + vi - tc,
      percent: ((db + vl + va + vr + vi - tc) / tc) * 100,
    });

    setRoi({
      val: db - tc,
      percent: ((db - tc) / tc) * 100,
    });
  }

  function formatCurrency(value) {
    if (value < 1000) {
      return `$${value.toFixed(2)}`;
    } else if (value < 1000000) {
      return `$${(value / 1000).toFixed(1)}K`;
    } else if (value < 1000000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    } else if (value < 1000000000000) {
      return `$${(value / 1000000000).toFixed(1)}B`;
    } else {
      return `$${(value / 1000000000000).toFixed(1)}T`;
    }
  }

  function handleInput() {
    setFieldsFilled(
      document.getElementById("db").value !== "" &&
        document.getElementById("vl").value !== "" &&
        document.getElementById("va").value !== "" &&
        document.getElementById("vr").value !== "" &&
        document.getElementById("vi").value !== "" &&
        document.getElementById("cc").value !== "" &&
        document.getElementById("oc").value !== ""
    );
  }

  function resetCalcs() {
    setVoi({
      val: 0,
      percent: 0.0,
    });
    setRoi({
      val: 0,
      percent: 0.0,
    });
  }

  function resetForm() {
    document.getElementById("db").value = "";
    document.getElementById("vl").value = "";
    document.getElementById("va").value = "";
    document.getElementById("vr").value = "";
    document.getElementById("vi").value = "";
    document.getElementById("cc").value = "";
    document.getElementById("oc").value = "";
    resetCalcs();
    setCalcMade(false);
  }

  return (
    <Modal show={props.show} setShowModal={props.setShow}>
      <div className="modal-content modal-calc">
        <h3 id="modal-title">
          Calculate VOI for {props.process.minorProcessName}
        </h3>
        {/* SELECT CALCULATOR ENTRY TYPE */}
        {entryType == null ? (
          <div className="modal-calc-entry-select">
            <button onClick={() => setEntryType(0)}>Manual</button>
            <button onClick={() => setEntryType(1)} disabled>
              Automatic
            </button>
          </div>
        ) : // MANUAL ENTRY
        entryType === 0 ? (
          <>
            <div className="modal-fields-container">
              <div className="modal-calc-grouping">
                {/* DIRECT BENEFIT */}
                <div className="modal-calc-field">
                  <label htmlFor="db">Direct Benefit</label>
                  <input
                    type="number"
                    id="db"
                    placeholder="$"
                    step="any"
                    onChange={handleInput}
                    // onChange={(e) => formatCurrency(e)}
                  />
                </div>
              </div>

              {/* VALUES */}
              <div className="modal-calc-grouping">
                {/* VALUE LINKING */}
                <div className="modal-calc-field">
                  <label htmlFor="vl">Value Linking</label>
                  <input
                    type="number"
                    id="vl"
                    placeholder="$"
                    step="any"
                    onChange={handleInput}
                  />
                </div>

                {/* VALUE ACCELERATION */}
                <div className="modal-calc-field">
                  <label htmlFor="va">Value Acceleration</label>
                  <input
                    type="number"
                    id="va"
                    placeholder="$"
                    step="any"
                    onChange={handleInput}
                  />
                </div>

                {/* VALUE RESTRUCTURING */}
                <div className="modal-calc-field">
                  <label htmlFor="vr">Value Restructuring</label>
                  <input
                    type="number"
                    id="vr"
                    placeholder="$"
                    step="any"
                    onChange={handleInput}
                  />
                </div>

                {/* VALUE INNOVATION */}
                <div className="modal-calc-field">
                  <label htmlFor="vi">Value Innovation</label>
                  <input
                    type="number"
                    id="vi"
                    placeholder="$"
                    step="any"
                    onChange={handleInput}
                  />
                </div>
              </div>

              {/* COSTS */}
              <div className="modal-calc-grouping">
                {/* CAPITAL COST */}
                <div className="modal-calc-field">
                  <label htmlFor="cc">Capital Cost</label>
                  <input
                    type="number"
                    id="cc"
                    placeholder="$"
                    step="any"
                    onChange={handleInput}
                  />
                </div>

                {/* OPERATING COST */}
                <div className="modal-calc-field">
                  <label htmlFor="oc">Operating Cost</label>
                  <input
                    type="number"
                    id="oc"
                    placeholder="$"
                    step="any"
                    onChange={handleInput}
                  />
                </div>
              </div>

              {/* RESULTS */}
              <div className="modal-calc-grouping calc-results">
                <div className="modal-calc-field">
                  <label>VOI</label>
                  <p className="calc-result">
                    {formatCurrency(voi.val)} ({voi.percent.toFixed(2)}%)
                  </p>
                </div>

                <div className="modal-calc-field">
                  <label htmlFor="oc">ROI</label>
                  <p className="calc-result">
                    {formatCurrency(roi.val)} ({roi.percent.toFixed(2)}%)
                  </p>
                </div>
                <button
                  onClick={() => props.setVOI(props.process, voi.val)}
                  // disabled={!calcMade}
                  disabled
                >
                  Set VOI
                </button>
              </div>
            </div>

            <div className="modal-buttons">
              <button
                id="calc-button"
                onClick={calcVOI}
                disabled={!fieldsFilled}
              >
                Calculate
              </button>
              <button id="calc-button" onClick={resetForm}>
                Reset
              </button>
            </div>
          </>
        ) : (
          // AUTOMATIC ENTRY
          <div className="modal-buttons">
            <button id="calc-button" onClick={calcVOI}>
              Calculate
            </button>
            <button id="create-effect-button" onClick={null}>
              Create Effects &nbsp;
              <i className="fa-solid fa-plus" />
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalCalc;
