/*
    Required props:
        type
        id
        placeholder
        onButtonClick
    Optional props:
        buttonText
*/

import { useState, useEffect } from "react";

const EditableInput = (props) => {
  const [originalInput, setOriginalInput] = useState(props.text ?? "");
  const [userInput, setUserInput] = useState(props.text ?? "");
  const [displayButton, setDisplayButton] = useState(false);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
    // not using strict equality to handle number/string comparisons
    setDisplayButton(e.target.value != originalInput);
  };

  useEffect(() => {
    setOriginalInput(props.text ?? "");
  }, [props.text]);

  function handleSave() {
    props.onButtonClick(userInput);
    setOriginalInput(userInput);
    setDisplayButton(false);
  }

  return (
    <div className="editable-input-container">
      <input
        type={props.type}
        id={props.id}
        placeholder={props.placeholder}
        value={userInput}
        onChange={handleInputChange}
      />
      {!displayButton ? null : (
        <button onClick={handleSave}>{props.buttonText ?? "Save"}</button>
      )}
    </div>
  );
};

export default EditableInput;
