// import { useState } from "react";

const TabImport = (props) => {
  return (
    <div className="admin-import-tab">
      <div className="admin-import-table">
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Filename:</strong>
              </td>
              <td>
                <input type="file" />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Header row number</strong>
              </td>
              <td>
                <input type="number"></input>
              </td>
            </tr>
            <tr>
              <td>
                <h2>Field Mapping</h2>
              </td>
              <td>
                <a href="#">Click here to see a sample mapping file</a>
              </td>
            </tr>
            <tr>
              <td>
                <h4>SeeScape Field Name</h4>
              </td>
              <td>
                <h4>Excel File Column Name</h4>
              </td>
            </tr>
            <tr>
              <td>Entry Type (Major or Minor)</td>
              <td>
                <input type="text" />
              </td>
            </tr>
            <tr>
              <td>Process/Project Name</td>
              <td>
                <input type="text" />
              </td>
            </tr>
            <tr>
              <td>Process/Project Number</td>
              <td>
                <input type="text" />
              </td>
            </tr>
            <tr>
              <td>Process/Project Description</td>
              <td>
                <input type="text" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="admin-import-instructions">
        <p>To import your files:</p>
        <ol>
          <li>Select the file you would like to import</li>
          <li>
            Map the selected required columns to columns in your file with the
            required data
          </li>
          <li>Click the "Import your File" button</li>
        </ol>
      </div>
    </div>
  );
};

export default TabImport;
