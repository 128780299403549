import { useEffect, useState } from "react";

import Modal from "./Modal";

const ModalForm = (props) => {
  const [page, setPage] = useState(0);
  const [formPages, setFormPages] = useState([]);

  useEffect(() => {
    if (Array.isArray(props.children)) {
      setFormPages(props.children.filter((c) => c !== null));
    } else {
      setFormPages([props.children]);
    }
  }, [props.children]);

  function nextPage() {
    setPage(page + 1);
  }

  function prevPage() {
    setPage(page - 1);
  }

  return (
    <Modal
      show={props.show}
      setShowModal={props.setShow}
      onClose={props.onClose}
      key={props.renderItem}
    >
      <div className="modal-content modal-form">
        <h2 id="modal-title">{props.title}</h2>
        {formPages[page]}
      </div>
      <div className="modal-buttons form-buttons">
        {page === 0 ? null : (
          <button id="form-prev" onClick={prevPage}>
            Back
            <i className="fa-solid fa-chevron-left fa-lg" />
          </button>
        )}
        {page === formPages.length - 1 ? (
          <button id="form-submit" onClick={props.action}>
            {props.buttonText ?? "Submit"}
          </button>
        ) : (
          <button id="form-next" onClick={nextPage}>
            Next
            <i className="fa-solid fa-chevron-right fa-lg" />
          </button>
        )}
      </div>
    </Modal>
  );
};

export default ModalForm;
