import axios from "axios";

import Helpers from "./Helpers";
import IntervalHelpers from "./IntervalHelpers";
import configData from "./config.json";

export default class ProjectHelpers {
  // API
  static openScoring(projectId) {
    const calls = [];
    calls.push(axios.patch(configData.PATCH.OPEN_PROJECT_SCORING + projectId));
    calls.push(
      axios.patch(configData.PATCH.SET_PROJECT_VISIBILITY, {
        ProjectId: projectId,
        IsVisibleToParticipants: true,
        IsVisibleToModerators: true,
      })
    );
    return Promise.all(calls);
  }

  static closeScoring(projectId) {
    return axios.patch(configData.PATCH.CLOSE_PROJECT_SCORING + projectId);
  }

  static addUser(userId, projectId, intervalId) {
    return Helpers.addProjectUser({
      UserId: userId,
      ProjectId: projectId,
    });
    // .then((response) => {
    //   if (response === 1 && intervalId !== undefined) {
    //     return Helpers.addUsersToInterval({
    //       IntervalId: intervalId,
    //       UserIds: [userId],
    //     });
    //   }
    // });
  }

  static editUser(user, projectId, intervalId) {
    const calls = [];
    // calls.push(Helpers.editProjectUser());
    if (intervalId) {
      calls.push(IntervalHelpers.addUsers(intervalId, [user.userId]));
    }
    return Promise.all(calls);
  }

  // HELPERS
  static getMinorProcesses(majorProcesses) {
    const minorProcesses = [];
    majorProcesses.forEach((minp) => {
      minorProcesses.push(minp);
    });
    return minorProcesses;
  }
}
