import SetupSection from "../Setup/Layout/SetupSection";
import ChecklistItem from "./Layout/ChecklistItem";

const ProjectChecklist = (props) => {
  return (
    <SetupSection
      title="Getting Started"
      setupFlag={props.setupFlag}
      className="checklist"
      sectionText={`Begin setting up your new ${props.scoringItem.type} by completing this checklist:`}
    >
      <ChecklistItem
        title="Create a Probability Factor"
        dataExists={props.projectChecks.probs()}
        action={() => props.setTabIndex(1)}
      />
      <ChecklistItem
        title="Create a Business Factor"
        dataExists={props.projectChecks.cons()}
        action={() => props.setTabIndex(2)}
      />
      <ChecklistItem
        title={`Create a ${props.scoringItem.singular} and ${props.scoringItem.minorSingular}`}
        dataExists={props.projectChecks.procs()}
        action={() => props.setTabIndex(3)}
      />
      <ChecklistItem
        title="Add a Participant"
        dataExists={props.projectChecks.parts()}
        action={() => props.setTabIndex(4)}
      />
    </SetupSection>
  );
};

export default ProjectChecklist;
