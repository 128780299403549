import React, { useEffect, useState } from "react";

import { cloneDeep } from "lodash";
import { Tooltip } from "react-tooltip";
import TabContextMenu from "../Input/TabContextMenu";
import ModalDialog from "../Modal/ModalDialog";
import ScoringTable from "./ScoringTable";

const Scoring = (props) => {
  /* STATES */
  const [canSubmit, setCanSubmit] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const [scores, setScores] = useState([]);
  const [savedScores, setSavedScores] = useState([]);

  const [showModalDialog, setShowModalDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogAction, setDialogAction] = useState(null);

  /* EFFECTS */
  useEffect(() => {
    console.log(props.processes);
    console.log(props.factors);
    console.log(`Admin Scoring: ${props.admin}`);
  }, []);

  useEffect(() => {
    if (scores) {
      setCanSubmit(checkCanSubmit());
      setCanSave(checkCanSave());
    }
  }, [scores]);

  /* API */
  function saveScores() {
    props.setLoading(true);
    // save scores
    props.stopScoring(true);
  }

  function submitScores() {
    props.setLoading(true);
    // submit scores
    props.stopScoring(true);
  }

  /* HELPERS */
  function generateScoreData(processId, factorId, scoreValue) {
    const score = {
      IntervalId: props.interval.intervalId,
      ProcessId: parseInt(processId),
      ScoreValue: parseInt(scoreValue),
    };

    if (props.scoringProb) {
      score.ProbabilityId = parseInt(factorId);
    } else {
      score.ConsequenceId = parseInt(factorId);
    }

    return score;
  }

  function checkCanSave() {
    if (scores.length === 0) {
      return false;
    } else if (scores.length > savedScores.length) {
      return true;
    } else {
      scores.forEach((score) => {
        if (
          !savedScores.some(
            (saved) =>
              saved.processId === score.processId &&
              saved.scoreValue === score.scoreValue &&
              (saved.probabilityId ?? saved.consequenceId) ===
                (score.probabilityId ?? score.consequenceId)
          )
        ) {
          return true;
        }
      });
      // scores and savedScores are identical
      return false;
    }
  }

  function checkCanSubmit() {
    return scores.length > 0;
  }

  function handleBack() {
    if (canSave && canSubmit) {
      setDiscardScoresDialog();
    } else {
      props.stopScoring();
    }
  }

  function handleSave() {
    saveScores();
  }

  function handleSubmit() {
    setSubmitScoresDialog();
  }

  function setDiscardScoresDialog() {
    setDialogTitle("Unsaved Changes");
    setDialogMessage("All unsaved changes will be discarded, continue?");
    setDialogAction(() => props.stopScoring);
    setShowModalDialog(true);
  }

  function setSubmitScoresDialog() {
    setDialogTitle("Submit");
    setDialogMessage("All scores will be submitted for calculation, continue?");
    setDialogAction(() => submitScores);
    setShowModalDialog(true);
  }

  return (
    <>
      <TabContextMenu>
        <button onClick={handleBack}>Back</button>
        <button onClick={handleSave} disabled={!canSave}>
          Save
        </button>
        <button onClick={handleSubmit} disabled={!canSubmit}>
          Submit
        </button>
      </TabContextMenu>
      <div className={props.containerClass ?? ""}>
        <ModalDialog
          show={showModalDialog}
          setShow={setShowModalDialog}
          title={dialogTitle}
          message={dialogMessage}
          action={dialogAction}
        />

        {/* Scale Description Tooltips */}
        {props.factors.map((f, index) => (
          <Tooltip
            key={index}
            anchorSelect={"factor-" + f.probabilityId ?? f.consequenceId}
            place="top"
            events={["click"]}
          >
            <ol>
              <li>Scale descriptions here</li>
              {f.scale.map((sd, index) => (
                <li key={index} value={sd.value}>
                  {sd.description}
                </li>
              ))}
            </ol>
          </Tooltip>
        ))}

        <ScoringTable
          processes={props.processes}
          factors={props.factors}
          scores={scores}
          setScores={setScores}
          savedScores={savedScores}
          generateScoreData={generateScoreData}
        />
      </div>
    </>
  );
};

export default Scoring;
