const TableIcon = (props) => {
  return (
    <td
      className={`table-icon ${props.cellClass ?? ""}`}
      onClick={props.onClick}
    >
      <i className={props.iconClass} />
    </td>
  );
};

export default TableIcon;
