/*
    Required props:
        show
        setShow
        title
        message
        action
*/

import Modal from "./Modal";

const ModalConfirm = (props) => {
  return (
    <Modal show={props.show} setShowModal={props.setShow}>
      <div className="modal-content modal-confirm">
        <h3 id="modal-title">{props.title}</h3>
        <p>{props.message}</p>
        <div className="modal-buttons">
          <button onClick={props.action} autoFocus>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirm;
