import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import logo from "../images/SeescapeLogo.White.png";

const Header = (props) => {
  const gotoLogin = () => {
    props.setAdmin(false);
  };

  return (
    <header id="top-bar">
      <img src={logo} alt="Seescape Logo" onClick={gotoLogin} />
      {props.loginStatus ? (
        <div>
          <Menu
            className="header-menu data-class-menu"
            menuButton={<p>Welcome, {props.user?.firstName}</p>}
            align="center"
            position="anchor"
            arrow={true}
            direction="bottom"
            transition
          >
            <MenuItem className="menu-item">
              <i className="fa-solid fa-gear fa-lg" />
              Settings
            </MenuItem>
            <MenuItem className="menu-item">
              <i className="fa-solid fa-file-arrow-down fa-lg" />
              User Guides
            </MenuItem>
            <MenuItem
              className="menu-item menu-delete"
              onClick={() => {
                props.logout();
              }}
            >
              <i className="fa-solid fa-right-from-bracket fa-lg" />
              Log Out
            </MenuItem>
          </Menu>
          <img
            src="https://via.placeholder.com/70.png?text=Client+Logo"
            alt="Client Logo"
          />
        </div>
      ) : (
        <></>
      )}
    </header>
  );
};

export default Header;
