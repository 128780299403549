/*
    Required props:
        show
        setShow
        title
        action
    Optional props:
        children (input or other elements to be displayed)
        buttonText (customize action button text)
        onClose
*/

import Modal from "./Modal";

const ModalCreate = (props) => {
  return (
    <Modal
      show={props.show}
      setShowModal={props.setShow}
      onClose={props.onClose}
    >
      <div className="modal-content">
        <h3 id="modal-title">{props.title}</h3>
        <div className="modal-fields-container">{props.children}</div>
        {!props.action ? null : (
          <div className="modal-buttons">
            <button id="create-majp-button" onClick={props.action}>
              {props?.buttonText || "Create"}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalCreate;
