import React, { useEffect, useState } from "react";

const Sidebar = (props) => {
  /* STATES */
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedProject, setSelectedProject] = useState({});

  /* EFFECTS */
  useEffect(() => {
    setSelectedCompany(
      props.companies.find(
        (c) => c.companyId === props.selectedProject.companyId
      )
    );
    setSelectedProject(props.selectedProject);
  }, [props.selectedProject]);

  /* HANDLERS */
  function handleCompanyClick(id) {
    setSelectedCompany(props.companies.find((c) => c.companyId === id));
    document
      .getElementsByClassName("fa-chevron-down")[0]
      .classList.toggle("closed");
    document.getElementById(id + "-projects").classList.toggle("closed");
  }

  function handleProjectClick(id) {
    if (selectedProject.projectId !== id) {
      if (props.canChangeProject) {
        selectProject(id);
      } else {
        props.changeProjectDialog(() => selectProject(id));
      }
    }
  }

  /* HELPERS */
  function selectProject(id) {
    props.selectProject(id);
  }

  function getColour(project) {
    if (project.interval.scoringOpen) {
      if (scoresSubmitted(project)) {
        return "green";
      } else {
        return "yellow";
      }
    }
    return "grey";
  }

  function scoresSubmitted(project) {
    return (
      project.interval.submitted.consequenceScores.length &&
      project.interval.submitted.probabilityScores.length
    );
  }

  return (
    <div className="sidebar">
      <ul id="sidebar-companies">
        {props.companies.map((c) => (
          <React.Fragment key={c.companyId}>
            <li
              className={`li-company ${
                selectedCompany.companyId === c.companyId ? "selected" : null
              }`}
              id={c.companyId}
              onClick={() => handleCompanyClick(c.companyId)}
            >
              {c.companyName}
              {props.projects.some((p) => p.companyId === c.companyId) ? (
                <i className="fa-solid fa-chevron-down" />
              ) : null}
            </li>
            {props.projects.some((sp) => sp.companyId === c.companyId) ? (
              <ul className="sidebar-projects" id={c.companyId + "-projects"}>
                {props.projects
                  .filter((fp) => fp.companyId === c.companyId)
                  .map((mp) => (
                    <li
                      key={mp.projectId}
                      className={`li-project ${
                        selectedProject.projectId === mp.projectId
                          ? "selected"
                          : ""
                      } ${props.participant ? getColour(mp) : ""}`}
                      id={mp.projectId}
                      onClick={() => handleProjectClick(mp.projectId)}
                    >
                      {mp.projectName}
                    </li>
                  ))}
              </ul>
            ) : null}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
