import { cloneDeep } from "lodash";
import React, { useState } from "react";

const FilterModal = (props) => {
  function hasFilters(cat) {
    return cat.min || cat.max;
  }

  function toggleCategory(e, id) {
    document.getElementById(id).classList.toggle("closed");
    e.target.firstChild.classList.toggle("closed");
  }

  function assertMinMax(e, cat, isMin) {
    // min is always < max and maxVal
    // max is always > min and maxVal
    const value = parseInt(e.target.value);
    if (isMin) {
      if (cat.max && value >= cat.max) {
        e.target.value = cat.max - 1;
      }
      if (value >= cat.maxVal) {
        e.target.value = cat.maxVal - 1;
      }
      if (value < cat.minVal) {
        e.target.value = cat.minVal;
      }
    } else {
      if (cat.min && value <= cat.min) {
        e.target.value = cat.min + 1;
      }
      if (value > cat.maxVal) {
        e.target.value = cat.maxVal;
      }
      if (value <= cat.minVal) {
        e.target.value = cat.minVal + 1;
      }
    }
  }

  function handleMajpCheck(e, filterIndex) {
    const filtersCopy = cloneDeep(props.filters);
    if (e.target.checked) {
      filtersCopy[filterIndex].checked.push(parseInt(e.target.value));
    } else {
      filtersCopy[filterIndex].checked.splice(
        filtersCopy[filterIndex].checked.indexOf(parseInt(e.target.value)),
        1
      );
    }
    props.setFilters(filtersCopy);
  }

  function handleSetMinMax(e, cat, minmax) {
    const filtersCopy = cloneDeep(props.filters);
    filtersCopy.find((filter) => filter.id === cat.id)[minmax] =
      parseInt(e.target.value) || false;
    props.setFilters(filtersCopy);
  }

  return (
    <div className="filter-modal">
      {/* Checkbox Categories */}
      {props.filters.map((filter, filterIndex) => {
        if (filter.type === "checkbox" && filter.data.length > 0) {
          return (
            <React.Fragment key={filterIndex}>
              <div
                className="filter-category"
                onClick={(e) => toggleCategory(e, filter.id)}
              >
                <i className="fa-solid fa-chevron-down" />
                {filter.name}
              </div>
              <div className="filter-category-items" id={filter.id}>
                {filter.data.map((item, index) => (
                  <div key={index}>
                    <input
                      type="checkbox"
                      id={item.id}
                      value={item.id}
                      checked={filter.checked.includes(item.id)}
                      onChange={(e) => handleMajpCheck(e, filterIndex)}
                    ></input>
                    <label htmlFor={item.id}>{item.name}</label>
                  </div>
                ))}
              </div>
            </React.Fragment>
          );
        }
      })}

      {/* Min-Max Categories */}
      {props.filters.map((filter, index) => {
        if (filter.type === "minmax" && filter.maxVal !== 0) {
          return (
            <React.Fragment key={index}>
              <div
                className="filter-category"
                onClick={(e) => toggleCategory(e, filter.id)}
              >
                <i className="fa-solid fa-chevron-up" />
                {filter.name}
              </div>
              <div
                className={`filter-category-items${
                  hasFilters(filter) ? "" : " closed"
                }`}
                id={filter.id}
              >
                <div className="filter-nums">
                  <input
                    type="number"
                    id={`min${filter.id}`}
                    min={filter.minVal}
                    max={(filter.max || filter.maxVal) - 1}
                    placeholder="0"
                    value={filter.min || ""}
                    onBlur={(e) => {
                      assertMinMax(e, filter, true);
                      handleSetMinMax(e, filter, "min");
                    }}
                    onChange={(e) => handleSetMinMax(e, filter, "min")}
                  ></input>
                  <label htmlFor={`min${filter.id}`}>Min. {filter.name}</label>
                </div>
                <div className="filter-nums">
                  <input
                    type="number"
                    id={`min${filter.id}`}
                    min={(filter.min || filter.minVal) + 1}
                    max={filter.maxVal}
                    placeholder={filter.maxVal.toString()}
                    value={filter.max || ""}
                    onBlur={(e) => {
                      assertMinMax(e, filter, false);
                      handleSetMinMax(e, filter, "max");
                    }}
                    onChange={(e) => handleSetMinMax(e, filter, "max")}
                  ></input>
                  <label htmlFor={`min${filter.id}`}>Max. {filter.name}</label>
                </div>
              </div>
            </React.Fragment>
          );
        }
      })}
    </div>
  );
};

export default FilterModal;
