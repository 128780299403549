import { useEffect, useState } from "react";
import { cloneDeep, isEmpty } from "lodash";

import Loading from "../Loading";
import Filters from "../Reporting/Filters/Filters";
import PrevNext from "../Input/PrevNext";
import ReportCard from "../Reporting/ReportCard";
import ScoreDetails from "../ScoreDetails";
import ScoringReport from "../Reporting/ScoringReport";
import TabContextMenu from "../Input/TabContextMenu";
import DecisionMap from "../Reporting/DecisionMap";

const TabReports = (props) => {
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [reportType, setReportType] = useState("");

  const [currInterval, setCurrInterval] = useState({});
  const [currProcesses, setCurrProcesses] = useState([]);
  const [filteredProcesses, setFilteredProcesses] = useState([]);

  const [prevActive, setPrevActive] = useState(false);
  const [nextActive, setNextActive] = useState(false);

  const [reports, setReports] = useState([
    {
      name: "Scoring Report",
      enabled: false,
      icon: "fa-solid fa-table fa-6x",
    },
    {
      name: "Decision Map",
      enabled: false,
    },
    {
      name: "Participant Scores",
      enabled: false,
      icon: "fa-solid fa-list fa-6x",
    },
  ]);

  // hide loading graphic when required information is received
  useEffect(() => {
    if (
      !isEmpty(props.intervals) &&
      !isEmpty(props.currInterval) &&
      !isEmpty(props.pf) &&
      !isEmpty(props.bf) &&
      !isEmpty(props.participants) &&
      !isEmpty(props.majorProcesses)
    ) {
      if (
        props.currInterval.doesNotExist ||
        (props.currInterval.calculations &&
          props.currInterval.details &&
          props.currInterval.scores &&
          props.currInterval.users &&
          !props.intervals.some((i) => !i.details))
      ) {
        setCurrInterval(props.currInterval);
        setDataLoaded(true);
      }
    }
  }, [
    props.intervals,
    props.currInterval,
    props.pf,
    props.bf,
    props.participants,
    props.majorProcesses,
  ]);

  useEffect(() => {
    if (!isEmpty(currInterval) && !currInterval.doesNotExist) {
      let procs = getCurrProcesses();
      setCurrProcesses(procs);
      setFilteredProcesses(procs);
      updateSwitcher();
    }
  }, [currInterval]);

  useEffect(() => {
    setLoading(!dataLoaded);
    if (dataLoaded && !currInterval.doesNotExist) {
      // create deepCopy of reports and determine which are active/inactive
      const reportsCopy = cloneDeep(reports);

      // Score Table is enabled if calculations have been made
      if (props.currInterval.calculations.processCalculations.length) {
        reportsCopy[0].enabled = true;
      }

      // Decision Map is enabled if calculations have been made
      if (props.currInterval.calculations.processCalculations.length) {
        reportsCopy[1].enabled = true;
      }

      // Score Details is enabled if scores have been submitted
      if (
        props.currInterval.scores.consequenceScores.length &&
        props.currInterval.scores.probabilityScores.length
      ) {
        reportsCopy[2].enabled = true;
      }

      setReports(reportsCopy);
    }
  }, [dataLoaded]);

  function getCurrProcesses() {
    let includedProcesses = cloneDeep(props.minorProcesses);
    includedProcesses.filter((p) =>
      currInterval.details.includedProcesses
        .find((majp) => majp.majorProcessId === p.majorProcessId)
        .minorProcesses.includes(p.minorProcessId)
    );
    for (let i = 0; i < includedProcesses.length; i++) {
      includedProcesses[i] = {
        ...includedProcesses[i],
        majorProcessName: (includedProcesses[i].majorProcessName =
          props.majorProcesses.find(
            (mp) => mp.majorProcessId === includedProcesses[i].majorProcessId
          ).majorProcessName),
      };
      includedProcesses[i].values =
        props.currInterval.calculations.processCalculations.find(
          (calc) => calc.processId === includedProcesses[i].minorProcessId
        ) ?? null;
    }
    return includedProcesses;
  }

  // assumes no indexOutOfBounds error as button should be disabled
  const nextInterval = () => {
    setCurrInterval(
      props.intervals[
        props.intervals.findIndex(
          (i) => i.intervalId === currInterval.intervalId
        ) + 1
      ]
    );
    updateSwitcher();
  };

  // assumes no indexOutOfBounds error as button should be disabled
  const prevInterval = () => {
    setCurrInterval(
      props.intervals[
        props.intervals.findIndex(
          (i) => i.intervalId === currInterval.intervalId
        ) - 1
      ]
    );
    updateSwitcher();
  };

  function updateSwitcher() {
    setPrevActive(currInterval.intervalId !== props.intervals[0].intervalId);
    setNextActive(
      currInterval.intervalId !==
        props.intervals[props.intervals.length - 1].intervalId
    );
  }

  const displayReport = (reportType) => {
    setReportType(reportType);
    document.getElementById("admin-reports-tab").className =
      "tab-content flex-column";
  };

  const selectReport = () => {
    document.getElementById("admin-reports-tab").className =
      "admin-reports-tab flex-row";
    setReportType("");
    // reset current interval to latest
    setCurrInterval(props.currInterval);
  };

  return (
    <div className="tab-content flex-row" id="admin-reports-tab">
      {loading ? <Loading /> : null}
      {reportType === "" ? null : (
        <TabContextMenu>
          <button onClick={selectReport}>Back</button>
          <PrevNext
            step={`Interval ${currInterval.intervalNum}`}
            prevInterval={prevInterval}
            nextInterval={nextInterval}
            prevActive={prevActive}
            nextActive={nextActive}
          />
          <Filters
            setFiltered={setFilteredProcesses}
            filteredNum={filteredProcesses.length}
            processes={currProcesses}
            majorProcesses={props.majorProcesses}
            scoringItem={props.scoringItem}
          />
        </TabContextMenu>
      )}
      {!dataLoaded ? null : (
        <div className="content-container">
          {
            {
              "Scoring Report": (
                <ScoringReport
                  processes={filteredProcesses}
                  bfSubcats={props.bfSubcats}
                  pfSubcats={props.pfSubcats}
                  scoringItem={props.scoringItem}
                />
              ),
              "Decision Map": (
                <DecisionMap
                  project={props.project}
                  currInterval={currInterval}
                  processes={filteredProcesses}
                  bfSubcats={props.bfSubcats}
                  pfSubcats={props.pfSubcats}
                  scoringItem={props.scoringItem}
                />
              ),
              "Participant Scores": (
                <ScoreDetails
                  minorProcesses={props.minorProcesses}
                  participants={props.participants}
                  bValues={props.bValues}
                  probs={props.probs}
                />
              ),
              "": reports.map((r, index) => (
                <ReportCard
                  name={r.name}
                  icon={r.icon}
                  enabled={r.enabled}
                  displayReport={displayReport}
                  key={index}
                />
              )),
            }[reportType]
          }
        </div>
      )}
    </div>
  );
};

export default TabReports;
