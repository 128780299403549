import React, { Component, createRef } from "react";

import lock from "../images/lock-icon-large.png";

import Helpers from "../api/Helpers";
import Loading from "./Loading";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      store: null,
      errorMessage: "",
      loading: false,
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.emailInputRef = createRef();
    this.passwordInputRef = createRef();
  }

  componentDidMount() {
    const seescapeLogin_localObj = JSON.parse(
      localStorage.getItem("seescapeLogin")
    );
    if (seescapeLogin_localObj != null) {
      this.setState({
        isLoggedIn: true,
        store: {
          email: seescapeLogin_localObj.id,
          token: seescapeLogin_localObj.token,
          expirationTime: seescapeLogin_localObj.expirationTime,
        },
      });
      this.props.admin ? this.props.loginAdmin() : this.props.loginPart();
    }
  }

  handleLogin = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const email = this.emailInputRef.current.value;
    const password = this.passwordInputRef.current.value;

    const data = {
      EmailAddress: email,
      Password: password,
    };

    Helpers.login(this.props.admin, data)
      .then(() => {
        this.props.admin ? this.props.loginAdmin() : this.props.loginPart();
      })
      .catch((error) => this.setState({ errorMessage: error.message }));
  };

  render() {
    const prompt = this.props.admin ? "Administrator Login" : "Login";
    return (
      <div className="login-div">
        {this.state.loading ? <Loading /> : null}
        <div id="icon-text">
          <img src={lock} alt="Lock Icon" />
          <div id="login-text">
            <h2>{prompt}</h2>
            <p>
              Please enter your user name and password.
              {/* If you've forgotten your
              password please{" "}
              <a href="http://" target="_blank" rel="noopener noreferrer">
                click here
              </a>
              . */}
            </p>
          </div>
        </div>
        <div>{this.state.errorMessage}</div>
        <form onSubmit={(event) => this.handleLogin(event)}>
          <label htmlFor="email">Please enter your email address</label>
          <br />
          <input
            type="text"
            id="email"
            autoComplete="email"
            ref={this.emailInputRef}
            autoFocus
          />
          <br />
          <label htmlFor="password">Password</label>
          <br />
          <input
            type="password"
            id="password"
            autoComplete="current-password"
            ref={this.passwordInputRef}
          />
          <br />
          <button>Log On</button>
        </form>
      </div>
    );
  }
}
