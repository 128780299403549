import axios from "axios";
import { cloneDeep, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import Helpers from "../../api/Helpers";

import Loading from "../Loading";
import ModalDialog from "../Modal/ModalDialog";
import Sidebar from "../Sidebar/Sidebar";
import ParticipantScorecard from "./ParticipantScorecard";
import ScoringTable from "./ScoringTable";

const ParticipantHome = (props) => {
  // page state
  const [loading, setLoading] = useState(true);
  const [scoring, setScoring] = useState(false);
  const [scoringProb, setScoringProb] = useState(true);

  // required data state
  const [dataLoaded, setDataLoaded] = useState(false);

  // loaded data
  const [companies, setCompanies] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});

  // generated data
  const [scores, setScores] = useState([]);
  const [factors, setFactors] = useState([]);
  const [processes, setProcesses] = useState([]);
  const [savedScores, setSavedScores] = useState(null);
  const [submittedScores, setSubmittedScores] = useState(null);

  // dialog box
  const [showModalDialog, setShowModalDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogAction, setDialogAction] = useState(null);

  /* HOOKS */

  // start fetching data
  useEffect(() => {
    getProjects();
  }, []);

  // finish loading when all data has been received
  useEffect(() => {
    if (!isEmpty(projects) && !isEmpty(companies) && allIntervalsLoaded()) {
      // only set the first time
      if (!dataLoaded) {
        selectProject(projects[0].projectId);
      }
      setDataLoaded(true);
      setLoading(false);
    }
  }, [projects, companies]);

  // ensure scoring stops when a new project is selected
  useEffect(() => {
    if (!isEmpty(selectedProject)) {
      stopScoring();
    }
  }, [selectedProject]);

  // enable scoring flag when all required data is loaded
  useEffect(() => {
    if (
      !isEmpty(factors) &&
      !isEmpty(processes) &&
      savedScores !== null &&
      submittedScores !== null
    ) {
      setScoring(true);
      setLoading(false);
    }
  }, [factors, processes, savedScores, submittedScores]);

  /* API */

  const getProjects = () => {
    Helpers.getProjectsParticipant()
      .then((response) => {
        if (response.data.length === 0) {
          setProjects({ doesNotExist: true });
        } else {
          response.data.forEach((p) => {
            Helpers.getProbabilityFactors(p.projectId).then(
              axios.spread((...responses) => {
                p.pf = responses[0].data;
                p.pfSubcats = responses[1].data;
              })
            );
            Helpers.getBusinessFactors(p.projectId).then(
              axios.spread((...responses) => {
                p.bf = responses[0].data;
                p.bfSubcats = responses[1].data;
              })
            );
            Helpers.getProcesses(p.projectId).then((response) => {
              p.processes = response.data;
            });
          });
        }
        return response.data;
      })
      .then((projects) => {
        getAllIntervals(projects);
        getCompanies(projects);
      });
  };

  const getCompanies = (projects) => {
    Helpers.getCompanies().then((response) => {
      if (response.data.length === 0) {
        setCompanies({ doesNotExist: true });
      } else {
        setCompanies(filterCompanies(response.data, projects));
      }
    });
  };

  const getAllIntervals = (allProjects) => {
    const intervalCalls = [];
    allProjects.forEach((p) => {
      intervalCalls.push(Helpers.getProjectIntervalsParticipant(p.projectId));
    });
    axios.all(intervalCalls).then(
      axios.spread((...responses) => {
        allProjects.forEach((p) => {
          p.interval = responses.find((i) => i.projectId === p.projectId);
        });
        setProjects(cloneDeep(allProjects));
      })
    );
  };

  const getProjectInterval = (projectId) => {
    setLoading(true);
    const projectsCopy = cloneDeep(projects);
    Helpers.getProjectIntervalsParticipant(projectId).then((response) => {
      projectsCopy.find((p) => p.projectId === projectId).interval = response;
      setProjects(projectsCopy);
    });
  };

  const saveScores = () => {
    setLoading(true);
    Helpers.saveScores(scores, scoringProb)
      .then(() => {
        getProjectInterval(selectedProject.projectId);
      })
      .catch((err) => {
        alert("Not all scores were successfully saved");
        console.error(
          `Not all scores were successfully saved - ${err.data.numScoresSaved}/${scores}`
        );
        err.data.errorMessages.forEach((m) => console.error(m));
      })
      .finally(() => {
        stopScoring();
      });
  };

  const submitScores = () => {
    setLoading(true);
    const filteredScores = scores.filter((s) => s.ScoreValue !== -1);
    Helpers.submitScores(filteredScores, scoringProb)
      .then(() => {
        getProjectInterval(selectedProject.projectId);
      })
      .catch((err) => {
        alert("Not all scores were successfully submitted");
        console.error(
          `Not all scores were successfully submitted - ${err.data.numScoresSubmitted}/${scores}`
        );
        err.data.errorMessages.forEach((m) => console.error(m));
      })
      .finally(() => {
        stopScoring();
      });
  };

  /* LOCAL HELPERS */
  function allIntervalsLoaded() {
    return !projects.some((p) => p.interval === undefined);
  }

  function filterCompanies(companies, projects) {
    return companies.filter((c) =>
      projects.some((p) => p.companyId === c.companyId)
    );
  }

  function selectProject(projectId) {
    setSelectedProject(projects.find((p) => p.projectId === projectId));
  }

  function generateTableInfo(isProb) {
    generateFactors(isProb);
    generateProcesses();
    generateSavedScores(isProb);
    generateSubmittedScores(isProb);
  }

  function generateFactors(isProb) {
    const factors = [];
    if (isProb) {
      selectedProject.interval.details.includedProbabilities.forEach(
        (inc, index) => {
          factors.push({
            id: inc.probabilityId,
            name: selectedProject.pf.find(
              (f) => f.probabilityId === inc.probabilityId
            ).description,
          });
          const scale = [];
          inc.scaleDescriptions.forEach((sd) => {
            scale.push({
              description: sd.description,
              value: sd.probScoreValue,
            });
          });

          factors[index].scale = orderedScale(scale);
        }
      );
    } else {
      selectedProject.interval.details.includedConsequences.forEach(
        (inc, index) => {
          factors.push({
            id: inc.consequenceId,
            name: selectedProject.bf.find(
              (f) => f.consequenceId === inc.consequenceId
            ).description,
            scale: inc.scaleDescriptions,
          });
          const scale = [];
          inc.scaleDescriptions.forEach((sd) => {
            scale.push({
              description: sd.description,
              value: sd.consScoreValue,
            });
          });
          factors[index].scale = orderedScale(scale);
        }
      );
    }
    // return factors;
    setFactors(factors);
  }

  function generateProcesses() {
    const processes = [];
    selectedProject.interval.details.includedProcesses.forEach((ip, index) => {
      const processObject = selectedProject.processes.find(
        (p) => p.majorProcessId === ip.majorProcessId
      );
      processes.push({
        id: ip.majorProcessId,
        name: processObject.majorProcessName,
        number: processObject.majorProcessNumber,
      });
      const minorProcesses = [];
      ip.minorProcesses.forEach((mp) => {
        const minorProcessObject = processObject.minorProcesses.find(
          (p) => p.minorProcessId === mp
        );
        minorProcesses.push({
          id: mp,
          name: minorProcessObject.minorProcessName,
          number: minorProcessObject.minorProcessNumber,
        });
      });
      processes[index].minorProcesses = minorProcesses;
    });
    // return processes;
    setProcesses(processes);
  }

  function generateSavedScores(isProb) {
    const saved = [];
    if (isProb) {
      selectedProject.interval.saved.savedProbabilityScores.forEach((sc) => {
        saved.push({
          factorId: sc.probabilityId,
          processId: sc.processId,
          scoreValue: sc.scoreValue,
        });
      });
    } else {
      selectedProject.interval.saved.savedConsequenceScores.forEach((sc) => {
        saved.push({
          factorId: sc.consequenceId,
          processId: sc.processId,
          scoreValue: sc.scoreValue,
        });
      });
    }
    console.log(saved);
    // return saved;
    setSavedScores(saved);
  }

  function generateSubmittedScores(isProb) {
    const submitted = [];
    if (isProb) {
      selectedProject.interval.submitted.probabilityScores.forEach((sc) => {
        submitted.push({
          factorId: sc.probabilityId,
          processId: sc.processId,
          scoreValue: sc.scoreValue,
        });
      });
    } else {
      selectedProject.interval.submitted.consequenceScores.forEach((sc) => {
        submitted.push({
          factorId: sc.consequenceId,
          processId: sc.processId,
          scoreValue: sc.scoreValue,
        });
      });
    }
    console.log(submitted);
    setSubmittedScores(submitted);
  }

  function stopScoring() {
    setScores([]);
    setScoring(false);
    setFactors([]);
    setProcesses([]);
    setSavedScores(null);
    setSubmittedScores(null);
  }

  function setLeaveWithoutSavingDialog(customAction) {
    setDialogTitle("Unsaved Changes");
    setDialogMessage("All unsaved changes will be discarded, continue?");
    setDialogAction(() => customAction ?? stopScoring);
    setShowModalDialog(true);
  }

  function setSaveScoresDialog() {
    setDialogTitle("Save Scores");
    setDialogMessage(
      "All changes will be saved to be completed later, continue?"
    );
    setDialogAction(() => saveScores);
    setShowModalDialog(true);
  }

  function setSubmitScoresDialog() {
    setDialogTitle("Submit Scores");
    setDialogMessage(
      "All changes will be submitted for calculation, continue?"
    );
    setDialogAction(() => submitScores);
    setShowModalDialog(true);
  }

  function orderedScale(scaleArray) {
    return scaleArray.sort((a, b) => a.value - b.value);
  }

  /* LOCAL TESTER VARIABLES */
  const cardInstructions = [
    "Please score the processes/projects you are familiar with - do not score processes/projects you are not familiar with.",
    "To score a process/project, please include the rating for each factor listed across the top of the page.",
    "To see the rates you can select for a factor, click the factor name and you will see the different rates you can use and what each rate means.",
    "When you are done scoring the processes/projects, please click the submit button at the top left of the page.",
  ];

  return (
    <div className="part-home">
      <ModalDialog
        show={showModalDialog}
        setShow={setShowModalDialog}
        title={dialogTitle}
        message={dialogMessage}
        action={dialogAction}
      />

      {loading ? <Loading /> : null}
      {!dataLoaded || isEmpty(selectedProject) ? null : (
        <>
          <Sidebar
            participant={true}
            companies={companies}
            projects={projects}
            selectedProject={selectedProject}
            selectProject={selectProject}
            canChangeProject={!scoring}
            changeProjectDialog={setLeaveWithoutSavingDialog}
          />
          <div className="part-content">
            <h3>{selectedProject.projectName}</h3>
            {selectedProject.interval.doesNotExist ? (
              <p>
                The current interval is not available for scoring at this time.
                Please contact your administrator if you believe this is
                incorrect.
              </p>
            ) : scoring ? (
              <ScoringTable
                project={selectedProject}
                interval={selectedProject.interval}
                scoringProb={scoringProb}
                factors={factors}
                processes={processes}
                savedScores={savedScores}
                submittedScores={submittedScores}
                scores={scores}
                setScores={setScores}
                back={stopScoring}
                promptBack={setLeaveWithoutSavingDialog}
                save={setSaveScoresDialog}
                submit={setSubmitScoresDialog}
              />
            ) : (
              <div className="scoring-container">
                <div className="scorecard-container">
                  <ParticipantScorecard
                    title="Probability Factor Scoring"
                    action={() => {
                      setLoading(true);
                      setScoringProb(true);
                      generateTableInfo(true);
                    }}
                  />
                  <ParticipantScorecard
                    title="Business Factor Scoring"
                    action={() => {
                      setLoading(true);
                      setScoringProb(false);
                      generateTableInfo(false);
                    }}
                  />
                </div>
                <ol>
                  {cardInstructions.map((c, index) => (
                    <li key={index}>{c}</li>
                  ))}
                </ol>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ParticipantHome;
