/*
    Required props:
        step
        prevInterval
        nextInterval
        prevActive
        nextActive
*/

const PrevNext = (props) => {
  return (
    <div className="prev-next-container">
      {props.prevActive ? (
        <button onClick={props.prevInterval}>
          <i className="fa-solid fa-backward-step fa-lg" />
        </button>
      ) : (
        <button disabled onClick={props.prevInterval}>
          <i className="fa-solid fa-backward-step fa-lg" />
        </button>
      )}
      <span>{props.step}</span>
      {props.nextActive ? (
        <button onClick={props.nextInterval}>
          <i className="fa-solid fa-forward-step fa-lg" />
        </button>
      ) : (
        <button disabled onClick={props.nextInterval}>
          <i className="fa-solid fa-forward-step fa-lg" />
        </button>
      )}
    </div>
  );
};

export default PrevNext;
