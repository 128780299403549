import React, { useEffect, useState } from "react";

import Loading from "../Loading";
import FactorScorecard from "../Scoring/FactorScorecard";
import { isEmpty } from "lodash";
import TabContextMenu from "../Input/TabContextMenu";
import Scoring from "../Scoring/Scoring";

const TabAdmin = (props) => {
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [scoresSubmitted, setScoresSubmitted] = useState(false);

  // index 0 = Probability Scoring
  // index 1 = Consequence Scoring
  const [canScore, setCanScore] = useState([false, false]);
  const [scoring, setScoring] = useState(false);
  const [scoringProb, setScoringProb] = useState(true);

  const [excludedProcesses, setExcludedProcesses] = useState(null);
  const [factors, setFactors] = useState(null);

  /* EFFECTS */
  useEffect(() => {
    if (
      !isEmpty(props.currInterval) &&
      !isEmpty(props.processes) &&
      !isEmpty(props.pf) &&
      !isEmpty(props.bf)
    ) {
      if (
        props.currInterval.calculations &&
        props.currInterval.details &&
        props.currInterval.scores &&
        props.currInterval.users
      ) {
        // console.log(props.currInterval);
        // console.log(props.processes);
        // console.log(props.pf);
        // console.log(props.bf);
        checkScoringData();
      }
    }
  }, [props.currInterval, props.processes, props.pf, props.bf]);

  useEffect(() => {
    if (canScore.includes(true)) {
      getExcludedProcesses();
    } else {
      setDataLoaded(true);
    }
  }, [canScore]);

  useEffect(() => {
    if (excludedProcesses) {
      setDataLoaded(true);
    }
  }, [excludedProcesses]);

  useEffect(() => {
    setScoring(factors !== null);
  }, [factors]);

  // syncs loading graphic to dataLoaded state
  useEffect(() => {
    setLoading(!dataLoaded);
  }, [dataLoaded]);

  /* SCORING DATA HELPERS */
  function getExcludedProcesses() {
    // TODO: UPDATE TO CHECK EXCLUDED PROCESSES WHEN ANDY PUSHES UP THAT FEATURE
    const procs = [];
    props.currInterval.details.includedProcesses.forEach((includedMajp) => {
      // find corresponding major process
      const majp = props.processes.find(
        (majp) => majp.majorProcessId === includedMajp.majorProcessId
      );
      // filter majp.minorProcesses to what is in excludedProcesses
      majp.minorProcesses = majp.minorProcesses.filter((minp) =>
        includedMajp.minorProcesses.includes(minp.minorProcessId)
      );
      procs.push(majp);
    });
    setExcludedProcesses(procs);
  }

  function getFactors(isProb) {
    const f = [];
    if (isProb) {
      props.currInterval.details.includedProbabilities.forEach((prob) => {
        f.push({
          ...props.pf.find((pf) => pf.probabilityId === prob.probabilityId),
          scale: prob.scaleDescriptions,
        });
      });
    } else {
      props.currInterval.details.includedConsequences.forEach((cons) => {
        f.push({
          ...props.bf.find((bf) => bf.consequenceId === cons.consequenceId),
          scale: cons.scaleDescriptions,
        });
      });
    }
    setFactors(f);
  }

  /* HELPERS */
  function reloadData() {
    console.log();
  }

  function checkScoringData() {
    // ensure there are processes and factors for admin to score
    if (
      props.processes.doesNotExist ||
      isEmpty(props.currInterval.details.includedProcesses)
    ) {
      setCanScore([false, false]);
    } else {
      const pfScoring =
        !props.pf.doesNotExist ||
        !isEmpty(props.currInterval.details.includedProbabilities);
      const bfScoring =
        !props.bf.doesNotExist ||
        !isEmpty(props.currInterval.details.includedConsequences);
      setCanScore([pfScoring, bfScoring]);
    }
  }

  function handleStartScoring(isProb) {
    setScoringProb(isProb);
    getFactors(isProb);
  }

  function handleStopScoring(reload) {
    if (reload) {
      reloadData();
    }
    setScoring(false);
    setFactors(null);
  }

  return (
    <div className="tab-content">
      {loading ? <Loading /> : null}
      {!dataLoaded ? null : scoring ? (
        <Scoring
          admin={true}
          scoresSubmitted={scoresSubmitted}
          interval={props.currInterval}
          processes={excludedProcesses}
          factors={factors}
          stopScoring={handleStopScoring}
          scoringProb={scoringProb}
          setLoading={setLoading}
          containerClass="content-container"
        />
      ) : (
        <>
          <TabContextMenu>
            <button>View Processes</button>
          </TabContextMenu>

          <div className="content-container">
            <FactorScorecard
              title="Probability Factor Scoring"
              scoresSubmitted={scoresSubmitted}
              action={canScore[0] ? () => handleStartScoring(true) : null}
            />
            <FactorScorecard
              title="Business Factor Scoring"
              scoresSubmitted={scoresSubmitted}
              action={canScore[1] ? () => handleStartScoring(false) : null}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TabAdmin;
