const FormPage = (props) => {
  return (
    <div className="form-page">
      {props.title ? <h3>{props.title}</h3> : null}
      {props.children}
    </div>
  );
};

export default FormPage;
