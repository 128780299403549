import ChecklistIcon from "../../Misc/ChecklistIcon";

const ChecklistItem = (props) => {
  return (
    <div className="checklist-item" onClick={props.action}>
      <ChecklistIcon flag={props.dataExists} />
      <div>
        <h3>{props.title}</h3>
        {props.noArrow ? null : (
          <div>
            <i className="fa-solid fa-chevron-right fa-lg" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChecklistItem;
