const FactorScorecard = (props) => {
  return (
    <div className="factor-scorecard">
      <h2>{props.title ?? "<Title>"}</h2>
      <button onClick={props.action} disabled={!props.action}>
        <h3>{props.scoresSubmitted ? "View" : "Score"}</h3>
      </button>
    </div>
  );
};

export default FactorScorecard;
