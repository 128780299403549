import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";

import IntervalHelpers from "../../api/IntervalHelpers";
import Helpers from "../../api/Helpers";
import EditableInput from "../Input/EditableInput";
import Loading from "../Loading";
import ModalCalc from "../Modal/ModalCalc";
import ModalCreate from "../Modal/ModalCreate";
import ModalCustom from "../Modal/ModalCustom";
import ModalDialog from "../Modal/ModalDialog";
import TabContextMenu from "../Input/TabContextMenu";
import IntervalItemTable from "../Interval/IntervalItemTable";

const TabProjects = (props) => {
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [writeInp, setWriteInp] = useState(false);
  const [tagChange, setTagChange] = useState(false);

  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState({});

  const [showTagMenuModal, setShowTagMenuModal] = useState(false);
  const [showCreateTagModal, setShowCreateTagModal] = useState(false);
  const [showEditTagModal, setShowEditTagModal] = useState(false);
  const [showAssignTagModal, setShowAssignTagModal] = useState(false);

  const [selectedProcess, setSelectedProcess] = useState({});

  const [showVOIModal, setShowVOIModal] = useState(false);

  const [showCreateMajpModal, setShowCreateMajpModal] = useState(false);
  const [showEditMajpModal, setShowEditMajpModal] = useState(false);

  const [showCreateMinpModal, setShowCreateMinpModal] = useState(false);
  const [showEditMinpModal, setShowEditMinpModal] = useState(false);

  const [showModalDialog, setShowModalDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogAction, setDialogAction] = useState(null);
  const [dialogOnClose, setDialogOnClose] = useState(null);

  useEffect(() => {
    // when Andy sets up the new tagging system, it'll probably be passed along through props
    setTags([
      {
        name: "Monetary Value",
        id: "monetaryValue",
        type: "int",
      },
      {
        name: "Process Status Description",
        id: "processStatusDescription",
        type: "select",
        subTags: ["First Desc", "Second Desc", "Third Desc"],
      },
      {
        name: "Process Status Name",
        id: "processStatusName",
        type: "select",
        subTags: ["First Status", "Second Status", "Third Status"],
      },
      {
        name: "Time Frame",
        id: "timeFrame",
        type: "select",
        subTags: ["1 Month", "3-6 Months", "1+ Years"],
      },
    ]);
  }, []);

  // ensure all data is available before generating item structure
  useEffect(() => {
    if (!isEmpty(props.currInterval) && !isEmpty(props.majorProcesses)) {
      if (
        props.currInterval.doesNotExist ||
        (props.currInterval.calculations &&
          props.currInterval.details &&
          props.currInterval.scores &&
          props.currInterval.users)
      ) {
        if (dataLoaded) {
          setLoading(false);
        } else {
          setDataLoaded(true);
        }
      }
    }
  }, [props.currInterval, props.majorProcesses, props.minorProcesses]);

  useEffect(() => {
    setLoading(!dataLoaded);
  }, [dataLoaded]);

  function refreshInformation() {
    setDataLoaded(false);
    props.getProcesses();
    props.getCurrInterval(true);
    // props.getTags();
  }

  const createMajp = () => {
    setLoading(true);
    const data = {
      ProjectId: props.project.projectId,
      MajorProcessName: document.getElementById("create-name").value,
      Description: document.getElementById("createDesc").value || null,
    };
    Helpers.createMajorProcess(data)
      .then(() => refreshInformation())
      .finally(() => {
        setShowCreateMajpModal(false);
      });
  };

  const deleteMajp = (majp, hasScores) => {
    setLoading(true);
    Helpers.deleteMajorProcess(majp.majorProcessId, hasScores)
      .then(() => {
        refreshInformation();
      })
      .finally(() => {
        setShowModalDialog(false);
      });
  };

  const editMajp = () => {
    setLoading(true);
    const data = {
      MajorProcessId: selectedProcess.majorProcessId,
      NewName: document.getElementById("edit-name").value || null,
      NewDescription: document.getElementById("editDesc").value || null,
      NewProcessStatusId: null,
      NewProjectValueId: null,
      NewProjectTimeFrameId: null,
    };
    Helpers.editMajorProcess(data)
      .then(() => refreshInformation())
      .finally(() => {
        setShowEditMajpModal(false);
      });
  };

  const createMinp = () => {
    setLoading(true);
    const data = {
      MajorProcessId: selectedProcess.majorProcessId,
      MinorProcessName: document.getElementById("createMinpName").value,
      Description: document.getElementById("createMinpDesc").value || null,
      ProcessStatusId: null,
      ProjectValueId: null,
      ProjectTimeFrameId: null,
    };
    Helpers.createMinorProcess(data)
      .then(() => refreshInformation())
      .finally(() => {
        setShowCreateMinpModal(false);
      });
  };

  const deleteMinp = (minp, hasScores) => {
    setLoading(true);
    Helpers.deleteMinorProcess(minp.minorProcessId, hasScores)
      .then(() => {
        refreshInformation();
      })
      .finally(() => {
        setShowModalDialog(false);
      });
  };

  const editMinp = () => {
    setLoading(true);
    const data = {
      MinorProcessId: selectedProcess.minorProcessId,
      NewName: document.getElementById("editMinpName").value || null,
      NewDescription: document.getElementById("editMinpDesc").value || null,
      NewProcessStatusId: null,
      NewProjectValueId: null,
      NewProjectTimeFrameId: null,
    };
    Helpers.editMinorProcess(data)
      .then(() => {
        refreshInformation();
      })
      .finally(() => {
        setShowEditMinpModal(false);
      });
  };

  const setVOI = (minp, voi) => {
    setLoading(true);
    const data = {
      IntervalId: props.currInterval.intervalId,
      ProcessId: minp.minorProcessId,
      VOI: voi,
    };
    console.log(data);
    Helpers.setVOI(data)
      .then(() => {
        refreshInformation();
      })
      .finally(() => {
        setShowVOIModal(false);
      });
  };

  function addAllProcessesToInterval() {
    setLoading(true);
    const ids = [];
    props.majorProcesses.forEach((majp) => {
      if (majp.minorProcesses.length > 0) {
        ids.push(majp.majorProcessId);
      }
    });
    Helpers.addMajorProcessesToInterval({
      IntervalId: props.currInterval.intervalId,
      MajorProcessIds: ids,
    }).then(refreshInformation);
  }

  function removeIntervalMajp(majp) {
    setLoading(true);
    IntervalHelpers.removeMajorProcesses(props.currInterval, majp).then(
      refreshInformation
    );
  }

  function removeIntervalMinp(minp) {
    setLoading(true);
    IntervalHelpers.removeMinorProcesses(props.currInterval, minp).then(
      refreshInformation
    );
  }

  // TODO
  const createTag = () => {
    // setLoading(true);

    console.log("Creating Tag");
    const data = {
      TagName: document.getElementById("create-name").value,
      TagType: document.getElementById("create-type").value,
    };
    console.log(data);

    // Helpers.createTag(data)
    //   .then(() => refreshInformation())
    //   .finally(() => {
    //     setShowCreateTagModal(false);
    //     setTagChange(!tagChange);
    //   });
  };

  // TODO
  const editTag = () => {
    // setLoading(true);

    console.log("editing tag");
    const data = {
      TagName: document.getElementById("edit-name").value,
    };

    // Helpers.editTag(data)
    //   .then(() => {
    //     refreshInformation();
    //   })
    //   .finally(() => {
    //     setShowEditTagModal(false);
    //     setTagChange(!tagChange);
    //   });
  };

  // TODO
  const deleteTag = (tag) => {
    console.log("deleting tag:");
    console.log(tag);
    // setLoading(true);
    // Helpers.deleteTag(tag.tagId)
    //   .then(() => {
    //     refreshInformation();
    //   })
    //   .finally(() => {
    //     setShowModalDialog(false);
    //     setTagChange(!tagChange);
    //   });
  };

  const createSubTag = (newName) => {
    console.log(newName);
  };

  const editSubTag = (newName) => {
    console.log(newName);
  };

  function addMinpToInterval(minp) {
    setLoading(true);
    const data = {
      IntervalId: props.currInterval.intervalId,
      MinorProcessIds: [minp.minorProcessId],
    };
    Helpers.addMinorProcessesToInterval(data)
      .then(() => {
        refreshInformation();
      })
      .finally(() => {
        setShowModalDialog(false);
        setShowVOIModal(true);
      });
  }

  function intervalIncludes(minp) {
    if (props.currInterval.doesNotExist) return false;
    return props.currInterval.details.includedProcesses.some((intMajp) =>
      intMajp.minorProcesses.includes(minp.minorProcessId)
    );
  }

  function minpHasScores(minp) {
    if (props.currInterval.doesNotExist) return false;
    return props.currInterval.calculations.processCalculations.some(
      (calc) => calc.processId === minp.minorProcessId
    );
  }

  function majpHasScores(majp) {
    if (props.currInterval.doesNotExist) return false;
    if (
      !props.currInterval.details.includedProcesses.some(
        (intMajp) => intMajp.majorProcessId === majp.majorProcessId
      )
    ) {
      return false;
    }
    return majp.minorProcesses.some((minp) => minpHasScores(minp));
  }

  function allProcsAdded() {
    if (!props.majorProcesses.length) {
      // if there are no major processes, don't display "Add All" button
      return true;
    } else {
      for (let majp of props.majorProcesses) {
        if (majp.minorProcesses.length > 0) {
          const intMajp = props.currInterval.details.includedProcesses.find(
            (p) => p.majorProcessId === majp.majorProcessId
          );
          if (
            intMajp === undefined ||
            majp.minorProcesses.length > intMajp.minorProcesses.length
          ) {
            return false;
          }
        }
      }
      // no flags raised, all major processes and their minor processes have been added
      return true;
    }
  }

  const hasCalcsText =
    " All associated scores will be deleted and the interval will need to be recalculated.";

  function setDeleteMajpDialog(majp) {
    const hasScores = majpHasScores(majp);
    setDialogAction(() => () => deleteMajp(majp, hasScores));
    setDialogTitle(`Delete ${majp.majorProcessName}`);
    setDialogMessage(
      `Are you sure you want to delete ${majp.majorProcessName}?${
        hasScores ? hasCalcsText : ""
      }`
    );
    setShowModalDialog(true);
  }

  function setDeleteMinpDialog(minp) {
    const hasScores = minpHasScores(minp);
    setDialogAction(() => () => deleteMinp(minp, hasScores));
    setDialogTitle(`Delete ${minp.minorProcessName}`);
    setDialogMessage(
      `Are you sure you want to delete ${minp.minorProcessName}?${
        hasScores ? hasCalcsText : ""
      }`
    );
    setShowModalDialog(true);
  }

  function setRemoveIntervalMajpDialog(majpId, setShowAncestor) {
    const majp = props.majorProcesses.find((p) => p.majorProcessId === majpId);
    const hasCalcs = IntervalHelpers.majpHasCalculations(
      props.currInterval,
      majp.majorProcessId
    );
    setDialogTitle(`Remove ${props.scoringItem.singular} from Interval`);
    setDialogMessage(
      `Are you sure you want to remove ${
        majp.majorProcessName
      } from the current Interval? All associated ${
        props.scoringItem.minorPlural
      } will also be removed.${hasCalcs ? hasCalcsText : ""}`
    );
    setDialogAction(() => () => removeIntervalMajp(majp));
    setDialogOnClose(() => () => setShowAncestor(true));
    setShowModalDialog(true);
  }

  function setRemoveIntervalMinpDialog(minpId, setShowAncestor) {
    const minp = props.minorProcesses.find(
      (minp) => minp.minorProcessId === minpId
    );
    const hasCalcs = IntervalHelpers.minpHasCalculations(
      props.currInterval,
      minp.minorProcessId
    );
    setDialogTitle(`Remove ${props.scoringItem.minorSingular} from Interval`);
    setDialogMessage(
      `Are you sure you want to remove ${
        minp.minorProcessName
      } from the current Interval?${hasCalcs ? hasCalcsText : ""}`
    );
    setDialogAction(() => () => removeIntervalMinp(minp));
    setDialogOnClose(() => () => setShowAncestor(true));
    setShowModalDialog(true);
  }

  function setAddToIntervalDialog(minp) {
    setDialogAction(() => () => addMinpToInterval(minp));
    setDialogTitle(
      `Add ${minp.minorProcessName} to Interval ${props.currInterval.intervalLabel}`
    );
    setDialogMessage(
      `A process must be added to the current interval before allowing VOI to be calculated.
      Are you sure you want to add ${minp.minorProcessName} to the current interval?`
    );
    setShowModalDialog(true);
  }

  function setDeleteTagDialog(tag) {
    setDialogAction(() => () => deleteTag(tag));
    setDialogTitle(`Delete ${tag.name}`);
    setDialogMessage(`Are you sure you want to delete ${tag.name}?`);
    setShowModalDialog(true);
  }

  return (
    <div className="tab-content">
      {loading ? <Loading /> : null}
      {/* Context Menu */}
      <TabContextMenu>
        <button onClick={() => setShowCreateMajpModal(true)}>
          Create New {props.scoringItem.singular}
        </button>
        <button disabled onClick={() => setShowTagMenuModal(true)}>
          Tags
        </button>
        {!dataLoaded ||
        props.majorProcesses.doesNotExist ||
        props.currInterval.doesNotExist ? null : (
          <IntervalItemTable
            title={`Interval ${props.scoringItem.minorPlural}`}
            itemName={props.scoringItem.minorPlural}
            items={IntervalHelpers.getProcessItems(
              props.currInterval,
              props.majorProcesses
            )}
            addAllAction={allProcsAdded() ? null : addAllProcessesToInterval}
            buttonText="Add All"
            removeAction={setRemoveIntervalMajpDialog}
            removeSubItemAction={setRemoveIntervalMinpDialog}
          />
        )}
      </TabContextMenu>

      {/* Create new major process */}
      <ModalCreate
        show={showCreateMajpModal}
        setShow={setShowCreateMajpModal}
        title={`Create New ${props.scoringItem.singular}`}
        action={createMajp}
      >
        {/* NAME */}
        <div className="modal-field">
          <label htmlFor="create-name">Name</label>
          <input
            type="text"
            id="create-name"
            placeholder={`New ${props.scoringItem.singular}`}
            autoFocus
          />
        </div>

        {/* DESCRIPTION */}
        <div className="modal-field">
          <label htmlFor="createDesc">Description</label>
          <textarea
            id="createDesc"
            placeholder={`${props.scoringItem.singular} Description`}
          />
        </div>
      </ModalCreate>

      {/* View Tags */}
      <ModalCustom
        show={showTagMenuModal}
        setShow={setShowTagMenuModal}
        title={`${props.scoringItem.minorSingular} Tags`}
        onClose={() => setWriteInp(false)}
        renderItem={tagChange}
        action={() => {
          setShowCreateTagModal(true);
        }}
      >
        <div className="modal-fields-container">
          <table id="tag-table">
            <thead>
              <tr>
                <th>Tag</th>
                <th>Tag Type</th>
                <th className="nowrap"></th>
                <th className="nowrap"></th>
              </tr>
            </thead>
            <tbody id="tag-table-body">
              {tags.doesNotExist || isEmpty(tags) ? (
                <tr>
                  <td>No Tags Exist!</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              ) : (
                tags.map((tag, index) => (
                  <tr key={index} id={tag.id}>
                    <td className="tag-name">{tag.name}</td>
                    <td className="tag-type">
                      {tag.type === "int" ? "Numerical" : "List"}
                    </td>
                    <td
                      className="nowrap hover-cursor"
                      onClick={() => {
                        setSelectedTag(tag);
                        setShowEditTagModal(true);
                        console.log("editing subcat " + tag.id);
                      }}
                    >
                      <i className="fa-solid fa-pen-to-square fa-lg" />
                    </td>
                    <td
                      className="nowrap hover-cursor"
                      onClick={() => {
                        setDeleteTagDialog(tag);
                      }}
                    >
                      <i className="fa-solid fa-trash fa-lg" />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </ModalCustom>

      {!dataLoaded || props.majorProcesses.doesNotExist ? null : (
        <>
          {/* Calculate VOI */}
          <ModalCalc
            show={showVOIModal}
            setShow={setShowVOIModal}
            process={selectedProcess}
            setVOI={setVOI}
          />

          {/* Create New Tag */}
          <ModalCreate
            show={showCreateTagModal}
            setShow={setShowCreateTagModal}
            title="Create new Tag"
            action={createTag}
          >
            {/* NAME */}
            <div className="modal-field">
              <label htmlFor="create-name">Name</label>
              <input
                type="text"
                id="create-name"
                placeholder="New Tag"
                autoFocus
              />
            </div>

            {/* TYPE */}
            <div className="modal-field">
              <label htmlFor="create-type">Type</label>
              <select id="create-type">
                <option value="int">Numerical</option>
                <option value="select">List</option>
              </select>
            </div>
          </ModalCreate>

          {/* Edit Tag */}
          <ModalCreate
            show={showEditTagModal}
            setShow={setShowEditTagModal}
            title={`Edit ${selectedTag.name}`}
            action={editTag}
            buttonText="Confirm"
          >
            <div className="modal-field">
              <label htmlFor="edit-name">Name</label>
              <input
                type="text"
                id="edit-name"
                placeholder={selectedTag.name}
                autoFocus
              />
            </div>

            {selectedTag.type !== "select" ? null : (
              <>
                <label htmlFor="projectLabel">Subtags</label>
                <table className="subtag-table">
                  <tbody>
                    {selectedTag.subTags.map((st, index) => (
                      <tr key={index}>
                        <td>
                          <EditableInput
                            type="text"
                            id="projectLabel"
                            text={st}
                            placeholder={st}
                            onButtonClick={(e) => editSubTag(e)}
                          />
                        </td>
                      </tr>
                    ))}
                    <tr className="new-subtag">
                      <td>
                        <EditableInput
                          type="text"
                          id="projectLabel"
                          placeholder="New Subtag"
                          onButtonClick={(e) => createSubTag(e)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </ModalCreate>

          {/* Assign Tags */}
          <ModalCreate
            show={showAssignTagModal}
            setShow={setShowAssignTagModal}
            title={`Assign Tags to ${selectedProcess.description}`}
            action={() => console.log("assigning tags")}
            buttonText="Save"
          >
            {tags.map((tag, index) => (
              <div className="modal-field" key={index}>
                <input
                  type="checkbox"
                  name="tags"
                  id={tag.id}
                  value={tag.id}
                  // defaultChecked={
                  //   isEmpty(selectedFactor)
                  //     ? false
                  //     : selectedFactor.subcategories.includes(
                  //         subcat.probSubcategoryId ?? subcat.consSubcategoryId
                  //       )
                  // }
                />
                <label htmlFor={tag.id}>{tag.name}</label>
              </div>
            ))}
          </ModalCreate>

          {/* Edit major process */}
          <ModalCreate
            show={showEditMajpModal}
            setShow={setShowEditMajpModal}
            title={`Edit ${selectedProcess.majorProcessName}`}
            action={editMajp}
            buttonText="Confirm"
          >
            {/* NAME */}
            <div className="modal-field">
              <label htmlFor="edit-name">Name</label>
              <input
                type="text"
                id="edit-name"
                placeholder={selectedProcess.majorProcessName}
                autoFocus
              />
            </div>

            {/* DESCRIPTION */}
            <div className="modal-field">
              <label htmlFor="editDesc">Description</label>
              <textarea
                id="editDesc"
                placeholder={selectedProcess.description}
              />
            </div>
          </ModalCreate>

          {/* Create new minor process */}
          <ModalCreate
            show={showCreateMinpModal}
            setShow={setShowCreateMinpModal}
            title={`Create new ${props.scoringItem.minorSingular}`}
            action={createMinp}
          >
            {/* NAME */}
            <div className="modal-field">
              <label htmlFor="createMinpName">Name</label>
              <input
                type="text"
                id="createMinpName"
                placeholder={`New ${props.scoringItem.minorSingular}`}
                autoFocus
              />
            </div>

            {/* DESCRIPTION */}
            <div className="modal-field">
              <label htmlFor="createMinpDesc">Description</label>
              <textarea
                id="createMinpDesc"
                placeholder={`${props.scoringItem.minorSingular} description`}
              />
            </div>
          </ModalCreate>

          {/* Edit minor process */}
          <ModalCreate
            show={showEditMinpModal}
            setShow={setShowEditMinpModal}
            title={`Edit ${selectedProcess.minorProcessName}`}
            action={editMinp}
            buttonText="Confirm"
          >
            {/* NAME */}
            <div className="modal-field">
              <label htmlFor="editMinpName">Name</label>
              <input
                type="text"
                id="editMinpName"
                placeholder={selectedProcess.minorProcessName}
                autoFocus
              />
            </div>

            {/* DESCRIPTION */}
            <div className="modal-field">
              <label htmlFor="editMinpDesc">Description</label>
              <textarea
                id="editMinpDesc"
                placeholder={selectedProcess.description}
              />
            </div>
          </ModalCreate>

          {/* General purpose dialog box, set contents through state */}
          <ModalDialog
            show={showModalDialog}
            setShow={setShowModalDialog}
            title={dialogTitle}
            message={dialogMessage}
            action={dialogAction}
            onClose={dialogOnClose}
          />

          {/* Process Table */}
          <div className="content-container">
            <table id="process-table">
              <thead>
                <tr>
                  <th>
                    {`${props.scoringItem.singular} & ${props.scoringItem.minorSingular} Name`}
                  </th>
                  <th className="nowrap">ID #</th>
                  <th>Description</th>
                  {/* VOI only displayed for Project Portfolios */}
                  {props.scoringItem.typeId !== 2 ? null : (
                    <th className="nowrap">VOI</th>
                  )}
                  {/* CONFIGURE */}
                  <th className="table-icon"></th>
                </tr>
              </thead>
              <tbody>
                {props.majorProcesses.map((p) => (
                  <React.Fragment key={p.majorProcessId}>
                    <tr className="section-name">
                      <td>{p.majorProcessName}</td>
                      <td>{p.majorProcessNumber}</td>
                      <td>{p.description}</td>
                      {/* VOI only displayed for Project Portfolios */}
                      {props.scoringItem.typeId !== 2 ? null : <td></td>}
                      <td className="table-icon nowrap">
                        {/* Major process context menu */}
                        <Menu
                          className="data-class-menu"
                          menuButton={
                            <i className="fa-solid fa-ellipsis-vertical fa-lg" />
                          }
                          align="center"
                          position="anchor"
                          arrow={true}
                          direction="left"
                        >
                          <MenuItem
                            className="menu-item"
                            onClick={() => {
                              setSelectedProcess(p);
                              setShowCreateMinpModal(true);
                            }}
                          >
                            <i className="fa-solid fa-circle-plus" /> Add
                          </MenuItem>
                          <MenuItem
                            className="menu-item"
                            onClick={() => {
                              setSelectedProcess(p);
                              setShowEditMajpModal(true);
                            }}
                          >
                            <i className="fa-solid fa-pen-to-square " /> Edit
                          </MenuItem>
                          <MenuItem
                            className="menu-item menu-delete"
                            onClick={() => {
                              setDeleteMajpDialog(p);
                            }}
                          >
                            <i className="fa-solid fa-trash" /> Delete
                          </MenuItem>
                        </Menu>
                      </td>
                    </tr>
                    {p.minorProcesses.map((sp) => (
                      <tr key={sp.minorProcessId}>
                        <td>{sp.minorProcessName}</td>
                        <td>{sp.minorProcessNumber}</td>
                        <td>{sp.description}</td>
                        {/* VOI only displayed for Project Portfolios */}
                        {props.scoringItem.typeId !== 2 ? null : (
                          <td>
                            {sp.values?.voi || (
                              <button
                                // disabled // disabled until endpoint is resolved
                                onClick={() => {
                                  setSelectedProcess(sp);
                                  intervalIncludes(sp)
                                    ? setShowVOIModal(true)
                                    : setAddToIntervalDialog(sp);
                                }}
                              >
                                Calculate
                              </button>
                            )}
                          </td>
                        )}
                        <td className="table-icon nowrap">
                          {/* Minor process context menu */}
                          <Menu
                            className="data-class-menu"
                            menuButton={
                              <i className="fa-solid fa-ellipsis-vertical fa-lg" />
                            }
                            align="center"
                            position="anchor"
                            arrow={true}
                            direction="left"
                          >
                            <MenuItem
                              className="menu-item"
                              onClick={() => {
                                setSelectedProcess(sp);
                                setShowEditMinpModal(true);
                              }}
                            >
                              <i className="fa-solid fa-pen-to-square " /> Edit
                            </MenuItem>
                            <MenuItem
                              className="menu-item"
                              onClick={() => {
                                setSelectedProcess(sp);
                                setShowAssignTagModal(true);
                              }}
                              disabled
                            >
                              <i className="fa-solid fa-tags " /> Tags
                            </MenuItem>
                            <MenuItem
                              className="menu-item menu-delete"
                              onClick={() => {
                                setDeleteMinpDialog(sp);
                              }}
                            >
                              <i className="fa-solid fa-trash" /> Delete
                            </MenuItem>
                          </Menu>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default TabProjects;
