import React, { useEffect } from "react";
import { cloneDeep } from "lodash";

const ScoringTable = (props) => {
  useEffect(() => {
    console.log(props.scores);
    console.log(props.savedScores);
  }, [props.scores, props.savedScores]);

  function handleInput(e) {
    const processId = parseInt(e.target.id.split("-")[0]);
    const factorId = parseInt(e.target.id.split("-")[1]);
    const scoresCopy = cloneDeep(props.scores);
    const existingIndex = scoresCopy.findIndex(
      (s) =>
        s.processId === processId &&
        (s.probabilityId === factorId || s.consequenceId === factorId)
    );
    const saveExists = getSavedScore(processId, factorId) !== "";
    if (!e.target.value && !saveExists) {
      e.target.classList.remove("changed");
    } else {
      e.target.classList.add("changed");
    }
    // if score exists
    if (existingIndex !== -1) {
      if (!e.target.value) {
        if (saveExists) {
          scoresCopy[existingIndex].ScoreValue = -1;
        } else {
          scoresCopy.splice(existingIndex, 1);
        }
      } else {
        scoresCopy[existingIndex].ScoreValue = parseInt(e.target.value) || null;
      }
    } else {
      scoresCopy.push(
        props.generateScoreData(processId, factorId, e.target.value)
      );
    }
    // props.setCanSubmit(scoresCopy.length > 0);
    props.setScores(scoresCopy);
  }

  function getSavedScore(processId, factorId) {
    const saved = props.savedScores.find(
      (s) =>
        s.ProcessId === processId &&
        (s.ProbabilityId ?? s.ConsequenceId) === factorId
    );
    return saved !== undefined ? saved.ScoreValue : "";
  }

  return (
    <table id="scoring-table">
      <thead>
        <tr>
          <th></th>
          {props.factors.map((f, index) => (
            <th key={index}>
              <a id={"factor-" + f.probabilityId ?? f.consequenceId}>
                {f.description}
              </a>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.processes.map((majp, index) => (
          <React.Fragment key={index}>
            <tr>
              <th
                className="major-process-row"
                colSpan={props.factors.length + 1}
              >{`${majp.majorProcessNumber} - ${majp.majorProcessName}`}</th>
            </tr>
            {majp.minorProcesses.map((minp) => (
              <tr key={index}>
                <td>{minp.minorProcessName}</td>
                {props.factors.map((f, index) => (
                  <td key={index} className="input-cells">
                    <select
                      name="scale"
                      id={`${minp.minorProcessId}-${
                        f.probabilityId ?? f.consequenceId
                      }`}
                      className={
                        getSavedScore(minp.id, f.id) !== "" ? "changed" : ""
                      }
                      defaultValue={getSavedScore(minp.id, f.id)}
                      onChange={(e) => handleInput(e)}
                    >
                      <option value=""></option>
                      {f.scale.map((s, index) => (
                        <option
                          key={index}
                          value={s.probScoreValue ?? s.consScoreValue}
                        >
                          {s.probScoreValue ?? s.consScoreValue}
                        </option>
                      ))}
                    </select>
                  </td>
                ))}
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default ScoringTable;
