import { useEffect, useState } from "react";

import SetupSection from "./Layout/SetupSection";
import ChecklistItem from "./Layout/ChecklistItem";

const IntervalChecklist = (props) => {
  const [instructions, setInstructions] = useState(null);
  const [actionIndex, setActionIndex] = useState(1);

  useEffect(() => {
    handleChecklistItemClick(0);
    setFactorInstructions(true);
  }, []);

  function setFactorInstructions(isProb) {
    setInstructions(
      <ul>
        <li>
          Add {isProb ? "Probability" : "Business"} Factors to the Interval
        </li>
        <li>Adjust their Weights{isProb ? "" : " and Multipliers"}</li>
        <li>Create each Factor's Scoring Scale Descriptions</li>
        <li>Ensure each Factor has at least 1 Subcategory assigned</li>
        <li>Exclude any Factors that shouldn't be visible to Participants</li>
      </ul>
    );
  }

  function setProcessInstructions() {
    setInstructions(
      <ul>
        <li>Add {props.scoringItem.minorPlural} to the Interval</li>
        <li>
          Ensure each {props.scoringItem.minorSingular} is Tagged, if applicable
        </li>
        <li>
          Calculate the VOI/VAR for each {props.scoringItem.minorSingular}
        </li>
      </ul>
    );
  }

  function setParticipantInstructions() {
    setInstructions(
      <ul>
        <li>Add Participants to the Interval</li>
        <li>Set each Participant's Scoring Privileges</li>
      </ul>
    );
  }

  function handleChecklistItemClick(index) {
    setActionIndex(index + 1);
    const items = document.querySelectorAll(
      "#interval-checklist div.checklist-item"
    );
    for (let item of items) {
      item.classList.remove("selected");
    }
    items[index].classList.add("selected");
  }

  return (
    <SetupSection
      title="Prepare the Interval"
      setupFlag={props.setupFlag}
      className="checklist"
      id="interval-checklist"
      sectionText={"Complete the following items:"}
      sectionInstructions={instructions}
      sectionAction={() => props.setTabIndex(actionIndex)}
    >
      <ChecklistItem
        title="Probability Factors"
        dataExists={props.intervalChecks.probs()}
        action={() => {
          handleChecklistItemClick(0);
          setFactorInstructions(true);
        }}
        noArrow={true}
      />
      <ChecklistItem
        title="Business Factors"
        dataExists={props.intervalChecks.cons()}
        action={() => {
          handleChecklistItemClick(1);
          setFactorInstructions(false);
        }}
        noArrow={true}
      />
      <ChecklistItem
        title={props.scoringItem.plural}
        dataExists={props.intervalChecks.procs}
        action={() => {
          handleChecklistItemClick(2);
          setProcessInstructions();
        }}
        noArrow={true}
      />
      <ChecklistItem
        title="Participants"
        dataExists={props.intervalChecks.parts()}
        action={() => {
          handleChecklistItemClick(3);
          setParticipantInstructions();
        }}
        noArrow={true}
      />
    </SetupSection>
  );
};

export default IntervalChecklist;
